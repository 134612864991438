export default {
  AUTHORIZE_CHARGE: {
    url: '/veritrans/charge',
    method: 'POST'
  },
  VERITRANS_ORDER_RECEIVE: {
    url: '/veritrans/order-receive',
    method: 'POST'
  },
  VERITRANS_CONFIRM_ORDER: {
    url: '/veritrans/confirm-order',
    method: 'POST'
  },
  VERITRANS_UPDATE_CARD: {
    url: '/veritrans/add-card',
    method: 'POST'
  },
  GMO_CHARGE: {
    url: '/gmo/charge',
    method: 'POST'
  },
  GMO_UPDATE_CARD: {
    url: '/gmo/add-card',
    method: 'POST'
  },
  CREATE_ORDER_BY_GAKKEN_ID: {
    url: '/gakken-payment/order-receive',
    method: 'POST'
  },
  GAKKEN_PAYEMNT_INFO: {
    url: '/gakken-payment/get-info',
    method: 'GET'
  },
  CONFIRM_ORDER_BY_GAKKEN_ID: {
    url: '/gakken-payment/confirm-order',
    method: 'POST'
  }
};
