import { all, put, select, takeEvery } from 'redux-saga/effects';
import createRequest from '../../api/httpRequest';
import API from '../../api/notifications';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import NotificationsTypes from './NotificationsTypes';
import NotificationTypes from 'state/notification/NotificationTypes';
import { getCompanyDomain, getServicePath } from 'state/company/companySelector';

function * getNotificationsDetail () {
  yield takeEvery(NotificationsTypes.GET_NOTIFICATIONS_DETAIL_REQUEST, _getNotificationsDetail);
}

function * _getNotificationsDetail ({ payload }) {
  const api = {
    ...API.GET_NOTIFICATIONS_DETAIL,
    url: API.GET_NOTIFICATIONS_DETAIL.url + payload
  };
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      yield put({
        type: NotificationsTypes.GET_NOTIFICATIONS_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * getNotificationsPaging () {
  yield takeEvery(NotificationsTypes.GET_NOTIFICATIONS_PAGING_REQUEST, _getNotificationsPaging);
}

function * _getNotificationsPaging ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_NOTIFICATIONS_PAGING,
      params: { ...request, path: servicePath, domain }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationsTypes.GET_NOTIFICATIONS_PAGING_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    }
  });
}

function * updateNotifications () {
  yield takeEvery(NotificationsTypes.UPDATE_NOTIFICATIONS_REQUEST, _updateNotifications);
}

function * _updateNotifications ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.UPDATE_NOTIFICATIONS,
      data: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

export default function * notificationsSaga () {
  yield all([
    getNotificationsDetail(),
    getNotificationsPaging(),
    updateNotifications()
  ]);
}
