const _prefix = '@home/';

export default {
  GET_PUBLIC_CONTENT_GROUP_REQUEST: _prefix + 'GET_PUBLIC_CONTENT_GROUP_REQUEST',
  GET_PUBLIC_CONTENT_GROUP_SUCCESS: _prefix + 'GET_PUBLIC_CONTENT_GROUP_SUCCESS',
  GET_PUBLIC_CONTENT_GROUP_ERROR: _prefix + 'GET_PUBLIC_CONTENT_GROUP_ERROR',

  GET_CONTENT_GROUP_BY_CONTENT_ID_REQUEST: _prefix + 'GET_CONTENT_GROUP_BY_CONTENT_ID_REQUEST',
  GET_CONTENT_GROUP_BY_CONTENT_ID_SUCCESS: _prefix + 'GET_CONTENT_GROUP_BY_CONTENT_ID_SUCCESS',
  GET_CONTENT_GROUP_BY_CONTENT_ID_ERROR: _prefix + 'GET_CONTENT_GROUP_BY_CONTENT_ID_ERROR',

  GET_CONTENT_BY_CONTENT_GROUP_REQUEST: _prefix + 'GET_CONTENT_BY_CONTENT_GROUP_REQUEST',
  GET_CONTENT_BY_CONTENT_GROUP_SUCCESS: _prefix + 'GET_CONTENT_BY_CONTENT_GROUP_SUCCESS',
  GET_CONTENT_BY_CONTENT_GROUP_ERROR: _prefix + 'GET_CONTENT_BY_CONTENT_GROUP_ERROR',

  SEARCH_CONTENT_BY_CONTENT_GROUP_REQUEST: _prefix + 'SEARCH_CONTENT_BY_CONTENT_GROUP_REQUEST',
  SEARCH_CONTENT_BY_CONTENT_GROUP_SUCCESS: _prefix + 'SEARCH_CONTENT_BY_CONTENT_GROUP_SUCCESS',
  SEARCH_CONTENT_BY_CONTENT_GROUP_ERROR: _prefix + 'SEARCH_CONTENT_BY_CONTENT_GROUP_ERROR',

  SEARCH_FULL_TEXT_BY_CONTENT_GROUP_REQUEST: _prefix + 'SEARCH_FULL_TEXT_BY_CONTENT_GROUP_REQUEST',
  SEARCH_FULL_TEXT_BY_CONTENT_GROUP_SUCCESS: _prefix + 'SEARCH_FULL_TEXT_BY_CONTENT_GROUP_SUCCESS',
  SEARCH_FULL_TEXT_BY_CONTENT_GROUP_ERROR: _prefix + 'SEARCH_FULL_TEXT_BY_CONTENT_GROUP_ERROR',

  GET_PAGE_CONTENT_BY_CONTENT_GROUP_REQUEST: _prefix + 'GET_PAGE_CONTENT_BY_CONTENT_GROUP_REQUEST',
  GET_PAGE_CONTENT_BY_CONTENT_GROUP_SUCCESS: _prefix + 'GET_PAGE_CONTENT_BY_CONTENT_GROUP_SUCCESS',
  GET_PAGE_CONTENT_BY_CONTENT_GROUP_ERROR: _prefix + 'GET_PAGE_CONTENT_BY_CONTENT_GROUP_ERROR',

  CLEAR_CONTENTS: _prefix + 'CLEAR_CONTENTS',
  SYNC_CONTENT_OF_CONTENT_GROUP: _prefix + 'SYNC_CONTENT_OF_CONTENT_GROUP',
  SET_SHOW_HOME_SEARCH: _prefix + 'SET_SHOW_HOME_SEARCH',
  CACHE_SEARCH_REQUEST: _prefix + 'CACHE_SEARCH_REQUEST',
  SET_SEARCH_REQUEST: _prefix + 'SET_SEARCH_REQUEST',
  SET_SEARCH_REQUEST_FULL_TEXT_SEARCH: _prefix + 'SET_SEARCH_REQUEST_FULL_TEXT_SEARCH',
  SET_FULLTEXT_SEARCH: _prefix + 'SET_FULLTEXT_SEARCH',
  UPDATE_DATA_NEXT_PAGE: _prefix + 'UPDATE_DATA_NEXT_PAGE',
  UPDATE_DATA_PRE_PAGE: _prefix + 'UPDATE_DATA_PRE_PAGE',
  UPDATE_DATA_CURRENT_PAGE: _prefix + 'UPDATE_DATA_CURRENT_PAGE',
  UPDATE_SORT_ORDER: _prefix + 'UPDATE_SORT_ORDER'
};
