import StorageHelper from 'utils/StorageHelper';
import PaymentTypes from './PaymentTypes';
import { STORAGE_KEYS } from 'configs/AppConfig';
const initialState = {
  contentHtml: '',
  redirectUrl: '',
  isPaymentError: false,
  isPaymentSuccess: false,
  isUpdateCardSuccess: false,
  gakkenInfo: {},
  isLoadingGakkenInfo: false,
  isLoadingConfirmPayment: false,
  isCreateOrderReceive: false,
  isGakkenInfo: false,
  isRediectUrl: false,
  gakkenPayment: {},
  isVerify: false,
  redirectUrlVeritrans: null,
  isRedirectUrlVeritrans: true,
  isUpdatingCard: false
};

export default function paymentReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case PaymentTypes.AUTHORIZE_CHARGE_REQUEST:
      newState.isVerify = true;
      return newState;
    case PaymentTypes.AUTHORIZE_CHARGE_SUCCESS:
      newState.contentHtml = payload;
      newState.isVerify = false;
      return newState;
    case PaymentTypes.GMO_CHARGE_SUCCESS:
      newState.isPaymentSuccess = true;
      newState.isVerify = false;
      return newState;
    case PaymentTypes.GMO_CHARGE_REQUEST:
      newState.isPaymentSuccess = false;
      newState.isPaymentError = false;
      newState.isVerify = true;
      return newState;
    case PaymentTypes.GMO_CHARGE_ERROR:
      newState.isPaymentError = true;
      newState.isVerify = false;
      return newState;
    case PaymentTypes.AUTHORIZE_CHARGE_ERROR:
      newState.isPaymentError = true;
      newState.isVerify = false;
      return newState;
    case PaymentTypes.RESET_PAYMENT_ERROR:
      newState.isPaymentError = false;
      return newState;
    case PaymentTypes.RESET_HTML:
      newState.contentHtml = null;
      return newState;
    case PaymentTypes.GMO_UPDATE_CARD_SUCCESS:
      newState.isUpdateCardSuccess = true;
      return newState;
    case PaymentTypes.VERITRANS_UPDATE_CARD_SUCCESS:
      newState.isUpdateCardSuccess = true;
      newState.isUpdatingCard = false;
      return newState;
    case PaymentTypes.GMO_UPDATE_CARD_REQUEST:
    case PaymentTypes.GMO_UPDATE_CARD_ERROR:
      newState.isUpdateCardSuccess = false;
      return newState;
    case PaymentTypes.VERITRANS_UPDATE_CARD:
      newState.isUpdateCardSuccess = false;
      newState.isUpdatingCard = true;
      return newState;
    case PaymentTypes.VERITRANS_UPDATE_CARD_ERROR:
      newState.isUpdateCardSuccess = false;
      newState.isUpdatingCard = false;
      return newState;
    case PaymentTypes.RESET_PAYMENT_STATUS:
      newState.isUpdateCardSuccess = false;
      newState.isPaymentError = false;
      newState.isPaymentSuccess = false;
      newState.isGakkenInfo = false;
      newState.isRediectUrl = false;
      return newState;
    case PaymentTypes.GAKKEN_PAYMENT_INFO_REQUEST:
      newState.isLoadingGakkenInfo = true;
      newState.isGakkenInfo = false;
      return newState;
    case PaymentTypes.GAKKEN_PAYMENT_INFO_ERROR:
      newState.isLoadingGakkenInfo = false;
      newState.isGakkenInfo = false;
      newState.gakkenInfo = {};
      return newState;
    case PaymentTypes.GAKKEN_PAYMENT_INFO_SUCCESS:
      newState.gakkenInfo = payload;
      newState.isLoadingGakkenInfo = false;
      newState.isGakkenInfo = true;
      return newState;
    case PaymentTypes.RESET_GAKKEN_PAYMENT_INFO:
      newState.isLoadingGakkenInfo = false;
      newState.isGakkenInfo = false;
      newState.isRediectUrl = false;
      StorageHelper.removeSessionItem(STORAGE_KEYS.couponCode);
      StorageHelper.removeSessionItem(STORAGE_KEYS.subsId);
      StorageHelper.removeSessionItem(STORAGE_KEYS.redirectUrlCallback);
      newState.gakkenPayment = {};
      // newState.gakkenInfo = {};
      return newState;
    case PaymentTypes.RESET_CREATE_ORDER_RECEIVE:
      newState.redirectUrl = null;
      newState.isRediectUrl = false;
      return newState;
    case PaymentTypes.RESET_CREATE_ORDER_RECEIVE_VERITRANS:
      newState.redirectUrlVeritrans = null;
      newState.isRedirectUrlVeritrans = false;
      newState.isPaymentError = false;
      newState.isPaymentSuccess = false;
      return newState;
    case PaymentTypes.CREATE_ORDER_RECEIVE_REQUEST:
      newState.isCreateOrderReceive = true;
      return newState;
    case PaymentTypes.CREATE_ORDER_RECEIVE_SUCCESS:
      newState.redirectUrl = payload;
      newState.isCreateOrderReceive = false;
      newState.isRediectUrl = true;
      return newState;
    case PaymentTypes.CREATE_ORDER_RECEIVE_ERROR:
      newState.isCreateOrderReceive = false;
      newState.isPaymentError = true;
      newState.redirectUrl = '';
      return newState;
    case PaymentTypes.CONFIRM_ORDER_RECEIVE_SUCCESS:
      newState.isLoadingConfirmPayment = false;
      newState.isPaymentSuccess = true;
      return newState;
    case PaymentTypes.CONFIRM_ORDER_RECEIVE_REQUEST:
      newState.isLoadingConfirmPayment = true;
      return newState;
    case PaymentTypes.CONFIRM_ORDER_RECEIVE_ERROR:
      newState.isLoadingConfirmPayment = false;
      newState.isPaymentSuccess = false;
      newState.isPaymentError = true;
      return newState;
    case PaymentTypes.SET_CACHE_COUPON_CODE:
      StorageHelper.setSessionItem(STORAGE_KEYS.couponCode, payload);
      return newState;
    case PaymentTypes.SET_CACHE_SUBSCRIPTION_ID:
      StorageHelper.setSessionItem(STORAGE_KEYS.subsId, payload);
      return newState;
    case PaymentTypes.SET_CACHE_REDIRECT_URL_CALLBACK:
      newState.gakkenPayment = payload;
      // StorageHelper.setSessionItem(STORAGE_KEYS.redirectUrlCallback, payload);
      return newState;
    case PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE:
      // newState.redirectUrlVeritrans = '';
      return newState;
    case PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE_SUCCESS:
      newState.redirectUrlVeritrans = payload;
      newState.isRedirectUrlVeritrans = true;
      return newState;
    case PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE_ERROR:
      newState.redirectUrlVeritrans = '';
      newState.isRedirectUrlVeritrans = false;
      return newState;
    case PaymentTypes.VERITRANS_CONFIRM_ORDER:
      newState.isLoadingConfirmPayment = true;
      return newState;
    case PaymentTypes.VERITRANS_CONFIRM_ORDER_SUCCESS:
      newState.isLoadingConfirmPayment = false;
      newState.isPaymentSuccess = true;
      return newState;
    case PaymentTypes.VERITRANS_CONFIRM_ORDER_ERROR:
      newState.isLoadingConfirmPayment = false;
      newState.isPaymentSuccess = false;
      newState.isPaymentError = true;
      return newState;
    default:
      newState = state;
      break;
  }

  return newState;
}
