import { PATTERNS, POSITION_CONFIG, SECTION_TYPES } from 'configs/AppConfig';
import moment from 'moment';
import { camelCase, isNumber, isObject } from 'lodash';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from 'configs/locale';
import * as CryptoJS from 'crypto-js';

const Multipayment = window.Multipayment;

function findHeadingRecursive (list, find) {
  if (!list || !find) {
    return null;
  }
  let heading = list.find(subItem => subItem.name === find);
  if (!heading) {
    for (const item of list) {
      heading = findHeadingRecursive(item.subSections, find);
      if (heading) {
        break;
      }
    }
  }
  return heading;
}

const Util = {
  mobileCheck: function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  mobileAndTabletCheck: function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  detectDeviceType: function () {
    const check =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ? 'Mobile'
      : 'Desktop';
    return check;
  },
  detectBrowserVersion: function () {
    navigator.sayswho = (function () {
      var ua = navigator.userAgent;
      var tem;
      var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    })();
    return navigator.sayswho;
  },
  getMoment (date, format) {
    return isObject(date) ? moment(date) : moment(date,
      format || PATTERNS.DATE_FORMAT);
  },
  formatDate (date, format) {
    return !date || date === '' ? '' : moment(date).format(format || PATTERNS.DATE_FORMAT).toString();
  },
  formatLocalDate (date, format) {
    return !date || date === '' ? '' : moment.utc(date).local().format(format || PATTERNS.DATE_FORMAT).toString();
  },
  formatUTCDate (date, format) {
    return !date || date === '' ? '' : moment.utc(date).format(format || PATTERNS.DATE_FORMAT).toString();
  },
  subsDate (date, amountDate) {
    return moment(date).subtract(amountDate, 'days');
  },
  addTax10 (number) {
    const amount = number + Math.round(number / 100 * 10);
    return amount;
  },
  tax10 (number) {
    const amount = Math.round(number / 100 * 10);
    return amount;
  },
  formatThousandsNumber (num) {
    if (!num && num !== 0) {
      return '-';
    }
    const numParts = num.toString().split('.');
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return numParts.join('.');
  },
  toCamelCaseKey (obj) {
    if (Array.isArray(obj)) {
      return obj.map(v => Util.toCamelCaseKey(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: Util.toCamelCaseKey(obj[key])
        }),
        {}
      );
    }
    return obj;
  },
  findTableHeading (list, name) {
    if (!list || list.length === 0) {
      return;
    }
    const section = list.find(section => name.includes(section.name));
    return findHeadingRecursive(section.subSections, name);
  },
  getRangeOfYears (startYear, numberOfYears, isDecreasing) {
    const years = [];
    if (isNumber(startYear)) {
      let num = 0;
      while (num <= numberOfYears) {
        years.push(isDecreasing ? startYear-- : startYear++);
        num++;
      }
    }
    return years;
  },
  getBrowserLanguage () {
    const language = (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
      navigator.language || // All browsers
      navigator.userLanguage; // IE <= 10

    if (language.indexOf('-') !== -1) {
      const lang = language.split('-')[0];
      return SUPPORTED_LOCALES.indexOf(lang) !== -1 ? lang : DEFAULT_LOCALE;
    }

    return SUPPORTED_LOCALES.indexOf(language) !== -1 ? language : DEFAULT_LOCALE;
  },
  // Sort Array of object by id asc function
  sortById (a, b) {
    const keyA = new Date(a.id);
    const keyB = new Date(b.id);
    // Compare the 2 ids
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
  },
  sortByName (a, b) {
    if (a.name > b.name) return 1;
    if (b.name > a.name) return -1;
    return 0;
  },
  sortByOrderNumber (a, b) {
    const keyA = new Date(a.orderNumber);
    const keyB = new Date(b.orderNumber);
    // Compare the 2 number
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
  },
  sortByOrdinalNumber (data) {
    return data.sort(function (a, b) {
      const keyA = a.ordinalNumber;
      const keyB = b.ordinalNumber;
      // Compare the 2 number
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  },
  getShowHideField (sectionName, fieldName, type = SECTION_TYPES.DISCLOSURE.value) {
    if (type === SECTION_TYPES.DISCLOSURE.value) {
      if (sectionName === 'A1') {
        const fields = ['jrctNumber', 'payee', 'facility', 'name', 'numberOfPayments', 'amount'];
        return !fields.includes(fieldName);
      } else if (['A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'B1', 'B2', 'C1-3', 'C3-2'].includes(sectionName)) {
        const fields = ['facility', 'numberOfPayments', 'amount'];
        return !fields.includes(fieldName);
      } else if (sectionName === 'A8') {
        return fieldName !== 'amount';
      } else if (['B3', 'B4', 'B5'].includes(sectionName)) {
        const fields = ['facility', 'amount'];
        return !fields.includes(fieldName);
      } else if (['C1-1', 'C1-2', 'C2-1', 'C3-1'].includes(sectionName)) {
        const fields = ['facility', 'name', 'numberOfPayments', 'amount'];
        return !fields.includes(fieldName);
      }
    } else {
      if (sectionName === 'D1') {
        return fieldName !== 'facility';
      }
      return !['facility', 'amount'].includes(fieldName);
    }
    return false;
  },
  findTitleDescriptionRecursive (value, list, type = 'id', isPre = false) {
    if (!list || !value) {
      return null;
    }
    let section;
    if (isPre) {
      section = list.find(item => item[type] === value && item.classify === 2);
    } else {
      section = list.find(item => item[type] === value);
    }
    if (!section) {
      for (const item of list) {
        section = Util.findTitleDescriptionRecursive(value, item.subSections, type, isPre);
        if (section) {
          break;
        }
      }
    }
    return section;
  },
  parseCSV (file, delimiter, callback) {
    if (!file) {
      return;
    }
    const reader = new FileReader();

    // When the FileReader has loaded the file...
    reader.onload = function () {
      // Split the result to an array of lines
      const lines = this.result.split('\n');

      // Split the lines themselves by the specified
      // delimiter, such as a comma
      const result = lines.map(function (line) {
        return line.split(delimiter);
      });

      // As the FileReader reads asynchronously,
      // we can't just return the result; instead,
      // we're passing it to a callback function
      callback(result);
    };

    // Read the file content as a single string
    reader.readAsText(file);
  },
  csvToArray (file) {
    return new Promise((resolve, reject) => {
      Util.parseCSV(file, ',', (result) => {
        resolve(result);
      });
    });
  },
  async verifyCSVYear (file, year) {
    const errors = [];
    const name = file.name;
    const extension = name.split('.').pop();
    if (extension !== 'csv') {
      errors.push({
        id: 'validate.file.notAllowed',
        value: name
      });
    } else {
      const result = await Util.csvToArray(file);
      if (result && result.length < 3) {
        errors.push({
          id: 'validate.file.invalid',
          value: name
        });
      } else {
        const lineArray = result[2];
        if (!lineArray || lineArray.length === 0) {
          errors.push({
            id: 'validate.file.invalid',
            value: name
          });
        } else if (lineArray[0] !== year) {
          errors.push({
            id: 'validate.file.yearInvalid',
            value: name
          });
        }
      }
    }
    return errors;
  },
  findDataRecordValue (object, detail, key) {
    if (!object || !key) {
      return undefined;
    }
    const camelKey = camelCase(key);
    let value = object[camelKey];
    if (!value) {
      value = detail[camelKey];
    }
    return value;
  },
  getTextAlign (positionId) {
    switch (positionId) {
      case POSITION_CONFIG.CENTER.id:
        return 'text-center';
      case POSITION_CONFIG.LEFT.id:
        return 'text-left';
      case POSITION_CONFIG.RIGHT.id:
        return 'text-right';
      default:
        break;
    }
  },
  getExtension (path) {
    const basename = path.split(/[\\/]/).pop();
    const pos = basename.lastIndexOf('.');
    if (basename === '' || pos < 1) {
      return '';
    }
    return basename.slice(pos + 1);
  },
  convertDaysToMonthsAndDays (days) {
    const months = Math.floor(days / 30);
    const remainingDays = days % 30;
    return {
      months,
      days: remainingDays
    };
  },
  translate (message) { return <FormattedMessage id={message}>{txt => txt}</FormattedMessage> },
  async getUserTrace () {
    const response = await axios.get('https://www.cloudflare.com/cdn-cgi/trace', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/text; charset=utf-8'
      }
    });
    if (response) {
      let data = response.data.replace(/[\r\n]+/g, '","').replace(/=+/g, '":"');
      data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
      return JSON.parse(data);
    }
    return undefined;
  },
  handleGMOPayment (data, gmoCharge, onError) {
    const message = 'toast.error.payment';
    const { cardNumber, cardExpire, securityCode, couponCode, subscriptionPlanId, authUser } = data;
    if (cardNumber && cardExpire && securityCode) {
      const expireSplit = cardExpire.split('/') || [];
      if (authUser && authUser.shopId) {
        Multipayment.getToken({
          cardno: cardNumber,
          expire: expireSplit[1] + expireSplit[0],
          securitycode: securityCode
        }, response => {
          const { resultCode, tokenObject = {} } = response;
          const { token } = tokenObject;
          if (resultCode !== '000') {
            onError(message);
          } else {
            gmoCharge({
              couponCode,
              token,
              subscriptionPlanId
            });
          }
        });
      } else {
        return message;
      }
    } else if (authUser.cardNumber) {
      gmoCharge({
        couponCode,
        subscriptionPlanId
      });
    } else {
      return message;
    }
  },
  handleUpdateCardVeritrans (data, onUpdateCard) {
    const message = 'api.error.841';
    const params = {
      card_number: data.cardNumber,
      card_expire: data.cardExpire,
      security_code: data.securityCode,
      token_api_key: data.tokenApiKey,
      lang: data.lang
    };
    axios({
      method: 'post',
      url: 'https://api.veritrans.co.jp/4gtoken',
      data: { ...params }
    })
      .then((response) => {
        if (response.data.status === 'success') {
          onUpdateCard({
            token: response.data.token
          });
        } else {
          return message;
        }
      })
      .catch(reason => {
        console.log(reason);
        return message;
      });
  },

  handleVeritransPayment (data, onPayment) {
    const { subscriptionPlanId, couponCode, ...request } = data;
    const params = {
      card_number: request.cardNumber,
      card_expire: request.cardExpire,
      security_code: request.securityCode,
      token_api_key: request.tokenApiKey,
      lang: request.lang
    };
    const message = 'api.error.841';
    axios({
      method: 'post',
      url: 'https://api.veritrans.co.jp/4gtoken',
      data: { ...params }
    })
      .then((response) => {
        if (response.data.status === 'success') {
          onPayment({
            cardHolderEmail: data.cardHolderEmail,
            cardHolderName: data.cardHolderName,
            customerIp: data.customerIp,
            redirectUrl: data.url,
            subscriptionPlanId: subscriptionPlanId,
            token: response.data.token,
            couponCode: couponCode,
            isClose: data.totalPayment === '¥0'
          });
        } else {
          return message;
        }
      })
      .catch(reason => {
        console.log(reason);
        return message;
      });
  },
  decrypt (password, salt, encryptedText) {
    const password1 = 'uKnJoxlZRnWe08sB4X5qLGseKgZVI6su';
    const salt1 = '32e63c1b532c4f6f9e1312e1d50440b2';
    const data = '111111';

    const key256Bits = CryptoJS.PBKDF2(password1, salt1, { hasher: CryptoJS.algo.SHA256, keySize: 256 / 32, iterations: 65536 });

    const parsedIv = CryptoJS.lib.WordArray.create(key256Bits.words.slice(0, 4), 16);

    const encryptedText1 = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key256Bits, { iv: parsedIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    const encryptedText2 = encryptedText1.toString();
    console.log(encryptedText2);
    console.log(CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedText1.toString())));

    const decryptedWord = CryptoJS.AES.decrypt(encryptedText2, key256Bits, { iv: parsedIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

    const decryptedText = CryptoJS.enc.Base64.parse(CryptoJS.enc.Utf8.stringify(decryptedWord)).toString(CryptoJS.enc.Utf8);
    console.log(decryptedText);

    return decryptedText;
  },
  generateRandomString (length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
};

export default Util;
