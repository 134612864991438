const _prefix = '@search/';

export default {
  GET_DATA_REQUEST: _prefix + 'GET_DATA_REQUEST',
  GET_DATA_SUCCESS: _prefix + 'GET_DATA_SUCCESS',
  GET_DATA_ERROR: _prefix + 'GET_DATA_ERROR',

  SEARCH_FULL_TEXT_REQUEST: _prefix + 'SEARCH_FULL_TEXT_REQUEST',
  SEARCH_FULL_TEXT_SUCCESS: _prefix + 'SEARCH_FULL_TEXT_SUCCESS',
  SEARCH_FULL_TEXT_ERROR: _prefix + 'SEARCH_FULL_TEXT_ERROR',

  GET_PUBLIC_DATA_REQUEST: _prefix + 'GET_PUBLIC_DATA_REQUEST',
  GET_PUBLIC_DATA_SUCCESS: _prefix + 'GET_PUBLIC_DATA_SUCCESS',
  GET_PUBLIC_DATA_ERROR: _prefix + 'GET_PUBLIC_DATA_ERROR',

  GET_CONTENT_INFO_REQUEST: _prefix + 'GET_CONTENT_INFO_REQUEST',
  GET_CONTENT_INFO_SUCCESS: _prefix + 'GET_CONTENT_INFO_SUCCESS',
  GET_CONTENT_INFO_ERROR: _prefix + 'GET_CONTENT_INFO_ERROR',

  GET_SETTING_REQUEST: _prefix + 'GET_SETTING_REQUEST',
  GET_SETTING_SUCCESS: _prefix + 'GET_SETTING_SUCCESS',
  GET_SETTING_ERROR: _prefix + 'GET_SETTING_ERROR',

  GET_OPTIONS_REQUEST: _prefix + 'GET_OPTIONS_REQUEST',
  GET_OPTIONS_SUCCESS: _prefix + 'GET_OPTIONS_SUCCESS',

  RESET_PAGE: _prefix + 'RESET_PAGE',
  RESET_SETTING: _prefix + 'RESET_SETTING',

  SYNC_CONTENT_DETAIL: _prefix + 'SYNC_CONTENT_DETAIL',
  SELECTED_TAB_ID: _prefix + 'SELECTED_TAB_ID',

  SYNC_CONTENT_SEARCH: _prefix + 'SYNC_CONTENT_SEARCH',

  STATE_SEARCH_SET_INITIAL_VALUES: _prefix + 'STATE_SEARCH_SET_INITIAL_VALUES',
  STATE_SEARCH_SET_ID_TAB_SEARCH: _prefix + 'STATE_SEARCH_SET_ID_TAB_SEARCH',
  STATE_SEARCH_SET_OPEN_SEARCH_FULL_SCREEN: _prefix + 'STATE_SEARCH_SET_OPEN_SEARCH_FULL_SCREEN',

  SET_IS_FULL_TEXT_SEARCH: _prefix + 'SET_IS_FULL_TEXT_SEARCH',
  SET_SPECIAL_CONTENT_GROUP_ID: _prefix + 'SET_SPECIAL_CONTENT_GROUP_ID',

  GET_CONTENT_BY_CONTENT_GROUP_SPECIAL_REQUEST: _prefix + 'GET_CONTENT_BY_CONTENT_GROUP_SPECIAL_REQUEST',
  GET_CONTENT_BY_CONTENT_GROUP_SPECIAL_SUCCESS: _prefix + 'GET_CONTENT_BY_CONTENT_GROUP_SPECIAL_SUCCESS',
  GET_CONTENT_BY_CONTENT_GROUP_SPECIAL_ERROR: _prefix + 'GET_CONTENT_BY_CONTENT_GROUP_SPECIAL_ERROR'

};
