import { all, put, takeEvery } from 'redux-saga/effects';
import snakecaseKeys from 'snakecase-keys';
import createRequest from '../../api/httpRequest';
import API from '../../api/transaction';
import Util from '../../utils/Util';
import TransactionTypes from './TransactionTypes';

function * loadPaymentHisotryPage () {
  yield takeEvery(TransactionTypes.GET_TRANSACTION_REQUEST, _loadPaymentHisotryPage);
}
function * _loadPaymentHisotryPage ({ payload }) {
  const request = snakecaseKeys({ ...payload });

  yield createRequest({
    api: { ...API.GET_PAYMENT_HISTORY_PAGE, params: { ...request } },
    onSuccess: function * (response) {
      yield put({
        type: TransactionTypes.GET_TRANSACTION_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: TransactionTypes.GET_TRANSACTION_ERROR,
        payload: status
      });
    }
  });
}

export default function * transactionSaga () {
  yield all([
    loadPaymentHisotryPage()
  ]);
}
