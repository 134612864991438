
export default {
  GET_USER_DETAIL: {
    url: '/user/detail/',
    method: 'GET'
  },
  UPDATE_USER_INFO: {
    url: '/user/update/',
    method: 'PUT'
  },
  RESET_USER_PASSWORD: {
    url: '/user/reset-password/',
    method: 'PUT'
  },
  CHANGE_USER_PASSWORD: {
    url: '/user/change-password',
    method: 'PUT'
  },
  FORGOT_PASSWORD: {
    url: '/auth/forgot-password',
    method: 'PUT'
  },
  CHANGE_USER_EMAIL: {
    url: '/user/change-email/',
    method: 'PUT'
  },
  GET_USER_LIST: {
    url: '/user/page',
    method: 'GET'
  },
  ADD_USER: {
    url: '/user/add',
    method: 'POST'
  },
  DELETE_USER: {
    url: '/user/delete/',
    method: 'delete'
  },
  EXPORT_USERS: {
    url: '/user/export-csv',
    method: 'GET'
  },
  CANCEL_USER: {
    url: '/user/cancel',
    method: 'DELETE'
  }
};
