import { all } from 'redux-saga/effects';
import notificationSaga from './notification/notificationSaga';
import sessionSaga from './session/sessionSaga';
import handleApiError from './handler/errorSaga';
import routeSaga from './route/routeSaga';
import accountSaga from './account/accountSaga';
import contentSaga from './content/contentSaga';
import companySaga from './company/companySaga';
import searchSaga from './search/searchSaga';
import buttonSaga from './button/buttonSaga';
import bannerSaga from './banner/bannerSaga';
import sortSaga from './sort/sortSaga';
import templateSaga from './template/templateSaga';
import inputSaga from './input/inputSaga';
import layoutSaga from './layout/layoutSaga';
import homeSaga from './home/homeSaga';
import watermarkSaga from './watermark/watermarkSaga';
import contentDetailSaga from './content-detail/contentDetailSaga';
import customLoginSaga from './login/loginSaga';
import subscriptionSaga from './subscription/SubscriptionSaga';
import couponSaga from './coupon/CouponSaga';
import paymentSaga from './payment/PaymentSaga';
import subscriptionOrdinalSaga from './subscription-ordinal/SubscriptionOrdinalSaga';
import transactionSaga from './transaction/TransactionSaga';
import notificationsSaga from './notifications/notificationsSaga';
// single entry point to start all Sagas at once
export default function * rootSaga () {
  yield all([
    sessionSaga(),
    notificationSaga(),
    handleApiError(),
    routeSaga(),
    accountSaga(),
    contentSaga(),
    companySaga(),
    searchSaga(),
    buttonSaga(),
    bannerSaga(),
    sortSaga(),
    templateSaga(),
    inputSaga(),
    layoutSaga(),
    homeSaga(),
    contentDetailSaga(),
    watermarkSaga(),
    customLoginSaga(),
    subscriptionSaga(),
    couponSaga(),
    paymentSaga(),
    subscriptionOrdinalSaga(),
    transactionSaga(),
    notificationsSaga()
  ]);
}
