import React, { useEffect, useState } from 'react';
import {
  Button, FormGroup
} from 'reactstrap';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { connect, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { AUTHEN_TYPES, GOOGLE_OPTION, MICROSOFT_LOGIN_OPTION, OPTION_LOGIN, STORAGE_KEYS } from 'configs/AppConfig';
import { ActionContext } from 'components/layout/MainContainer';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Util from 'utils/Util';
import * as Yup from 'yup';
import InputFieldButtonRight from 'components/common/Input/InputFieldButtonRight';
import { makeStyles } from '@material-ui/core';
import {
  getAllowLoginByIpAddress,
  getTitleOfEmailField,
  getTitleOfPasswordField,
  getGoogleOption,
  getGoogleId,
  getAllowForgotPassword,
  getMicrosoftLoginOption
} from 'state/search/searchSelector';
import GoogleLoginButton from 'components/common/Button/GoogleLoginButton';
import { getAccountType, getServicePath, getViewSettings } from 'state/company/companySelector';
import StorageHelper from 'utils/StorageHelper';
import MicrosoftLoginButton from 'components/common/Button/MicrosoftLoginButton';

const useStyles = makeStyles(theme => ({
  form: {
    // width: '100vw',
    maxWidth: '400px',
    margin: 'auto',
    '& input': {
      minHeight: 'unset!important',
      fontSize: '1rem',
      borderColor: '#ced4da',
      flex: 'none'
    },
    '& input:focus': {
      borderColor: '#80bdff',
      outline: 0,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)!important'
    },
    '& input:hover': {
      borderColor: '#ced4da'
    }
  },
  logo: {
    marginRight: '10px',
    width: '20px',
    height: '20px'
  }
}));

const LoginEndUserForm = props => {
  const {
    onSubmit,
    accountType,
    allowLoginByIpAddress,
    titleOfEmailField,
    titleOfPasswordField,
    googleOption,
    isShowViewBook,
    contentDetailId,
    searchRequest,
    activeTab,
    isActiveForgotPassword,
    isSubscriptionDetail,
    microsoftOption,
    viewSettings
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const clientId = useSelector(getGoogleId);
  const servicePath = useSelector(getServicePath);
  const hideForgotPassword = !isActiveForgotPassword;
  const [isHideForgotPassword, setIsHideForgotPassword] = useState(false);

  // const locale = useSelector(getCurrentLocale);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(Util.translate('validate.required')),
    password: Yup.string().required(Util.translate('validate.required'))
  });
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (accountType === OPTION_LOGIN.GAKKEN_ID) {
      if (isSubscriptionDetail) {
        StorageHelper.setSessionItem(STORAGE_KEYS.urlSubscDetail, window.location.href);
      }
      history.push('/login');
    }
    switch (accountType) {
      case OPTION_LOGIN.ALL:
      case OPTION_LOGIN.EMAIL:
        setIsHideForgotPassword(true);
        break;
      case OPTION_LOGIN.OPEN_TYPE:
      case OPTION_LOGIN.USERNAME:
        setIsHideForgotPassword(false);
        break;
      default:
        setIsHideForgotPassword(false);
        break;
    }
  }, [accountType]);

  const submit = data => {
    const request = data;
    request.path = servicePath;
    onSubmit(request, AUTHEN_TYPES.EMAIL);
  };

  const doIpLogin = async () => {
    const userTrace = await Util.getUserTrace();
    const ip = userTrace.ip;
    const request = {
      ip: ip,
      loginType: AUTHEN_TYPES.IP
    };
    onSubmit(request, AUTHEN_TYPES.IP);
  };

  const doGoogleLogin = (data) => {
    onSubmit(data, AUTHEN_TYPES.GOOGLE_LOGIN);
  };
  const doMicrosoftLogin = (data) => {
    onSubmit(data, AUTHEN_TYPES.MICROSOFT_LOGIN);
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        email: '',
        password: '',
        rememberMe: false
      }}
      onSubmit={submit}
      validationSchema={validationSchema}
    >
      {formikProps => {
        return (
          <Form className={classes.form} autoComplete="off" aria-autocomplete="none">
            <div className="row">
              <div className="col-sm-12">
                <Field
                  component={InputFieldButtonRight}
                  name="email"
                  label={titleOfEmailField && titleOfEmailField !== ''
                    ? titleOfEmailField
                    : f({ id: accountType === OPTION_LOGIN.ALL ? 'label.emailOrUsername' : accountType === OPTION_LOGIN.EMAIL ? 'label.email' : 'label.username' })
                  }
                  placeholder={titleOfEmailField && titleOfEmailField !== ''
                    ? titleOfEmailField
                    : f({ id: accountType === OPTION_LOGIN.ALL ? 'label.emailOrUsername' : accountType === OPTION_LOGIN.EMAIL ? 'label.email' : 'label.username' })
                  }
                />
              </div>
              <div className="col-sm-12">
                <Field
                  component={InputFieldButtonRight}
                  name="password"
                  label={titleOfPasswordField && titleOfPasswordField !== ''
                    ? titleOfPasswordField
                    : f({ id: 'label.password' })
                  }
                  placeholder={titleOfPasswordField && titleOfPasswordField !== ''
                    ? titleOfPasswordField
                    : f({ id: 'label.password' })
                  }
                  type="password"
                />
              </div>
              <div className="col-sm-12">
                <FormGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    block
                  >
                    <TranslateMessage id="menu.login" />
                  </Button>
                </FormGroup>
              </div>
              {
                allowLoginByIpAddress && (
                  <React.Fragment>
                    <div className="col-sm-12 ">
                      <hr data-content={f({ id: 'conjunction.or' }).toUpperCase()} className="hr-text" />
                    </div>
                    <div className="col-sm-12">
                      <FormGroup>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="button"
                          block
                          onClick={doIpLogin}
                        >
                          <TranslateMessage id="menu.loginIp" />
                        </Button>
                      </FormGroup>
                    </div>
                  </React.Fragment>
                )
              }
              { (accountType === OPTION_LOGIN.EMAIL ||
                accountType === OPTION_LOGIN.ALL) &&
                googleOption !== GOOGLE_OPTION.NONE
                ? <React.Fragment>
                  <div className="col-sm-12">
                    <FormGroup>
                      <GoogleLoginButton
                        clientId={clientId}
                        onSubmit={doGoogleLogin}
                        isShowViewBook={isShowViewBook}
                        contentDetailId={contentDetailId}
                        searchRequest={searchRequest}
                        activeTab={activeTab}
                        isSubscriptionDetail={isSubscriptionDetail}
                      />
                    </FormGroup>
                  </div>
                </React.Fragment>
                : null
              }
              {
                (accountType === OPTION_LOGIN.EMAIL ||
                accountType === OPTION_LOGIN.ALL) &&
                  microsoftOption !== MICROSOFT_LOGIN_OPTION.NONE
                  ? <div className="col-sm-12">
                    <FormGroup>
                      <MicrosoftLoginButton
                        clientId={viewSettings.clientIdMicrosoftSso}
                        onSubmit={doMicrosoftLogin}
                      />
                    </FormGroup>
                  </div>
                  : null
              }
              {
                (!hideForgotPassword || isHideForgotPassword) && (
                  <div className="col-sm-12 text-center mb-3">
                    <Link to={'/forget-password'}>
                      <TranslateMessage id="label.forgotPassword" />
                    </Link>
                  </div>
                )
              }
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

LoginEndUserForm.propTypes = {
  onSubmit: PropTypes.func,
  allowLoginByIpAddress: PropTypes.bool,
  titleOfEmailField: PropTypes.string,
  titleOfPasswordField: PropTypes.string,
  googleOption: PropTypes.string,
  microsoftOption: PropTypes.string,
  isShowViewBook: PropTypes.bool,
  contentDetailId: PropTypes.any,
  searchRequest: PropTypes.any,
  activeTab: PropTypes.any,
  accountType: PropTypes.string,
  isActiveForgotPassword: PropTypes.bool,
  viewSettings: PropTypes.object,
  isSubscriptionDetail: PropTypes.bool
};
const mapStateToProps = (state) => {
  return {
    accountType: getAccountType(state),
    allowLoginByIpAddress: getAllowLoginByIpAddress(state),
    titleOfEmailField: getTitleOfEmailField(state),
    titleOfPasswordField: getTitleOfPasswordField(state),
    googleOption: getGoogleOption(state),
    isActiveForgotPassword: getAllowForgotPassword(state),
    viewSettings: getViewSettings(state),
    microsoftOption: getMicrosoftLoginOption(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {() => (
        <LoginEndUserForm {...props} />
      )}
    </ActionContext.Consumer>
  ));
