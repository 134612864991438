import SubscriptionTypes from './SubscriptionTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  isSubscriptionDetailLoad: true,
  isListLoad: true,
  isLoading: true,
  subscriptionList: [],
  pagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0,
    search_key: ''
  },

  subscriptionDetail: {
    name: '',
    duration: 0,
    price: 0,
    description: '',
    subscriptionContentGroups: [],
    durationType: 0
  },
  isSaving: false,
  isOpenPopUp: false,
  isLoadFail: false,
  subscriptionRegistered: {
    price: 0
  }
};

export default function subscriptionReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case SubscriptionTypes.GET_SUBSCRIPTION_PAGE_SUCCESS:
      newState.subscriptionList = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageSize;
      newState.pagination.page = payload.pageNumber;
      newState.pagination = { ...newState.pagination };
      newState.isLoading = false;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_SUCCESS:
      for (const key in payload) {
        if (!payload[key] && payload[key] !== 0) {
          payload[key] = '';
        }
      }
      newState.subscriptionDetail = { ...payload };
      newState.isSubscriptionDetailLoad = false;
      newState.isLoadFail = false;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_REQUEST:
      newState.isSubscriptionDetailLoad = true;
      newState.isLoadFail = false;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_ERROR:
      newState.isSubscriptionDetailLoad = false;
      newState.isLoadFail = true;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_LIST_REQUEST:
      newState.isListLoad = true;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_LIST_SUCCESS:
      newState.subscriptionList = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageSize;
      newState.pagination.page = payload.pageNumber;
      newState.pagination = { ...newState.pagination };
      newState.isListLoad = false;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_PAGE_ERROR:
      newState.isLoading = false;
      return newState;
    case SubscriptionTypes.GET_SUBSCRIPTION_PAGE_REQUEST:
      newState.isLoading = true;
      return newState;
    case SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_PAGE_REQUEST:
      newState.isListLoad = true;
      newState.pagination.search_key = payload.search_key || '';
      newState.pagination.page = payload.page;
      newState.pagination.size = payload.size;
      newState.pagination = { ...newState.pagination };
      return newState;
    case SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_PAGE_SUCCESS:
      newState.subscriptionList = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageSize;
      newState.pagination.page = payload.pageNumber;
      newState.pagination = { ...newState.pagination };
      newState.isListLoad = false;
      return newState;
    case SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_DETAIL_SUCCESS:
      for (const key in payload) {
        if (!payload[key] && payload[key] !== 0) {
          payload[key] = '';
        }
      }
      // newState.subscriptionDetail = { ...payload };
      newState.subscriptionRegistered = { ...payload };
      newState.isSubscriptionDetailLoad = false;
      return newState;
    case SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_DETAIL_REQUEST:
      newState.isSubscriptionDetailLoad = true;
      return newState;
    case SubscriptionTypes.ADD_SUBSCRIPTION_REQUEST:
      newState.isSaving = true;
      return newState;
    case SubscriptionTypes.UPDATE_SUBSCRIPTION_REQUEST:
      newState.isSaving = true;
      return newState;
    case SubscriptionTypes.OPEN_SUBSCRIPTION_POPUP:
      newState.isOpenPopUp = true;
      return newState;
    case SubscriptionTypes.CLOSE_SUBSCRIPTION_POPUP:
      newState.isOpenPopUp = false;
      return newState;
    case SubscriptionTypes.SAVE_SUBSCRIPTION_SUCCESS:
      newState.isSaving = false;
      newState.isOpenPopUp = false;
      return newState;
    case SubscriptionTypes.SAVE_SUBSCRIPTION_ERROR:
      newState.isSaving = false;
      return newState;
    default:
      newState = state;
      break;
  }

  // return new state
  return newState;
}
