import StorageHelper from 'utils/StorageHelper';
import { STORAGE_KEYS } from 'configs/AppConfig';

const servicePath = StorageHelper.getSessionItem(STORAGE_KEYS.path);
const currentDomain = StorageHelper.getSessionItem(STORAGE_KEYS.domain);
const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;

export const isAuthenticated = state => state.session.isAuthenticated;
export const isSessionValidating = state => state.session.isSessionValidating;
export const isGoogleLogin = state => state.session.isGoogleLogin || StorageHelper.getLocalObject(STORAGE_KEYS.googleLogin) ? StorageHelper.getLocalObject(STORAGE_KEYS.googleLogin).isGoogleLogin : state.session.isGoogleLogin;
export const isMicrosoftLogin = state => state.session.isMicrosoftLogin || StorageHelper.getLocalObject(STORAGE_KEYS.microsoftLogin) ? StorageHelper.getLocalObject(STORAGE_KEYS.microsoftLogin).isMicrosoftLogin : state.session.isMicrosoftLogin;
export const getToken = state => state.session.authToken || StorageHelper.getCookie(userStore);
export const getAuthUser = state => state.session.user;
export const isLoading = state => state.session.isLoading;
export const getShowGakkenTermsAllow = state => state.session.showGakkenTermsAllow;
export const getShowGakkenMyPage = state => state.session.showGakkenMypage;
export const getIsMobileApp = state => state.session.isMobileApp;
export const getIsCallStartAppWithCustomAuth = state => state.session.isCallStartAppWithCustomAuth;
