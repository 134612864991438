import PropTypes from 'prop-types';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ErrorMessage, getIn } from 'formik';
import { useIntl } from 'react-intl';
import TranslateMessage from '../TranslateMessage/TranslateMessage';
import InputMask from 'react-input-mask';

const InputFieldButtonRight = (props) => {
  const {
    field, form,
    type, label, placeholder, disabled, id, checked, errorMessage, isTouched,
    onCheck, rightButtonLabel, onRightButtonClick, showRightButton, showNextRightButton,
    onNextRightButtonClick, rightButtonIcon, rightButtonClass, nextRightButtonLabel,
    isCancel, onChange, mask, maskChar, maxLength, onNextRightButtonClickSecond,
    disabledNextRightButton
  } = props;
  const { name, value } = field;
  const { errors, touched, isValid } = form;
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  let showError = error && touch;
  const { formatMessage: f } = useIntl();

  if (errorMessage) {
    showError = !!errorMessage && isTouched;
  }

  const handleChangeInput = (value) => {
    if (type === 'tel' && value !== '') {
      value = parseInt(value, 10);
    }
    const changeEvent = {
      target: {
        name: name,
        value: type === 'number' ? parseFloat(value) : value
      }
    };
    field.onChange(changeEvent);

    onChange && onChange(value);
  };

  return (
    <FormGroup check={type === 'radio' || type === 'checkbox'}>
      <div className="d-flex">

        {type !== 'radio' && type !== 'checkbox' && (
          <div className="w-100">
            <>
              {label && <Label for={id || name} style={{ display: 'block' }}>{f({ id: label })}</Label>}

              <Input
                id={id || name}
                {...field}
                maxLength={maxLength}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(event) => handleChangeInput(event.target.value)}
                tag={InputMask}
                mask={mask}
                maskChar={maskChar}
                invalid={showError}
              />
            </>
          </div>
        )}
        {(type === 'radio' || type === 'checkbox') && (
          <div className="mr-2 w-100">
            <Label for={id || name} check>
              <Input
                id={id || name}
                {...field}

                type={type}
                disabled={disabled}
                placeholder={placeholder}
                checked={checked || value}
                onChange={(event) => {
                  onCheck(event.target.value);
                  field.onChange(event);
                  onChange && onChange(value);
                }}
                invalid={showError}
              />{' '}
              {f({ id: label })}
            </Label>
          </div>
        )}
        {showRightButton && (
          <div className="d-flex align-items-end">
            <button
              className={`btn btn-${isCancel ? 'danger' : 'info'} text-nowrap`}
              type="button"
              style={{ height: 38 }}
              onClick={onRightButtonClick}
            >{isCancel ? f({ id: 'button.cancel' }) : rightButtonLabel}</button>
          </div>
        )}
        {showNextRightButton && (
          <div className="d-flex align-items-end">
            <button
              className={`btn text-nowrap ${rightButtonClass}`}
              style={{ height: 38 }}
              type="button"
              disabled={disabledNextRightButton}
              onClick={onNextRightButtonClickSecond || (() => onNextRightButtonClick(value, isValid))}
            >
              {nextRightButtonLabel}
              {
                rightButtonIcon
                  ? <i className={rightButtonIcon} aria-hidden="true"> </i>
                  : null
              }
            </button>
          </div>
        )}
      </div>

      {!errorMessage && (
        <ErrorMessage name={name} component={FormFeedback} style={{
          display: showError ? 'inline' : 'none'
        }} />
      )}
      {!!errorMessage && (
        <FormFeedback style={{
          display: showError ? 'inline' : 'none'
        }}>
          <TranslateMessage id={errorMessage} />
        </FormFeedback>
      )}
    </FormGroup>
  );
};

InputFieldButtonRight.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,

  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.any,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  rightButtonLabel: PropTypes.string,
  onRightButtonClick: PropTypes.func,
  onNextRightButtonClick: PropTypes.func,
  onNextRightButtonClickSecond: PropTypes.func,
  showRightButton: PropTypes.bool,
  showNextRightButton: PropTypes.bool,
  rightButtonIcon: PropTypes.string,
  nextRightButtonLabel: PropTypes.string,
  rightButtonClass: PropTypes.string,
  isCancel: PropTypes.bool,
  errorMessage: PropTypes.any,
  isTouched: PropTypes.bool,
  onChange: PropTypes.func,
  mask: PropTypes.any,
  maskChar: PropTypes.any,
  maxLength: PropTypes.number,
  disabledNextRightButton: PropTypes.bool
};

InputFieldButtonRight.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  disabled: false,
  id: '',
  checked: false,
  onCheck: function () {
  },
  rightButtonLabel: 'Text',
  onRightButtonClick: function () {
  },
  onNextRightButtonClick: function () {
  },
  isTouched: false
};

export default InputFieldButtonRight;
