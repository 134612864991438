import Loadable from 'react-loadable';
import forIn from 'lodash/forIn';
import UnauthenticatedRoute from '../components/layout/UnauthenticatedLayout';
import PublicRoute from '../components/layout/PublicLayout';
import NoMatch from '../components/page/NoMatch';
import FlashScreen from '../components/page/FlashScreen';
import uniqBy from 'lodash/uniqBy';
import warning from 'warning';
import Loading from '../components/layout/LoadingRoute';
import { USER_CONFIG } from './AppConfig';
import ViewOnlineLayout from 'components/layout/ViewOnlineLayout';
import HomeLayout from 'components/layout/HomeLayout';

const LoginPage = Loadable({
  loader: () => import('../views/auth/UserLogin'),
  loading: Loading
});

const ForgotPasswordPage = Loadable({
  loader: () => import('../views/auth/ForgotPassword'),
  loading: Loading
});

const SearchViewBook = Loadable({
  loader: () => import('../components/search/SearchViewBook'),
  loading: Loading
});

// Public Pages
const SearchPage = Loadable({
  loader: () => import('../views/end-user/Search'),
  loading: Loading
});

const HomePage = Loadable({
  loader: () => import('../views/end-user/Home'),
  loading: Loading
});

const NotificationsPage = Loadable({
  loader: () => import('../views/end-user/NotificationsPage'),
  loading: Loading
});

const HomeSubscriptionDetail = Loadable({
  loader: () => import('../components/home/HomeSubscriptionDetail'),
  loading: Loading
});

const EndUserProfilePage = Loadable({
  loader: () => import('../views/end-user/UserProfile'),
  loading: Loading
});
const UserSubscription = Loadable({
  loader: () => import('../views/end-user/UserSubscription'),
  loading: Loading
});
const PublicDetailContent = Loadable({
  loader: () => import('../views/end-user/PublicDetailContent'),
  loading: Loading
});
const GakkenPaymentError = Loadable({
  loader: () => import('../components/page/GakkenPaymentError'),
  loading: Loading
});
const GakkenReceivingPage = Loadable({
  loader: () => import('../views/end-user/GakkenPaymentReceivingPage'),
  loading: Loading
});
const GakkenConfirmPage = Loadable({
  loader: () => import('../views/end-user/GakkenPaymentConfirmPage'),
  loading: Loading
});
const VeritransConfirmPage = Loadable({
  loader: () => import('../views/end-user/VeritransConfirmPage'),
  loading: Loading
})
const OrderEntryPage = Loadable({
  loader: () => import('../views/end-user/OrderEntry'),
  loading: Loading
});
const ThankYouPage = Loadable({
  loader: () => import('../views/end-user/ThankyouPage'),
  loading: Loading
})

export const NoMatchPage = NoMatch;
export const FlashScreenPage = FlashScreen;

const routes = {
  unauthorizedRoutes: {
    name: 'unauthenticated',
    layout: UnauthenticatedRoute,
    routes: [
      {
        path: '/login',
        title: 'menu.login',
        component: LoginPage
      },
      {
        path: '/forget-password',
        title: 'label.forgotPassword',
        component: ForgotPasswordPage
      }
    ]
  },
  publicRoutes: {
    name: 'public',
    layout: PublicRoute,
    routes: [
      // {
      //   path: '/profile',
      //   name: 'menu.userProfile',
      //   title: 'label.profile',
      //   component: EndUserProfilePage,
      //   showSidebar: false,
      //   requiredLogin: true
      // },
      {
        path: '/search',
        name: 'menu.search',
        title: 'label.search',
        component: SearchPage
      },
      {
        path: '/search/:contentId',
        name: 'menu.search',
        title: 'label.search',
        component: SearchPage
      }
    ]
  },
  noLayoutRoutes: {
    name: 'public',
    routes: [
      {
        path: '/content/:contentToken',
        name: 'menu.search',
        title: 'label.search',
        component: PublicDetailContent
      },
      {
        path: '/direct-view/:viewToken',
        name: 'menu.user.view',
        title: 'label.viewOnline',
        component: SearchViewBook,
      },
      // {
      //   path: '/gakken-payment/error',
      //   name: 'menu.gakken.payment',
      //   title: 'label.gakkenPaymentError',
      //   component: GakkenPaymentError
      // }
    ]
  },
  viewOnlineRoutes: {
    name: 'viewOnline',
    layout: ViewOnlineLayout,
    routes: [
      {
        path: '/view-online/:id/:contentGroupId',
        name: 'menu.user.view',
        title: 'label.viewOnline',
        component: SearchViewBook,
        showInMenu: false,
        accessRole: [USER_CONFIG.USER.roleLevel]
      },
      {
        path: '/view-online/:id',
        name: 'menu.user.view',
        title: 'label.viewOnline',
        component: SearchViewBook,
        showInMenu: false,
        accessRole: [USER_CONFIG.USER.roleLevel]
      },   
    ]
  },
  homeRoutes: {
    name: 'home',
    layout: HomeLayout,
    routes: [
      {
        path: '/',
        name: 'menu.user.home',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
      {
        path: '/profile',
        name: 'menu.userProfile',
        title: 'label.profile',
        component: EndUserProfilePage,
        showSidebar: false,
        requiredLogin: true
      },
      {
        path: '/notifications',
        name: 'menu.notifications',
        title: 'label.notifications',
        component: NotificationsPage,
        showSidebar: false,
        requiredLogin: true
      },
      {
        path: '/subscription',
        name: 'menu.subscription',
        title: 'label.subscription',
        component: UserSubscription,
        showSidebar: false,
        showWithSubscription: true,
        requiredLogin: true
      },
      {
        path: '/subscription/:subscriptionId',
        name: 'menu.subscription',
        title: 'label.subscription',
        component: HomeSubscriptionDetail,
        showWithSubscription: true,
        requiredLogin: true
      },
      {
        path: '/home-search',
        name: 'menu.user.home',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
      {
        path: '/home-search/:contentGroupId',
        name: 'menu.user.home',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
      {
        path: '/home-search/:contentGroupId/:contentId',
        name: 'menu.user.home',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
      {
        path: '/order-entry/:subscriptionId',
        name: 'menu.userProfile',
        title: 'label.orderEntry',
        component: OrderEntryPage,
        showSidebar: false,
        showInMenu: false,
        requiredLogin: true
      },
      {
        path: '/gakken-payment/processing',
        name: 'menu.userProfile',
        title: 'label.profile',
        component: GakkenReceivingPage,
        showSidebar: false,
        showInMenu: false,
        requiredLogin: true
      },
      {
        path: '/gakken-payment/confirm',
        name: 'menu.userProfile',
        title: 'label.profile',
        component: GakkenConfirmPage,
        showSidebar: false,
        showInMenu: false,
        requiredLogin: true
      },
      {
        path: '/veritrans-payment/confirm',
        name: 'menu.userProfile',
        title: 'label.profile',
        component: VeritransConfirmPage,
        showSidebar: false,
        showInMenu: false,
        requiredLogin: true
      },
      {
        path: '/thank-you',
        name: 'menu.userProfile',
        title: 'label.thankyou',
        component: ThankYouPage,
        showSidebar: false,
        showInMenu: false,
        requiredLogin: true
      },
      {
        path: '/:contentGroupId',
        name: 'menu.user.home',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
      {
        path: '/:contentGroupId/:contentId',
        name: 'menu.user.home',
        title: 'label.home',
        component: HomePage,
        showInMenu: false,
        accessRole: []
      },
    ]
  }
};

function _createRoutes (routes = [], path = '', layout, id) {
  const flattenedRoutes = [];
  routes.forEach(route => {
    const {
      path: subPath,
      layout: subLayout,
      exact,
      routes: subRoutes,
      component,
      relativePath,
      redirect,
      ...rest
    } = route;
    if (component) {
      flattenedRoutes.push(
        Object.assign(
          {
            layoutId: id, // use to compare layout when change route
            layout: subLayout || layout,
            path: path + subPath,
            component: component,
            fullDomain: 'fullDomain _createRoutes',
            ...rest
          },
          subRoutes &&
          !!subRoutes[0] && {
            exact: true,
            routes: _createRoutes(
              subRoutes,
              path + subPath,
              subLayout || layout,
              id
            )
          },
          !subRoutes && {
            exact: true
          },
          redirect && {
            redirect: relativePath ? redirect : path + subPath + redirect
          }
        )
      );
    } else {
      warning(
        false,
        "Missing route's component, config will be ignore %s",
        route.path
      );
    }
  });

  return flattenedRoutes;
}

export default function createRoutes (_servicePath) {
  const flattenedRoutes = [];
  const path = _servicePath && _servicePath !== '' ? `/${_servicePath}` : '';
  let i = 0;
  forIn(routes, ({ name = '', layout, routes: layoutRoutes }) => {
    layoutRoutes &&
      flattenedRoutes.push(
        ..._createRoutes(layoutRoutes, path, layout, `${name}_${i++}`)
      );
  });

  // push no match route
  const filteredDuplicate = uniqBy(flattenedRoutes, 'path');
  warning(
    filteredDuplicate.length === flattenedRoutes.length,
    'There are duplicate path in routes config, only first config are accepted all another config will be ignored'
  );

  return filteredDuplicate;
}

export function flattenRoutes (routes = [], init = []) {
  return routes.reduce((accumulator, { routes, ...rest }) => {
    accumulator.push(rest);
    routes && routes.length > 0 && flattenRoutes(routes, accumulator);
    return accumulator;
  }, init);
}

function _filterMenuRoutes (routes = [], path = '') {
  const menu = [];
  if (routes) {
    routes.forEach(route => {
      if (route.showInMenu) {
        const _route = {};
        if (route.routes) {
          const sub = _filterMenuRoutes(route.routes, path + route.path);
          sub.length > 1 && (_route.routes = sub);
        }

        menu.push(
          Object.assign(
            {},
            {
              path: path + route.path,
              name: route.name || '',
              title: route.title || '',
              exact: route.exact || true,
              iconClass: route.menuIconClass,
              accessRole: route.accessRole || [],
              fullDomain: 'fullDomain'
            },
            _route
          )
        );
      }
    });
  }

  return menu;
}

export function filterMenuRoutes () {
  const menuRoutes = [];
  forIn(routes, ({ layout, routes }) => {
    routes && menuRoutes.push(..._filterMenuRoutes(routes));
  });

  return menuRoutes;
}

export { routes };
