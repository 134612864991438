import { combineReducers } from 'redux';
import { filterActions } from 'redux-ignore';

import errorReducer from './handler/errorReducer';
import ErrorTypes from './handler/ErrorTypes';

import layoutReducer from './layout/layoutReducer';
import LayoutTypes from './layout/LayoutTypes';

import sessionReducer from './session/sessionReducer';
import SessionTypes from './session/SessionTypes';

import localeReducer from './locale/localeReducer';
import LocaleTypes from './locale/LocaleTypes';

import routeReducer from './route/routeReducer';
import RouteTypes from './route/RouteTypes';

import notificationReducer from './notification/notificationReducer';
import NotificationTypes from './notification/NotificationTypes';

import scrollbarReducer from './scrollbar/scrollbarReducer';
import * as ScrollbarActionTypes from './scrollbar/scrollbarActionTypes';

import accountReducer from './account/accountReducer';
import AccountTypes from './account/AccountTypes';

import contentReducer from './content/contentReducer';
import ContentTypes from './content/ContentTypes';

import companyReducer from './company/companyReducer';
import CompanyTypes from './company/CompanyTypes';

import searchReducer from './search/searchReducer';
import SearchTypes from './search/SearchTypes';

import { reducer as formReducer, actionTypes as FormTypes } from 'redux-form';

import buttonReducer from './button/buttonReducer';
import ButtonTypes from './button/ButtonTypes';

import bannerReducer from './banner/bannerReducer';
import BannerTypes from './banner/BannerTypes';

import sortReducer from './sort/sortReducer';
import SortTypes from './sort/SortTypes';

import templateReducer from './template/templateReducer';
import TemplateTypes from './template/TemplateTypes';

import inputReducer from './input/inputReducer';
import InputTypes from './input/InputTypes';

import contentDetailReducer from './content-detail/contentDetailReducer';
import ContentDetailTypes from './content-detail/ContentDetailTypes';

import homeReducer from './home/homeReducer';
import HomeTypes from './home/HomeTypes';

import customLoginReducer from './login/loginReducer';
import CustomLoginTypes from './login/LoginTypes';

import notificationsReducer from './notifications/notificationsReducer';
import NotificationsTypes from './notifications/NotificationsTypes';

import watermarkReducer from './watermark/watermarkReducer';
import WatermarkTypes from './watermark/WatermarkTypes';
import subscriptionReducer from './subscription/SubscriptionReducer';
import SubscriptionTypes from './subscription/SubscriptionTypes';
import couponReducer from './coupon/CouponReducer';
import CouponTypes from './coupon/CouponTypes';
import paymentReducer from './payment/PaymentReducer';
import PaymentTypes from './payment/PaymentTypes';
import subscriptionOrdinalReducer from './subscription-ordinal/SubscriptionOrdinalReducer';
import SubscriptionOrdinalTypes from './subscription-ordinal/SubscriptionOrdinalTypes';
import transactionReducer from './transaction/TransactionReducer';
import TransactionTypes from './transaction/TransactionTypes';

const rootReducer = combineReducers({
  error: filterActions(errorReducer, Object.values(ErrorTypes)),
  layout: filterActions(layoutReducer, Object.values(LayoutTypes)),
  session: filterActions(sessionReducer, Object.values(SessionTypes)),
  locale: filterActions(localeReducer, Object.values(LocaleTypes)),
  route: filterActions(routeReducer, Object.values(RouteTypes)),
  notification: filterActions(notificationReducer, Object.values(NotificationTypes)),
  scrollbar: filterActions(scrollbarReducer, Object.values(ScrollbarActionTypes)),
  form: filterActions(formReducer, Object.values(FormTypes)),
  account: filterActions(accountReducer, Object.values(AccountTypes)),
  content: filterActions(contentReducer, Object.values(ContentTypes)),
  company: filterActions(companyReducer, Object.values(CompanyTypes)),
  search: filterActions(searchReducer, Object.values(SearchTypes)),
  button: filterActions(buttonReducer, Object.values(ButtonTypes)),
  banner: filterActions(bannerReducer, Object.values(BannerTypes)),
  watermark: filterActions(watermarkReducer, Object.values(WatermarkTypes)),
  sort: filterActions(sortReducer, Object.values(SortTypes)),
  template: filterActions(templateReducer, Object.values(TemplateTypes)),
  input: filterActions(inputReducer, Object.values(InputTypes)),
  home: filterActions(homeReducer, Object.values(HomeTypes)),
  customLogin: filterActions(customLoginReducer, Object.values(CustomLoginTypes)),
  contentDetail: filterActions(contentDetailReducer, Object.values(ContentDetailTypes)),
  subscription: filterActions(subscriptionReducer, Object.values(SubscriptionTypes)),
  coupon: filterActions(couponReducer, Object.values(CouponTypes)),
  payment: filterActions(paymentReducer, Object.values(PaymentTypes)),
  subscriptionOrdinal: filterActions(subscriptionOrdinalReducer, Object.values(SubscriptionOrdinalTypes)),
  transaction: filterActions(transactionReducer, Object.values(TransactionTypes)),
  notifications: filterActions(notificationsReducer, Object.values(NotificationsTypes))
});

export default rootReducer;
