import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AUTHEN_TYPES, DOMAIN_TYPE, STORAGE_KEYS } from 'configs/AppConfig';
import { Button } from 'reactstrap';
import TranslateMessage from '../../../components/common/TranslateMessage/TranslateMessage';
import googleLogo from '../../../assets/img/icons/google.svg';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { getServicePath, getViewSettings } from 'state/company/companySelector';
import StorageHelper from 'utils/StorageHelper';
import { ActionContext } from 'components/layout/MainContainer';
const useStyles = makeStyles(theme => ({
  logo: {
    marginRight: '10px',
    width: '20px',
    height: '20px'
  }
}));
const GoogleLoginButton = (props) => {
  const {
    clientId,
    onSubmit,
    isShowViewBook,
    contentDetailId,
    searchRequest,
    activeTab,
    isSubscriptionDetail,
    notificationActions: {
      showNotification
    }
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const servicePath = useSelector(getServicePath);
  const viewSettings = useSelector(getViewSettings);
  const [OAuth2, setOAuth2] = useState();
  const search = window.location.search;
  const pathname = window.location.pathname;

  const isFullDomain = viewSettings.domainType === DOMAIN_TYPE.FULL_DOMAIN;
  const redirectUrl = isFullDomain ? `${window.location.origin}/login` : `${window.location.origin}/${servicePath}/login`;

  useEffect(() => {
    /* global google */
    if (window.google) {
      const oAuth2 = google.accounts.oauth2.initCodeClient({
        client_id: clientId,
        scope: 'profile email',
        ux_mode: 'redirect',
        redirect_uri: redirectUrl,
        state: '',
        response_type: 'code',
        flow_name: 'GeneralOAuthFlow',
        grant_type: 'authorization_code'
      });
      setOAuth2(oAuth2);
    }
  }, []);
  const handleOAuth2 = () => {
    const data = {
      cacheRoute: window.location.pathname,
      isShowViewBook: isShowViewBook,
      contentId: contentDetailId,
      searchRequest: searchRequest,
      activeTab: activeTab
    };
    StorageHelper.setSessionObject(STORAGE_KEYS.cacheStorage, data);
    if (isSubscriptionDetail) {
      StorageHelper.setSessionItem(STORAGE_KEYS.urlSubscDetail, window.location.href);
    }
    if (OAuth2) {
      StorageHelper.setSessionItem(STORAGE_KEYS.loginType, AUTHEN_TYPES.GOOGLE_LOGIN);
      OAuth2.requestCode();
    }
  };

  // Handle when success
  useEffect(() => {
    const loginType = StorageHelper.getSessionItem(STORAGE_KEYS.loginType);
    if (search && search.includes('?code=') && loginType === AUTHEN_TYPES.GOOGLE_LOGIN) {
      const code = search.split('&')[0].replace('?code=', '');
      if (code) {
        // setStopAutoLoad(true);
        const params = {
          authorizeCode: decodeURIComponent(code),
          loginType: AUTHEN_TYPES.GOOGLE_LOGIN,
          redirectUrl: redirectUrl
        };
        onSubmit(params, AUTHEN_TYPES.GOOGLE_LOGIN);
        StorageHelper.setSessionItem(STORAGE_KEYS.loginType, null);
      } else {
        console.log('code is null');
      }
    }
  }, []);

  // Handle when Error
  useEffect(() => {
    if (search && search.includes('?error=')) {
      const error = search.split('&')[0].replace('?error=', '');
      if (error) {
        showNotification({
          message: 'toast.error.loginFailed',
          level: 'error',
          autoDismiss: 3,
          position: 'tc'
        });
        StorageHelper.setSessionItem(STORAGE_KEYS.loginType, null);
        history.push('/login');
      } else {
        console.log('error is null');
      }
    }
  }, []);

  useEffect(() => {
    if (search && (search === '?google=true' || search === '?google=true&fl=true') && pathname && pathname.includes('/login')) {
      // const isRedirect = search.replace('?google=', '');
      // if (isRedirect === 'true') {
      if (OAuth2) {
        StorageHelper.setSessionItem(STORAGE_KEYS.loginType, AUTHEN_TYPES.GOOGLE_LOGIN);
        OAuth2.requestCode();
      }
      // }
    }
  }, [OAuth2]);

  return (
    <div>
      <Button
        outline
        variant="contained"
        color="secondary"
        type="button"
        block
        onClick={handleOAuth2}
      > <span>
          <img src={googleLogo} className={classes.logo} style={{ width: '20px', height: '20px', marginRight: '10px' }}/>
          <TranslateMessage id="menu.loginGoogle" />
        </span>
      </Button>
    </div>
  );
};

GoogleLoginButton.propTypes = {
  isAutoLoad: PropTypes.bool,
  clientId: PropTypes.any,
  onSubmit: PropTypes.func,
  isShowViewBook: PropTypes.bool,
  contentDetailId: PropTypes.any,
  searchRequest: PropTypes.any,
  activeTab: PropTypes.any,
  isSubscriptionDetail: PropTypes.bool,
  notificationActions: PropTypes.object.isRequired
};

export default connect()(
  props => (
    <ActionContext.Consumer>
      {({ notificationActions }) => (
        <GoogleLoginButton
          {...props}
          notificationActions={notificationActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
