import CouponTypes from './CouponTypes';

export function getCouponPage (data) {
  return {
    type: CouponTypes.GET_COUPON_PAGE_REQUEST,
    payload: data
  };
}

export function getCouponList (data) {
  return {
    type: CouponTypes.GET_COUPON_LIST_REQUEST,
    payload: data
  };
}

export function getCouponDetail (data) {
  return {
    type: CouponTypes.GET_COUPON_DETAIL_REQUEST,
    payload: data
  };
}

export function addCoupon (data) {
  return {
    type: CouponTypes.ADD_COUPON_REQUEST,
    payload: data
  };
}

export function getCouponUser (data) {
  return {
    type: CouponTypes.GET_COUPON_USER_REQUEST,
    payload: data
  };
}

export function setCouponUserNull () {
  return {
    type: CouponTypes.SET_COUPON_USER_NULL
  };
}
