const _prefix = '@payment/';

export default {
  ADD_CARD_REQUEST: _prefix + 'ADD_CARD_REQUEST',
  UPDATE_CARD_REQUEST: _prefix + 'UPDATE_CARD_REQUEST',
  DELETE_CARD_REQUEST: _prefix + 'DELETE_CARD_REQUEST',

  RESET_HTML: _prefix + 'RESET_HTML',

  AUTHORIZE_CHARGE_REQUEST: _prefix + 'AUTHORIZE_CHARGE_REQUEST',
  AUTHORIZE_CHARGE_SUCCESS: _prefix + 'AUTHORIZE_CHARGE_SUCCESS',
  AUTHORIZE_CHARGE_ERROR: _prefix + 'AUTHORIZE_CHARGE_ERROR',

  VERITRANS_CREATE_ORDER_RECEIVE: _prefix + 'VERITRANS_CREATE_ORDER_RECEIVE',
  VERITRANS_CREATE_ORDER_RECEIVE_SUCCESS: _prefix + 'VERITRANS_CREATE_ORDER_RECEIVE_SUCCESS',
  VERITRANS_CREATE_ORDER_RECEIVE_ERROR: _prefix + 'VERITRANS_CREATE_ORDER_RECEIVE_ERROR',

  VERITRANS_CONFIRM_ORDER: _prefix + 'VERITRANS_CONFIRM_ORDER',
  VERITRANS_CONFIRM_ORDER_SUCCESS: _prefix + 'VERITRANS_CONFIRM_ORDER_SUCCESS',
  VERITRANS_CONFIRM_ORDER_ERROR: _prefix + 'VERITRANS_CONFIRM_ORDER_ERROR',

  VERITRANS_UPDATE_CARD: _prefix + 'VERITRANS_UPDATE_CARD',
  VERITRANS_UPDATE_CARD_SUCCESS: _prefix + 'VERITRANS_UPDATE_CARD_SUCCESS',
  VERITRANS_UPDATE_CARD_ERROR: _prefix + 'VERITRANS_UPDATE_CARD_ERROR',

  GMO_CHARGE_REQUEST: _prefix + 'GMO_CHARGE_REQUEST',
  GMO_CHARGE_SUCCESS: _prefix + 'GMO_CHARGE_SUCCESS',
  GMO_CHARGE_ERROR: _prefix + 'GMO_CHARGE_ERROR',

  GMO_UPDATE_CARD_REQUEST: _prefix + 'GMO_UPDATE_CARD_REQUEST',
  GMO_UPDATE_CARD_SUCCESS: _prefix + 'GMO_UPDATE_CARD_SUCCESS',
  GMO_UPDATE_CARD_ERROR: _prefix + 'GMO_UPDATE_CARD_ERROR',

  VERIFY_CHARGE_REQUEST: _prefix + 'VERIFY_CHARGE_REQUEST',
  VERIFY_CHARGE_SUCCESS: _prefix + 'VERIFY_CHARGE_SUCCESS',
  VERIFY_CHARGE_ERROR: _prefix + 'VERIFY_CHARGE_ERROR',

  RESET_PAYMENT_ERROR: _prefix + 'RESET_PAYMENT_ERROR',
  RESET_PAYMENT_STATUS: _prefix + 'RESET_PAYMENT_STATUS',

  CREATE_ORDER_RECEIVE_REQUEST: _prefix + 'CREATE_ORDER_RECEIVE_REQUEST',
  CREATE_ORDER_RECEIVE_SUCCESS: _prefix + 'CREATE_ORDER_RECEIVE_SUCCESS',
  CREATE_ORDER_RECEIVE_ERROR: _prefix + 'CREATE_ORDER_RECEIVE_ERROR',

  GAKKEN_PAYMENT_INFO_REQUEST: _prefix + 'GAKKEN_PAYMENT_INFO_REQUEST',
  GAKKEN_PAYMENT_INFO_SUCCESS: _prefix + 'GAKKEN_PAYMENT_INFO_SUCCESS',
  GAKKEN_PAYMENT_INFO_ERROR: _prefix + 'GAKKEN_PAYMENT_INFO_ERROR',

  CONFIRM_ORDER_RECEIVE_REQUEST: _prefix + 'CONFIRM_ORDER_RECEIVE_REQUEST',
  CONFIRM_ORDER_RECEIVE_SUCCESS: _prefix + 'CONFIRM_ORDER_RECEIVE_SUCCESS',
  CONFIRM_ORDER_RECEIVE_ERROR: _prefix + 'CONFIRM_ORDER_RECEIVE_ERROR',

  RESET_GAKKEN_PAYMENT_INFO: _prefix + 'RESET_GAKKEN_PAYMENT_INFO',
  RESET_CREATE_ORDER_RECEIVE: _prefix + 'RESET_CREATE_ORDER_RECEIVE',

  RESET_CREATE_ORDER_RECEIVE_VERITRANS: _prefix + 'RESET_CREATE_ORDER_RECEIVE_VERITRANS',

  SET_CACHE_COUPON_CODE: _prefix + 'SET_CACHE_COUPON_CODE',
  SET_CACHE_SUBSCRIPTION_ID: _prefix + 'SET_CACHE_SUBSCRIPTION_ID',
  SET_CACHE_REDIRECT_URL_CALLBACK: _prefix + 'SET_CACHE_REDIRECT_URL_CALLBACK'
};
