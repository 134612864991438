import { all, put, select, takeEvery, fork, call, take } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import ContentTypes from './ContentTypes';
import createRequest from 'api/httpRequest';
import API from 'api/content';
import NotificationTypes from 'state/notification/NotificationTypes';
import HomeTypes from 'state/home/HomeTypes';
import SearchTypes from 'state/search/SearchTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from 'utils/Util';
import { getCompanyDomain, getServicePath } from 'state/company/companySelector';
import { uploadContent as upload, replaceContent } from 'utils/axios-helper';
import { PAGINATION } from 'configs/AppConfig';
import { saveAs } from 'file-saver';

function * loadContentData () {
  yield takeEvery(ContentTypes.GET_CONTENT_DATA_REQUEST, _loadContentData);
}

function * _loadContentData ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const request = snakecaseKeys({ ...payload, domain });
  yield createRequest({
    api: {
      ...API.GET_CONTENT_DATA,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentTypes.GET_CONTENT_DATA_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ContentTypes.GET_CONTENT_DATA_ERROR,
        payload: status
      });
    }
  });
}

function * loadContentDetail () {
  yield takeEvery(ContentTypes.GET_CONTENT_DETAIL_REQUEST, _loadContentDetail);
}

function * _loadContentDetail ({ payload }) {
  const api = {
    ...API.GET_CONTENT_DETAIL,
    url: API.GET_CONTENT_DETAIL.url + payload
  };
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: ContentTypes.GET_CONTENT_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ContentTypes.GET_CONTENT_DETAIL_ERROR,
        payload: status
      });
    }
  });
}

function * uploadContent () {
  yield takeEvery(ContentTypes.UPLOAD_CONTENT_REQUEST, _uploadFiles);
}

function * _uploadFiles ({ payload }) {
  payload.id = (new Date()).getTime();
  try {
    const [uploadPromise, chan] = yield call(createUploader, payload);
    yield fork(uploadProgressWatcher, chan);
    const res = yield call(() => uploadPromise);
    if (res.data.status !== 200) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.error.${res.data.status}`,
            level: 'error',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    } else {
      yield put({
        type: ContentTypes.SUCCESS_UPLOAD_CONTENT,
        payload: {
          id: res.data.id
        }
      });
      yield put({
        type: ContentTypes.GET_CONTENT_DATA_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    }
  } catch (e) {
    yield put({
      type: ContentTypes.FAILURE_UPLOAD_CONTENT,
      payload: {
        error: e,
        id: payload.id
      }
    });
    yield put({
      type: NotificationTypes.SHOW_NOTIFICATION,
      payload: {
        config: {
          message: `api.error.${e.response.status}`,
          level: 'error',
          autoDismiss: 3,
          position: 'tc'
        }
      }
    });
  }
}

function createUploader (payload) {
  let emit;
  const { contentId } = payload;
  const fileUpload = {
    id: payload.id,
    progress: 0,
    name: payload.file.name
  };
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    fileUpload.progress = percentage;
    emit(fileUpload);
    if (percentage === 100) emit(END);
  };
  const uploadPromise = contentId ? replaceContent(payload, uploadProgressCb) : upload(payload, uploadProgressCb);
  return [uploadPromise, chan];
}

function * uploadProgressWatcher (chan) {
  while (true) { // eslint-disable-line no-constant-condition
    const fileUpload = yield take(chan);
    yield put({
      type: ContentTypes.SET_UPLOAD_PROGRESS,
      payload: fileUpload
    });
  }
}

function * _deleteContent ({ payload }) {
  yield createRequest({
    api: {
      ...API.DELETE_CONTENT,
      url: API.DELETE_CONTENT.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.delete.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ContentTypes.GET_CONTENT_DATA_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    }
  });
}

function * deleteContent () {
  yield takeEvery(ContentTypes.DELETE_CONTENT_REQUEST, _deleteContent);
}

function * _addContent ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.ADD_CONTENT,
      data: request
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ContentTypes.GET_CONTENT_DATA_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    }
  });
}

function * addContent () {
  yield takeEvery(ContentTypes.ADD_CONTENT_REQUEST, _addContent);
}

function * _updateContent ({ payload }) {
  const request = snakecaseKeys({ ...payload.body });
  const pagination = snakecaseKeys({ ...payload.pagination });
  yield createRequest({
    api: {
      ...API.UPDATE_CONTENT,
      data: request,
      url: API.UPDATE_CONTENT.url + payload.id
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: ContentTypes.GET_CONTENT_DATA_REQUEST,
        payload: {
          ...PAGINATION,
          ...pagination
        }
      });
    }
  });
}

function * updateContent () {
  yield takeEvery(ContentTypes.UPDATE_CONTENT_REQUEST, _updateContent);
}

function * loadViewerToken () {
  yield takeEvery(ContentTypes.GET_VIEWER_TOKEN, _loadViewerToken);
}

function * _loadViewerToken ({ payload }) {
  const referer = document.referrer;
  const currentDomain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  yield createRequest({
    api: {
      ...API.GET_VIEWER_TOKEN,
      params: {
        content_id: payload.contentId,
        content_group_id: payload.contentGroupId,
        domain: currentDomain,
        path: servicePath
      },
      headers: {
        'cus-referer': referer || ''
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentTypes.GET_VIEWER_TOKEN_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ContentTypes.GET_VIEWER_TOKEN_ERROR,
        payload: {}
      });
    }
  });
}

function * loadViewerPublicToken () {
  yield takeEvery(ContentTypes.GET_VIEWER_PUBLIC_TOKEN, _loadViewerPublicToken);
}

function * _loadViewerPublicToken ({ payload }) {
  const referer = document.referrer;
  const currentDomain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  yield createRequest({
    api: {
      ...API.GET_VIEWER_PUBLIC_TOKEN,
      params: {
        token_id: payload.tokenId,
        service_path: payload.path,
        domain: currentDomain,
        path: servicePath
      },
      headers: {
        'cus-referer': referer || ''
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentTypes.GET_VIEWER_TOKEN_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ContentTypes.GET_VIEWER_TOKEN_ERROR,
        payload: {}
      });
    }
  });
}

function * exportContents () {
  yield takeEvery(ContentTypes.EXPORT_CONTENTS_REQUEST, _exportContents);
}

function * _exportContents ({ payload }) {
  yield createRequest({
    api: {
      ...API.EXPORT_CONTENTS
    },
    onSuccess: function (response) {
      const blob = new Blob(['\ufeff', response], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, payload);
    }
  });
}

function * _addFavoriteContent ({ payload }) {
  const { contentId, contentGroup } = payload;
  const request = snakecaseKeys({
    contentId,
    contentGroupId: contentGroup.contentGroupId
  });
  yield createRequest({
    api: {
      ...API.ADD_FAVORITE_CONTENT,
      data: request
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: 'api.addFavorite.success',
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: HomeTypes.SYNC_CONTENT_OF_CONTENT_GROUP,
        payload: {
          contentId: contentId,
          isFavorite: true,
          contentGroupId: contentGroup.contentGroupId
        }
      });
      yield put({
        type: SearchTypes.SYNC_CONTENT_DETAIL,
        payload: {
          contentId: contentId,
          isFavorite: true
        }
      });
      yield put({
        type: SearchTypes.SYNC_CONTENT_SEARCH,
        payload: {
          contentId: contentId,
          isFavorite: true
        }
      });
    }
  });
}

function * addFavoriteContent () {
  yield takeEvery(ContentTypes.ADD_FAVORITE_CONTENT, _addFavoriteContent);
}

function * _removeFavoriteContent ({ payload }) {
  const { contentId, contentGroup } = payload;
  yield createRequest({
    api: {
      ...API.REMOVE_FAVORITE_CONTENT,
      url: API.REMOVE_FAVORITE_CONTENT.url + contentId
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: 'api.removeFavorite.success',
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: HomeTypes.SYNC_CONTENT_OF_CONTENT_GROUP,
        payload: {
          contentId: contentId,
          isFavorite: false,
          contentGroupId: contentGroup.contentGroupId
        }
      });
      yield put({
        type: SearchTypes.SYNC_CONTENT_DETAIL,
        payload: {
          contentId: contentId,
          isFavorite: false
        }
      });
      yield put({
        type: SearchTypes.SYNC_CONTENT_SEARCH,
        payload: {
          contentId: contentId,
          isFavorite: false
        }
      });
    }
  });
}

function * removeFavoriteContent () {
  yield takeEvery(ContentTypes.REMOVE_FAVORITE_CONTENT, _removeFavoriteContent);
}

function * _addTrendingContent ({ payload }) {
  const { contentId, contentGroup } = payload;
  const request = snakecaseKeys({
    contentId,
    contentGroupId: contentGroup.contentGroupId
  });
  yield createRequest({
    api: {
      ...API.ADD_TRENDING_CONTENT,
      data: request
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: 'api.addTrending.success',
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: HomeTypes.SYNC_CONTENT_OF_CONTENT_GROUP,
        payload: {
          contentId: contentId,
          isFavorite: true,
          contentGroupId: contentGroup.contentGroupId
        }
      });
      yield put({
        type: SearchTypes.SYNC_CONTENT_DETAIL,
        payload: {
          contentId: contentId,
          isFavorite: true
        }
      });
    }
  });
}

function * addTrendingContent () {
  yield takeEvery(ContentTypes.ADD_TRENDING_CONTENT, _addTrendingContent);
}

function * _removeTrendingContent ({ payload }) {
  const { contentId, contentGroup } = payload;
  yield createRequest({
    api: {
      ...API.REMOVE_TRENDING_CONTENT,
      url: API.REMOVE_TRENDING_CONTENT.url + contentId
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: 'api.removeTrending.success',
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: HomeTypes.SYNC_CONTENT_OF_CONTENT_GROUP,
        payload: {
          contentId: contentId,
          isFavorite: false,
          contentGroupId: contentGroup.contentGroupId
        }
      });
      yield put({
        type: SearchTypes.SYNC_CONTENT_DETAIL,
        payload: {
          contentId: contentId,
          isFavorite: false
        }
      });
    }
  });
}

function * loadSeriesContent () {
  yield takeEvery(ContentTypes.GET_SERIES_CONTENT_REQUEST, _loadSeriesContent);
}

function * _loadSeriesContent ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const path = yield select(getServicePath);
  const request = snakecaseKeys({ ...payload, domain, path });
  yield createRequest({
    api: {
      ...API.GET_SERIES_CONTENT,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentTypes.GET_SERIES_CONTENT_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: ContentTypes.GET_SERIES_CONTENT_ERROR,
        payload: status
      });
    }
  });
}

function * removeTrendingContent () {
  yield takeEvery(ContentTypes.REMOVE_TRENDING_CONTENT, _removeTrendingContent);
}

function * _getDownloadContentId ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_DOWNLOAD_CONTENT_ID,
      params: snakecaseKeys(payload)
    },
    onSuccess: function * (response) {
      yield put({
        type: ContentTypes.GET_DOWNLOAD_CONTENT_ID_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * (response) {
      yield put({
        type: ContentTypes.GET_DOWNLOAD_CONTENT_ID_ERROR,
        payload: response
      });
    }
  });
}

function * getDownloadContentId () {
  yield takeEvery(ContentTypes.GET_DOWNLOAD_CONTENT_ID_REQUEST, _getDownloadContentId);
}

export default function * contentSaga () {
  yield all([
    loadContentData(),
    loadContentDetail(),
    uploadContent(),
    addContent(),
    deleteContent(),
    updateContent(),
    loadViewerToken(),
    loadViewerPublicToken(),
    exportContents(),
    addFavoriteContent(),
    removeFavoriteContent(),
    addTrendingContent(),
    removeTrendingContent(),
    loadSeriesContent(),
    getDownloadContentId()
  ]);
}
