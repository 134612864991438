import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TranslateMessage from '../TranslateMessage/TranslateMessage';
import { withStyles } from '@material-ui/core/styles';

const Style = {
  danger: {
    background: '#ff000075'
  },
  warning: {
    background: '#F08828',
    color: '#fff'
  }
};

const ModalConfirm = props => {
  const {
    message,
    title,
    isOpen,
    toggle,
    className,
    doConfirm,
    doCancel,
    backdrop,
    level,
    classes,
    cancelButtonName
  } = props;

  const confirmAction = () => {
    doConfirm();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} backdrop={backdrop}>
      <ModalHeader className={level === 'danger' ? classes.danger : level === 'warning' ? classes.warning : ''}>{title}</ModalHeader>
      <ModalBody style={{ overflowWrap: 'break-word' }}>
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </ModalBody>
      <ModalFooter>
        <Button color={level === 'danger' ? level : level === 'warning' ? level : 'info'} onClick={confirmAction}>
          <TranslateMessage id="button.yes" />
        </Button>
        <Button className="mr-0" color="secondary" onClick={doCancel}>
          <TranslateMessage id={cancelButtonName || 'button.cancel'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalConfirm.defaultProps = {};

ModalConfirm.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  className: PropTypes.object,
  doConfirm: PropTypes.func,
  doCancel: PropTypes.func,
  backdrop: PropTypes.any,
  level: PropTypes.any,
  classes: PropTypes.any,
  cancelButtonName: PropTypes.any
};

export default withStyles(Style)(props => (<ModalConfirm {...props} />));
