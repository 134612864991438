import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { ActionContext } from 'components/layout/MainContainer';
import { getEndUserCompanyDetail, getPageMargin } from 'state/company/companySelector';
import ScrollTopButton from 'components/common/Scrollbar/ScrollTopButton';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { IMAGE_PATH, LAYOUT_SETTING, LINK_POSITION, LINK_TYPE, POSITION_LINK_FOOTER } from 'configs/AppConfig';
import { Link } from 'react-router-dom';
import { getLayoutViewPage, getPageLogo } from 'state/search/searchSelector';
import { useMedia } from 'react-use';
import Util from 'utils/Util';

const PublicFooter = props => {
  const { pageLogo, layoutViewPage, pageMargin } = props;
  const below768 = useMedia('(max-width: 768px)');
  const setting = useSelector((state) => state.search.footerSetting) || {};

  const upperLinks = setting.links ? Util.sortByOrdinalNumber(setting.links.filter(item => item.positionLink === LINK_POSITION.UPPER_LINK)) : [];
  const lowerLinks = setting.links ? Util.sortByOrdinalNumber(setting.links.filter(item => item.positionLink === LINK_POSITION.LOWER_LINK)) : [];
  const middleLinks = setting.links ? Util.sortByOrdinalNumber(setting.links.filter(item => item.positionLink === LINK_POSITION.MIDDLE_LINK)) : [];
  const isLayout3 = layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id;
  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';

  const getPositionLinkFooter = () => {
    switch (setting.horizontalLinkPosition) {
      case POSITION_LINK_FOOTER.LEFT:
        return 'left';
      case POSITION_LINK_FOOTER.CENTER:
        return 'center';
      case POSITION_LINK_FOOTER.RIGHT:
        return 'right';
      default:
        return '';
    }
  };

  const useStyle = makeStyles(() => (
    {
      setting: {
        padding: '0 1.3rem',
        backgroundColor: setting.backgroundColor,
        color: setting.color,
        fontWeight: 700
      },
      links: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '0.8rem 0',
        '& .footer-item': {
          color: setting.color || '#007bff',
          flexGrow: 1,
          textAlign: getPositionLinkFooter(),
          fontSize: `${setting.fontSize}` || '0.8rem',
          // maxWidth: '200px',
          wordBreak: 'break-word'
        },
        '& .footer-item a': {
          cursor: 'pointer'
        },
        '& .footer-item a:hover': {
          color: setting.color || '#007bff',
          textDecoration: 'underline!important'
        }
      },
      small: {
        fontSize: `${setting.fontSize}px !important` || '0.8rem',
        wordBreak: 'break-all'
      },
      logoAndCoperight: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column'
      },
      logoBox: {
        display: 'flex',
        alignItems: 'center'
      },
      logo: {
        width: 'auto',
        height: '100%',
        minHeight: below768 ? 'unset' : '60px',
        maxWidth: below768 ? '70px' : 'unset',
        maxHeight: '70px',
        objectFit: 'contain'
      },
      containerFooterLayout3: {
        marginLeft: `${!below768 ? pageMargin.left : '0'}px`,
        marginRight: `${!below768 ? pageMargin.right : '0'}px`
        // padding: '0 1.3rem'
      }
    }
  ));

  const classes = useStyle();

  return (
    <footer className={`footer mt-auto py-3 flex-shrink-0 ${classes.setting}`}>
      <div className={isLayout3 ? classes.containerFooterLayout3 : ''}>
        <Grid container>
          {/* Logo */}
          {
            pageLogo && pageLogo !== '' && pageLogo !== 'null' &&
            <Grid item xs={4} sm={3} md={2} className={`${classes.logoAndCoperight}`}>
              <Box className={classes.logoBox}>
                <Link
                  to={'/'}>
                  <img className={`${classes.logo}`} width="40px" src={`${urlUpload}${IMAGE_PATH.LOGO}${pageLogo}`} />
                </Link>
              </Box>
              <small className={classes.small}> {setting.copyRight} </small>
            </Grid>
          }
          {/* Link */}
          <Grid item xs={8} sm={9} md={10} style={{ padding: '0 10px' }}>
            <div className="row">
              <div className={`col-sm-12 col-md-12 ${classes.links}`}>
                {
                  upperLinks && upperLinks.map((item, index) => (
                    item.typeLink === LINK_TYPE.EXTERNAL_LINK
                      ? <div key={`upper-external-link-${index}`} className="footer-item" style={{ width: `${100 / middleLinks.length}%` }}>
                        <a onClick={() => window.open(item.url)}>
                          {item.label}
                        </a>
                      </div>
                      : <div key={`upper-internal-link-${index}`} className="footer-item" style={{ width: `${100 / middleLinks.length}%` }}>
                        <Link to={`${item.url}`}>
                          {item.label}
                        </Link>
                      </div>
                  ))
                }
              </div>
              <div className={`col-sm-12 col-md-12 ${classes.links}`}>
                {
                  middleLinks && middleLinks.map((item, index) => (
                    item.typeLink === LINK_TYPE.EXTERNAL_LINK
                      ? <div key={`middle-external-link-${index}`} className="footer-item" style={{ width: `${100 / middleLinks.length}%` }}>
                        <a onClick={() => window.open(item.url)}>
                          {item.label}
                        </a>
                      </div>
                      : <div key={`middle-internal-link-${index}`} className="footer-item" style={{ width: `${100 / middleLinks.length}%` }}>
                        <Link to={`${item.url}`}>
                          {item.label}
                        </Link>
                      </div>
                  ))
                }
              </div>
              <div className={`col-sm-12 col-md-12 ${classes.links}`}>
                {
                  lowerLinks && lowerLinks.map((item, index) => (
                    item.typeLink === LINK_TYPE.EXTERNAL_LINK
                      ? <div key={`lower-external-link-${index}`} className="footer-item" style={{ width: `${100 / lowerLinks.length}%` }}>
                        <a onClick={() => window.open(item.url)}>
                          {item.label}
                        </a>
                      </div>
                      : <div key={`lower-internal-link-${index}`} className="footer-item" style={{ width: `${100 / lowerLinks.length}%` }}>
                        <Link to={`${item.url}`}>
                          {item.label}
                        </Link>
                      </div>
                  ))
                }
              </div>
              {
                (!pageLogo || pageLogo === '' || pageLogo === 'null') &&
                <div className="col-sm-12 col-md-12 text-center" >
                  <small className={classes.small}> {setting.copyRight} </small>
                </div>
              }
            </div>
          </Grid>
        </Grid>
        {/* <div className="row">
          {
            pageLogo && pageLogo !== '' && pageLogo !== 'null' &&
            <div className={`col-4 col-sm-3 col-md-2 ${classes.logoAndCoperight}`}>
              <Box className={classes.logoBox}>
                <Link
                  to={'/'}>
                  <img className={`${classes.logo}`} width="40px" src={`${urlUpload}${IMAGE_PATH.LOGO}${pageLogo}`} />
                </Link>
              </Box>
              <small className={classes.small}> {setting.copyRight} </small>
            </div>
          }
          <div className={'col-8 col-sm-9 col-md-10'}>
            <div className="row">
              <div className={`col-sm-12 col-md-12 ${classes.links}`}>
                {
                  upperLinks && upperLinks.map((item, index) => (
                    item.typeLink === LINK_TYPE.EXTERNAL_LINK
                      ? <div key={`upper-external-link-${index}`} className="footer-item" style={{ width: `${100 / middleLinks.length}%` }}>
                        <a onClick={() => window.open(item.url)}>
                          {item.label}
                        </a>
                      </div>
                      : <div key={`upper-internal-link-${index}`} className="footer-item" style={{ width: `${100 / middleLinks.length}%` }}>
                        <Link to={`${item.url}`}>
                          {item.label}
                        </Link>
                      </div>
                  ))
                }
              </div>
              <div className={`col-sm-12 col-md-12 ${classes.links}`}>
                {
                  middleLinks && middleLinks.map((item, index) => (
                    item.typeLink === LINK_TYPE.EXTERNAL_LINK
                      ? <div key={`middle-external-link-${index}`} className="footer-item" style={{ width: `${100 / middleLinks.length}%` }}>
                        <a onClick={() => window.open(item.url)}>
                          {item.label}
                        </a>
                      </div>
                      : <div key={`middle-internal-link-${index}`} className="footer-item" style={{ width: `${100 / middleLinks.length}%` }}>
                        <Link to={`${item.url}`}>
                          {item.label}
                        </Link>
                      </div>
                  ))
                }
              </div>
              <div className={`col-sm-12 col-md-12 ${classes.links}`}>
                {
                  lowerLinks && lowerLinks.map((item, index) => (
                    item.typeLink === LINK_TYPE.EXTERNAL_LINK
                      ? <div key={`lower-external-link-${index}`} className="footer-item" style={{ width: `${100 / lowerLinks.length}%` }}>
                        <a onClick={() => window.open(item.url)}>
                          {item.label}
                        </a>
                      </div>
                      : <div key={`lower-internal-link-${index}`} className="footer-item" style={{ width: `${100 / lowerLinks.length}%` }}>
                        <Link to={`${item.url}`}>
                          {item.label}
                        </Link>
                      </div>
                  ))
                }
              </div>
              {
                (!pageLogo || pageLogo === '' || pageLogo === 'null') &&
                <div className="col-sm-12 col-md-12 text-center" >
                  <small className={classes.small}> {setting.copyRight} </small>
                </div>
              }
            </div>
          </div>
        </div> */}
      </div>
      <ScrollTopButton />
    </footer>
  );
};

PublicFooter.propTypes = {
  companyActions: PropTypes.object.isRequired,
  pageLogo: PropTypes.any,
  companyDetail: PropTypes.object,
  layoutViewPage: PropTypes.any,
  pageMargin: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    companyDetail: getEndUserCompanyDetail(state),
    layoutViewPage: getLayoutViewPage(state),
    pageLogo: getPageLogo(state),
    pageMargin: getPageMargin(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ companyActions }) => (
        <PublicFooter
          {...props}
          companyActions={companyActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
