const _prefix = '@account/';

export default {
  GET_ACCOUNT_SETTING_REQUEST: _prefix + 'GET_ACCOUNT_SETTING_REQUEST',
  GET_ACCOUNT_SETTING_SUCCESS: _prefix + 'GET_ACCOUNT_SETTING_SUCCESS',
  GET_ACCOUNT_SETTING_ERROR: _prefix + 'GET_ACCOUNT_SETTING_ERROR',

  UPDATE_ACCOUNT_SETTING_REQUEST: _prefix + 'UPDATE_ACCOUNT_SETTING_REQUEST',
  UPDATE_ACCOUNT_SETTING_SUCCESS: _prefix + 'UPDATE_ACCOUNT_SETTING_SUCCESS',
  UPDATE_ACCOUNT_SETTING_ERROR: _prefix + 'UPDATE_ACCOUNT_SETTING_ERROR',

  CHANGE_PASSWORD_REQUEST: _prefix + 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: _prefix + 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: _prefix + 'CHANGE_PASSWORD_ERROR',

  RESET_USER_PASSWORD_REQUEST: _prefix + 'RESET_USER_PASSWORD_REQUEST',
  RESET_USER_PASSWORD_SUCCESS: _prefix + 'RESET_USER_PASSWORD_SUCCESS',
  RESET_USER_PASSWORD_ERROR: _prefix + 'RESET_USER_PASSWORD_ERROR',

  FORGOT_PASSWORD_REQUEST: _prefix + 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: _prefix + 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: _prefix + 'FORGOT_PASSWORD_ERROR',

  CHANGE_USER_EMAIL_REQUEST: _prefix + 'CHANGE_USER_EMAIL_REQUEST',
  CHANGE_USER_EMAIL_SUCCESS: _prefix + 'CHANGE_USER_EMAIL_SUCCESS',
  CHANGE_USER_EMAIL_ERROR: _prefix + 'CHANGE_USER_EMAIL_ERROR',

  UPDATE_USER_NOTI_PREFER_REQUEST: _prefix + 'UPDATE_USER_NOTI_PREFER_REQUEST',
  UPDATE_USER_NOTI_PREFER_SUCCESS: _prefix + 'UPDATE_USER_NOTI_PREFER_SUCCESS',
  UPDATE_USER_NOTI_PREFER_ERROR: _prefix + 'UPDATE_USER_NOTI_PREFER_ERROR',

  CANCEL_ACCOUNT_USER_REQUEST: _prefix + 'CANCEL_ACCOUNT_USER_REQUEST',
  CANCEL_ACCOUNT_USER_SUCCESS: _prefix + 'CANCEL_ACCOUNT_USER_SUCCESS',
  CANCEL_ACCOUNT_USER_ERROR: _prefix + 'CANCEL_ACCOUNT_USER_ERROR'

};
