
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getAccountType, getLoginSetting, getPageFavicon, getPageLogo, getPageTitle } from 'state/search/searchSelector';
import DetailContentPage from './DetailContentPage';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { useMedia } from 'react-use';
import { IMAGE_PATH, OPTION_LOGIN, SETTING_LOGIN, STORAGE_KEYS } from 'configs/AppConfig';
// import notFoundImage from '../../assets/img/not-found.png';
import { getViewSettings } from 'state/company/companySelector';
import { useHistory } from 'react-router-dom';
import StorageHelper from 'utils/StorageHelper';

const PublicDetailContent = (props) => {
  const {
    pageTitle,
    pageLogo,
    pageFavicon
  } = props;

  const servicePath = StorageHelper.getSessionItem(STORAGE_KEYS.path);
  const currentDomain = StorageHelper.getSessionItem(STORAGE_KEYS.domain);
  const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;
  const isAuthen = StorageHelper.getLocalObject(userStore);
  const isToken = StorageHelper.getCookie(userStore);
  const accountType = useSelector(getAccountType);
  const loginSetting = useSelector(getLoginSetting);
  const isRequireLogin = loginSetting === SETTING_LOGIN.LOGINREQUIRE.value;

  const history = useHistory();

  const style = useSelector((state) => state.search.headerSetting) || {};
  const below768 = useMedia('(max-width: 768px)');
  const useStyle = makeStyles(() => (
    {
      header: {
        display: 'flex',
        backgroundColor: `${style.backgroundColor}!important`,
        color: style.color || '#d0d0d0',
        padding: below768 ? '6px 10px' : '30px 1.3rem',
        height: below768 ? '80px' : 'unset',
        width: '100%',
        '& .dropdown-toggle:after': {
          display: 'none'
        },
        '& .nav-link': {
          color: `${style.color || '#d0d0d0'}!important`,
          whiteSpace: 'unset',
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        }
      },
      headerItem: {
        display: 'flex',
        flexFlow: 'column nowrap',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        padding: '0 0.5rem',
        color: style.color || '#d0d0d0',
        cursor: 'pointer'
      },
      logo: {
        width: below768 ? 'auto' : '100%',
        height: '100%',
        minHeight: below768 ? 'unset' : '60px',
        maxWidth: below768 ? '70px' : 'unset',
        maxHeight: '70px',
        objectFit: 'contain'
      },
      logoBox: {
        display: 'flex',
        alignItems: 'center'
      },
      pageTitle: {
        margin: '0 10px!important',
        padding: 0,
        color: `${style.color || '#fff'}!important`,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        maxWidth: below768 ? 'calc(100vw - 170px)' : 'calc(100vw - 470px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1.25rem',
        '&:hover': {
          textDecoration: 'none'
        }
      }
    }
  ));

  const classes = useStyle();
  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';
  const [isLoggedInContentDetail, setIsLoggedInContentDetail] = useState(false);

  useEffect(() => {
    if (!isAuthen && !isToken && accountType !== OPTION_LOGIN.OPEN_TYPE) {
      if (isRequireLogin) {
        StorageHelper.setSessionItem(STORAGE_KEYS.urlContentCallback, `${window.location.href}`);
        history.push({ pathname: '/login' });
      }
      if (accountType === OPTION_LOGIN.GAKKEN_ID) {
        StorageHelper.setSessionItem(STORAGE_KEYS.urlContentCallback, `${window.location.href}`);
      }
    }
    if (accountType !== OPTION_LOGIN.OPEN_TYPE) {
      if (isAuthen && isToken && (isRequireLogin || accountType === OPTION_LOGIN.GAKKEN_ID)) {
        setIsLoggedInContentDetail(true);
      } else {
        setIsLoggedInContentDetail(false);
      }
    }
  }, [isAuthen, isToken, accountType, loginSetting]);

  useEffect(() => {
    // Set Favicon
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
      favicon.type = 'image/png';
    }
  }, [pageFavicon]);

  return <React.Fragment>
    <Box className={classes.header}>
      <Box className={classes.logoBox}>
        <Box>
          {pageLogo && pageLogo !== '' && pageLogo !== 'null'
            ? (
              <img className={`${classes.logo}`} width="40px" src={`${urlUpload}${IMAGE_PATH.LOGO}${pageLogo}`} />
            )
            : (
              <></>
              // <img className={`${classes.logo}`} width="40px" src={notFoundImage} />
            )
          }
        </Box>
        {
          pageTitle && <span className={classes.pageTitle}>{pageTitle}</span>
        }
      </Box>
    </Box>
    <DetailContentPage
      showFavorite={false}
      isLoggedContentDirect={isLoggedInContentDetail}
    />
  </React.Fragment>;
};

PublicDetailContent.propTypes = {
  pageTitle: PropTypes.any,
  pageLogo: PropTypes.any,
  viewSetting: PropTypes.any,
  pageFavicon: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    pageTitle: getPageTitle(state),
    pageLogo: getPageLogo(state),
    viewSetting: getViewSettings(state),
    pageFavicon: getPageFavicon(state)
  };
};

export default connect(mapStateToProps)(props => (
  <PublicDetailContent
    {...props}
  />
));
