const _prefix = '@subscription/';

export default {
  GET_SUBSCRIPTION_PAGE_REQUEST: _prefix + 'GET_SUBSCRIPTION_PAGE_REQUEST',
  GET_SUBSCRIPTION_PAGE_SUCCESS: _prefix + 'GET_SUBSCRIPTION_PAGE_SUCCESS',
  GET_SUBSCRIPTION_PAGE_ERROR: _prefix + 'GET_SUBSCRIPTION_PAGE_ERROR',

  ADD_SUBSCRIPTION_REQUEST: _prefix + 'ADD_SUBSCRIPTION_REQUEST',
  SAVE_SUBSCRIPTION_SUCCESS: _prefix + 'SAVE_SUBSCRIPTION_SUCCESS',
  SAVE_SUBSCRIPTION_ERROR: _prefix + 'SAVE_SUBSCRIPTION_ERROR',
  OPEN_SUBSCRIPTION_POPUP: _prefix + 'OPEN_SUBSCRIPTION_POPUP',
  CLOSE_SUBSCRIPTION_POPUP: _prefix + 'CLOSE_SUBSCRIPTION_POPUP',

  GET_SUBSCRIPTION_DETAIL_REQUEST: _prefix + 'GET_SUBSCRIPTION_DETAIL_REQUEST',
  GET_SUBSCRIPTION_DETAIL_SUCCESS: _prefix + 'GET_SUBSCRIPTION_DETAIL_SUCCESS',
  GET_SUBSCRIPTION_DETAIL_ERROR: _prefix + 'GET_SUBSCRIPTION_DETAIL_ERROR',

  GET_SUBSCRIPTION_LIST_REQUEST: _prefix + 'GET_SUBSCRIPTION_ALL_REQUEST',
  GET_SUBSCRIPTION_LIST_SUCCESS: _prefix + 'GET_SUBSCRIPTION_ALL_SUCCESS',
  GET_SUBSCRIPTION_LIST_ERROR: _prefix + 'GET_SUBSCRIPTION_ALL_ERROR',

  GET_USER_REGISTER_SUBSCRIPTION_PAGE_REQUEST: _prefix + 'GET_USER_REGISTER_SUBSCRIPTION_PAGE_REQUEST',
  GET_USER_REGISTER_SUBSCRIPTION_PAGE_SUCCESS: _prefix + 'GET_USER_REGISTER_SUBSCRIPTION_PAGE_SUCCESS',
  GET_USER_REGISTER_SUBSCRIPTION_PAGE_ERROR: _prefix + 'GET_USER_REGISTER_SUBSCRIPTION_PAGE_ERROR',

  GET_USER_REGISTER_SUBSCRIPTION_DETAIL_REQUEST: _prefix + 'GET_USER_REGISTER_SUBSCRIPTION_DETAIL_REQUEST',
  GET_USER_REGISTER_SUBSCRIPTION_DETAIL_SUCCESS: _prefix + 'GET_USER_REGISTER_SUBSCRIPTION_DETAIL_SUCCESS',
  GET_USER_REGISTER_SUBSCRIPTION_DETAIL_ERROR: _prefix + 'GET_USER_REGISTER_SUBSCRIPTION_DETAIL_ERROR',

  CANCEL_USER_REGISTER_SUBSCRIPTION_REQUEST: _prefix + 'CANCEL_USER_REGISTER_SUBSCRIPTION_REQUEST',
  CANCEL_USER_REGISTER_SUBSCRIPTION_SUCCESS: _prefix + 'CANCEL_USER_REGISTER_SUBSCRIPTION_SUCCESS',
  CANCEL_USER_REGISTER_SUBSCRIPTION_ERROR: _prefix + 'CANCEL_USER_REGISTER_SUBSCRIPTION_ERROR',

  REGISTER_USER_SUBSCRIPTION_REQUEST: _prefix + 'REGISTER_USER_SUBSCRIPTION_REQUEST',
  REGISTER_USER_SUBSCRIPTION_SUCCESS: _prefix + 'REGISTER_USER_SUBSCRIPTION_SUCCESS',
  REGISTER_USER_SUBSCRIPTION_ERROR: _prefix + 'REGISTER_USER_SUBSCRIPTION_ERROR'
};
