import ContentTypes from './ContentTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  viewerToken: '',
  contentData: [],
  seriesContent: [],
  selected: [],
  pagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0
  },
  paginationSeriesContent: {},
  isContentDetailLoad: true,
  isSeriesContentLoad: true,
  contentDetail: {
    author: '',
    title: '',
    contentGroupIds: [],
    beContentsId: '',
    toc: '',
    keywords: '',
    param1: '',
    param2: '',
    param3: '',
    param4: '',
    param5: '',
    description: '',
    distributorName: '',
    fileName: '',
    view: false,
    print: false,
    printableContent: 0
  },
  uploadContents: [],
  haveContentDetail: true,
  enablePrint: 0,
  isErrorViewer: false,
  contentType: 'pdf',
  watermark: {},
  downloadContentObj: null,
  isDownloadContentSuccess: false,
  isLoading: false
};

const dataReducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  let existedFile;
  let newUploadContents;
  switch (type) {
    case ContentTypes.GET_VIEWER_TOKEN_SUCCESS:
      newState.viewerToken = payload.tokenId;
      newState.enablePrint = payload.contentInfo.printableContent || false;
      newState.contentType = payload.contentInfo.extensionType || 'pdf';
      newState.watermark = payload.watermark;
      return newState;
    case ContentTypes.GET_VIEWER_TOKEN_ERROR:
      newState.isErrorViewer = true;
      return newState;
    case ContentTypes.GET_VIEWER_TOKEN:
      newState.isErrorViewer = false;
      return newState;
    case ContentTypes.GET_VIEWER_PUBLIC_TOKEN:
      newState.isErrorViewer = false;
      return newState;
    case ContentTypes.GET_CONTENT_DATA_SUCCESS:
      newState.contentData = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.size;
      newState.pagination.page = payload.number + 1;
      newState.pagination = { ...newState.pagination };
      return newState;
    case ContentTypes.GET_SERIES_CONTENT_REQUEST:
      newState.isSeriesContentLoad = true;
      return newState;
    case ContentTypes.GET_SERIES_CONTENT_SUCCESS:
      var pageNumber = payload.number + 1;
      if (pageNumber === 1) {
        newState.seriesContent = [...payload.content];
        newState.paginationSeriesContent.totalRecords = payload.totalElements;
        newState.paginationSeriesContent.totalPages = payload.totalPages;
        newState.paginationSeriesContent.size = payload.size;
        newState.paginationSeriesContent.page = pageNumber;
        newState.paginationSeriesContent = { ...newState.paginationSeriesContent };
      } else if ((!newState.paginationSeriesContent.page || newState.paginationSeriesContent.page < pageNumber) && (payload.size * pageNumber > newState.seriesContent.length)) {
        newState.seriesContent = [...newState.seriesContent, ...payload.content];
        newState.paginationSeriesContent.totalRecords = payload.totalElements;
        newState.paginationSeriesContent.totalPages = payload.totalPages;
        newState.paginationSeriesContent.size = payload.size;
        newState.paginationSeriesContent.page = pageNumber;
        newState.paginationSeriesContent = { ...newState.paginationSeriesContent };
      }
      newState.isSeriesContentLoad = false;
      return newState;
    case ContentTypes.GET_SERIES_CONTENT_ERROR:
      newState.isSeriesContentLoad = false;
      return newState;
    case ContentTypes.GET_CONTENT_DETAIL_REQUEST:
      newState.haveContentDetail = true;
      newState.isContentDetailLoad = true;
      return newState;
    case ContentTypes.GET_CONTENT_DETAIL_SUCCESS:
      for (const key in payload) {
        if (!payload[key] && payload[key] !== 0) {
          payload[key] = '';
        }
      }
      newState.haveContentDetail = true;
      newState.contentDetail = { ...payload };
      newState.isContentDetailLoad = false;
      return newState;
    case ContentTypes.GET_CONTENT_DETAIL_ERROR:
      newState.haveContentDetail = false;
      return newState;
    case ContentTypes.SET_UPLOAD_PROGRESS:
      existedFile = newState.uploadContents.filter(file => file.id === payload.id)[0];
      if (existedFile) {
        existedFile.progress = payload.progress;
        newState.uploadContents = [...newState.uploadContents];
      } else {
        newState.uploadContents = [...newState.uploadContents, payload];
      }
      return newState;
    case ContentTypes.CLOSE_UPLOAD_PROGRESS:
      newUploadContents = newState.uploadContents.filter(file => file.progress !== 100 && !file.isError);
      newState.uploadContents = newUploadContents;
      return newState;
    case ContentTypes.FAILURE_UPLOAD_CONTENT:
      existedFile = newState.uploadContents.filter(file => file.id === payload.id)[0];
      existedFile.isError = true;
      newState.uploadContents = [...newState.uploadContents];
      return newState;
    case ContentTypes.GET_DOWNLOAD_CONTENT_ID_REQUEST:
      // newState.gettingDownloadContentId = true;
      newState.isLoading = true;
      return newState;
    case ContentTypes.GET_DOWNLOAD_CONTENT_ID_SUCCESS:
      // newState.gettingDownloadContentId = true;
      newState.downloadContentObj = payload;
      newState.isLoading = true;
      return newState;
    case ContentTypes.RESET_LOADING_DOWNLOAD_CONTENT:
      newState.isLoading = false;
      return newState;
    case ContentTypes.RESET_DOWNLOAD_CONTENT_ID:
    case ContentTypes.GET_DOWNLOAD_CONTENT_ID_ERROR:
      // newState.downloadContentObj = null;
      newState.isLoading = false;
      newState.isDownloadContentSuccess = false;
      newState.downloadContentObj = null;
      return newState;
    default:
      newState = state;
      break;
  }

  // return new state
  return newState;
};

export default dataReducer;
