import { all, takeEvery, put, select } from 'redux-saga/effects';
import CompanyTypes from './CompanyTypes';
import NotificationTypes from 'state/notification/NotificationTypes';
import createRequest from 'api/httpRequest';
import API from 'api/company';
import Util from 'utils/Util';
import snakecaseKeys from 'snakecase-keys';
import { changeRoute } from 'state/route/RouteActions';
import { getCompanyDomain, getServicePath } from './companySelector';
import SearchTypes from 'state/search/SearchTypes';

function * loadEndUserCompanyDetail () {
  yield takeEvery(CompanyTypes.GET_END_USER_COMPANY_DETAIL_REQUEST, _loadEndUserCompanyDetail);
}

function * _loadEndUserCompanyDetail ({ payload }) {
  const domain = yield select(getCompanyDomain);
  yield createRequest({
    api: {
      ...API.GET_END_USER_COMPANY_DETAIL,
      params: {
        domain: domain
      }
    },
    onSuccess: function * ({ data }) {
      yield put({
        type: CompanyTypes.GET_END_USER_COMPANY_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data)
      });
    },
    onError: function * ({ status }) {
      if (status === 201 && !window.location.origin.includes('admin')) {
        yield put(changeRoute('/'));
      }
    }
  });
}

function * getSetting () {
  yield takeEvery(CompanyTypes.GET_SETTING_REQUEST, _getSetting);
}

function * _getSetting ({ payload = {} }) {
  const { path } = payload;
  const domain = yield select(getCompanyDomain);
  const request = snakecaseKeys({ domain: domain, path: path });
  yield put({
    type: SearchTypes.GET_SETTING_REQUEST,
    payload: {}
  });
  yield createRequest({
    api: {
      ...API.GET_SETTING,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: CompanyTypes.GET_SETTING_SUCCESS,
        payload: Util.toCamelCaseKey({ ...response.data, path })
      });
      yield put({
        type: SearchTypes.GET_SETTING_SUCCESS,
        payload: Util.toCamelCaseKey({ ...response.data, path })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.GET_SETTING_ERROR,
        payload: {}
      });
      if (path === 'gakken-payment' && window.location.pathname === '/gakken-payment/error') {
        yield put(changeRoute('/gakken-payment/error'));
      } else {
        yield put(changeRoute('/404'));
      }
    }
  });
}

function * verifyPaymentVeritrans () {
  yield takeEvery(CompanyTypes.VERIFY_PAYMENT_VERITRANS_REQUEST, _verifyPaymentVeritrans);
}

function * _verifyPaymentVeritrans ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  yield createRequest({
    api: {
      ...API.VERIFY_PAYMENT_VERITRANS,
      params: request
    },
    onSuccess: function * ({ status }) {
      yield put({
        type: CompanyTypes.VERIFY_PAYMENT_VERITRANS_SUCCESS
      });
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.verify.veritrans.${status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CompanyTypes.VERIFY_PAYMENT_VERITRANS_ERROR
      });
    }
  });
}

function * updatePaymentVeritrans () {
  yield takeEvery(CompanyTypes.UPDATE_PAYMENT_VERITRANS_REQUEST, _updatePaymentVeritrans);
}

function * _updatePaymentVeritrans ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  yield createRequest({
    api: {
      ...API.UPDATE_PAYMENT_VERITRANS,
      data: request
    },
    onSuccess: function * ({ status }) {
      yield put({
        type: CompanyTypes.UPDATE_PAYMENT_VERITRANS_SUCCESS
      });
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CompanyTypes.UPDATE_PAYMENT_VERITRANS_ERROR
      });
    }
  });
}

function * loadGakkenTerms () {
  yield takeEvery(CompanyTypes.GET_GAKKEN_TERMS_REQUEST, _loadGakkenTerms);
}

function * _loadGakkenTerms () {
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);

  yield createRequest({
    api: {
      ...API.GET_GAKKEN_TERMS,
      params: {
        domain: domain,
        path: servicePath
      }
    },
    onSuccess: function * ({ data }) {
      yield put({
        type: CompanyTypes.GET_GAKKEN_TERMS_SUCCESS,
        payload: Util.toCamelCaseKey(data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CompanyTypes.GET_GAKKEN_TERMS_ERROR
      });
    }
  });
}

export default function * companySaga () {
  yield all([
    loadEndUserCompanyDetail(),
    getSetting(),
    verifyPaymentVeritrans(),
    updatePaymentVeritrans(),
    loadGakkenTerms()
  ]);
}
