const _prefix = '@coupon/';

export default {
  GET_COUPON_PAGE_REQUEST: _prefix + 'GET_COUPON_PAGE_REQUEST',
  GET_COUPON_PAGE_SUCCESS: _prefix + 'GET_COUPON_PAGE_SUCCESS',
  GET_COUPON_PAGE_ERROR: _prefix + 'GET_COUPON_PAGE_ERROR',

  ADD_COUPON_REQUEST: _prefix + 'ADD_COUPON_REQUEST',

  GET_COUPON_DETAIL_REQUEST: _prefix + 'GET_COUPON_DETAIL_REQUEST',
  GET_COUPON_DETAIL_SUCCESS: _prefix + 'GET_COUPON_DETAIL_SUCCESS',
  GET_COUPON_DETAIL_ERROR: _prefix + 'GET_COUPON_DETAIL_ERROR',

  GET_COUPON_LIST_REQUEST: _prefix + 'GET_COUPON_ALL_REQUEST',
  GET_COUPON_LIST_SUCCESS: _prefix + 'GET_COUPON_ALL_SUCCESS',
  GET_COUPON_LIST_ERROR: _prefix + 'GET_COUPON_ALL_ERROR',

  GET_COUPON_USER_REQUEST: _prefix + 'GET_COUPON_USER_REQUEST',
  GET_COUPON_USER_SUCCESS: _prefix + 'GET_COUPON_USER_SUCCESS',
  GET_COUPON_USER_ERROR: _prefix + 'GET_COUPON_USER_ERROR',

  SET_COUPON_USER_NULL: _prefix + 'SET_COUPON_USER_NULL'
};
