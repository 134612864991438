import { all, put, takeEvery, select } from 'redux-saga/effects';
import SubscriptionTypes from './SubscriptionTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/subscription';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { PAGINATION } from 'configs/AppConfig';
import HomeTypes from '../home/HomeTypes';
import { changeRoute } from 'state/route/RouteActions';
import { getCompanyDomain, getServicePath } from 'state/company/companySelector';

function * getSubscriptionPage () {
  yield takeEvery(SubscriptionTypes.GET_SUBSCRIPTION_PAGE_REQUEST, _getSubscriptionPage);
}

function * _getSubscriptionPage ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_SUBSCRIPTION_PAGE,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_PAGE_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_PAGE_ERROR,
        payload: status
      });
    }
  });
}

function * getSubscriptionList () {
  yield takeEvery(SubscriptionTypes.GET_SUBSCRIPTION_LIST_REQUEST, _getSubscriptionList);
}

function * _getSubscriptionList ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_SUBSCRIPTION_LIST,
      params: snakecaseKeys({ ...payload })
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_LIST_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_LIST_ERROR,
        payload: status
      });
    }
  });
}

function * getSubscriptionDetail () {
  yield takeEvery(SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_REQUEST, _getSubscriptionDetail);
}

function * _getSubscriptionDetail ({ payload }) {
  const servicePath = yield select(getServicePath);
  const currentDomain = yield select(getCompanyDomain);
  const api = {
    ...API.GET_SUBSCRIPTION_DETAIL,
    url: API.GET_SUBSCRIPTION_DETAIL.url + payload,
    params: {
      path: servicePath,
      domain: currentDomain
    }
  };
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    },
    onError: function * ({ status }) {
      const servicePath = yield select(getServicePath);
      const loginPath = !servicePath || servicePath === '' ? '/login' : `/${servicePath}/login`;
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_ERROR,
        payload: status
      });
      yield put(changeRoute(loginPath, ''));
    }
  });
}

function * addSubscription () {
  yield takeEvery(SubscriptionTypes.ADD_SUBSCRIPTION_REQUEST, _addSubscription);
}

function * _addSubscription ({ payload }) {
  const request = snakecaseKeys({ ...payload });

  yield createRequest({
    api: {
      ...API.ADD_SUBSCRIPTION,
      data: request.data
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.SAVE_SUBSCRIPTION_SUCCESS
      });
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SubscriptionTypes.GET_SUBSCRIPTION_PAGE_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    },
    onError: function * (response) {
      yield put({
        type: SubscriptionTypes.SAVE_SUBSCRIPTION_ERROR
      });
    }
  });
}

function * getUserRegisterSubscriptionPage () {
  yield takeEvery(SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_PAGE_REQUEST, _getUserRegisterSubscriptionPage);
}

function * _getUserRegisterSubscriptionPage ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_USER_REGISTER_SUBSCRIPTION_PAGE,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_PAGE_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_PAGE_ERROR,
        payload: status
      });
    }
  });
}

function * getUserRegisterSubscriptionDetail () {
  yield takeEvery(SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_DETAIL_REQUEST, _getUserRegisterSubscriptionDetail);
}

function * _getUserRegisterSubscriptionDetail ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_USER_REGISTER_SUBSCRIPTION_DETAIL,
      url: API.GET_USER_REGISTER_SUBSCRIPTION_DETAIL.url + payload
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_DETAIL_ERROR,
        payload: status
      });
    }
  });
}

function * registerUserSubscription () {
  yield takeEvery(SubscriptionTypes.REGISTER_USER_SUBSCRIPTION_REQUEST, _registerUserSubscription);
}

function * _registerUserSubscription ({ payload }) {
  const request = snakecaseKeys({ ...payload.data });
  yield createRequest({
    api: {
      ...API.REGISTER_USER_SUBSCRIPTION,
      data: request,
      url: API.REGISTER_USER_SUBSCRIPTION.url
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.register.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      if (payload.data.updateContentGroup) {
        yield put({
          type: HomeTypes.GET_PUBLIC_CONTENT_GROUP_REQUEST
        });
      } else {
        yield put({
          type: SubscriptionTypes.GET_SUBSCRIPTION_LIST_REQUEST
        });
      }
    }

  });
}

function * cancelUserSubscription () {
  yield takeEvery(SubscriptionTypes.CANCEL_USER_REGISTER_SUBSCRIPTION_REQUEST, _cancelUserSubscription);
}

function * _cancelUserSubscription ({ payload }) {
  const { data, queries } = payload;
  const { id } = data;
  yield createRequest({
    api: {
      ...API.CANCEL_USER_REGISTER_SUBSCRIPTION,
      url: `${API.CANCEL_USER_REGISTER_SUBSCRIPTION.url}${id}`
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.cancel.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SubscriptionTypes.CANCEL_USER_REGISTER_SUBSCRIPTION_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
      yield put({
        type: SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_PAGE_REQUEST,
        payload: { ...queries }
      });
    }

  });
}

export default function * subscriptionSaga () {
  yield all([
    getSubscriptionPage(),
    getSubscriptionList(),
    getSubscriptionDetail(),
    addSubscription(),
    getUserRegisterSubscriptionPage(),
    getUserRegisterSubscriptionDetail(),
    cancelUserSubscription(),
    registerUserSubscription()
  ]);
}
