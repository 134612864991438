import SortTypes from './SortTypes';

const initialState = {
  allSorts: [],
  sortDetail: {}
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  const newState = { ...state };
  switch (type) {
    case SortTypes.GET_SORT_SUCCESS:
      newState.sortDetail = { ...payload };
      return newState;
    case SortTypes.GET_ALL_SORT_SUCCESS:
      newState.allSorts = [...payload];
      return newState;
  }
  return newState;
}
