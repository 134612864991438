const _prefix = '@company/';

export default {
  GET_SETTING_REQUEST: _prefix + 'GET_SETTING_REQUEST',
  GET_SETTING_SUCCESS: _prefix + 'GET_SETTING_SUCCESS',
  GET_SETTING_ERROR: _prefix + 'GET_SETTING_ERROR',

  UPDATE_COMPANIES_QUERIES: _prefix + 'UPDATE_COMPANIES_QUERIES',

  DELETE_COMPANY_REQUEST: _prefix + 'DELETE_COMPANY_REQUEST',

  UPDATE_SELECTED: _prefix + 'UPDATE_SELECTED',
  UPDATE_SELECTED_ALL: _prefix + 'UPDATE_SELECTED_ALL',

  SET_COMPANY_DOMAIN: _prefix + 'SET_COMPANY_DOMAIN',
  SET_SERVICE_PATH: _prefix + 'SET_SERVICE_PATH',

  GET_END_USER_COMPANY_DETAIL_REQUEST: _prefix + 'GET_END_USER_COMPANY_DETAIL_REQUEST',
  GET_END_USER_COMPANY_DETAIL_SUCCESS: _prefix + 'GET_END_USER_COMPANY_DETAIL_SUCCESS',

  VERIFY_PAYMENT_VERITRANS_REQUEST: _prefix + 'VERIFY_PAYMENT_VERITRANS_REQUEST',
  VERIFY_PAYMENT_VERITRANS_SUCCESS: _prefix + 'VERIFY_PAYMENT_VERITRANS_SUCCESS',
  VERIFY_PAYMENT_VERITRANS_ERROR: _prefix + 'VERIFY_PAYMENT_VERITRANS_ERROR',

  UPDATE_PAYMENT_VERITRANS_REQUEST: _prefix + 'UPDATE_PAYMENT_VERITRANS_REQUEST',
  UPDATE_PAYMENT_VERITRANS_SUCCESS: _prefix + 'UPDATE_PAYMENT_VERITRANS_SUCCESS',
  UPDATE_PAYMENT_VERITRANS_ERROR: _prefix + 'UPDATE_PAYMENT_VERITRANS_ERROR',

  GET_GAKKEN_TERMS_REQUEST: _prefix + 'GET_GAKKEN_TERMS_REQUEST',
  GET_GAKKEN_TERMS_SUCCESS: _prefix + 'GET_GAKKEN_TERMS_SUCCESS',
  GET_GAKKEN_TERMS_ERROR: _prefix + 'GET_GAKKEN_TERMS_ERROR'
};
