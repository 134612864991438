export default {
  GET_SUBSCRIPTION_PAGE: {
    url: '/subscription-plan/public/user/page',
    method: 'GET'
  },
  GET_SUBSCRIPTION_DETAIL: {
    url: '/subscription-plan/public/',
    method: 'GET'
  },
  ADD_SUBSCRIPTION: {
    url: '/subscription-plan/add',
    method: 'POST'
  },
  UPDATE_SUBSCRIPTION: {
    url: '/subscription-plan/',
    method: 'PUT'
  },
  DELETE_SUBSCRIPTION: {
    url: '/subscription-plan/',
    method: 'DELETE'
  },
  GET_SUBSCRIPTION_LIST: {
    url: '/subscription-plan/public/user/page',
    method: 'GET'
  },
  GET_USER_REGISTER_SUBSCRIPTION_PAGE: {
    url: '/subscription-plan/user/registered/page',
    method: 'GET'
  },
  GET_USER_REGISTER_SUBSCRIPTION_DETAIL: {
    url: '/subscription-plan/user/registered/',
    method: 'GET'
  },

  CANCEL_USER_REGISTER_SUBSCRIPTION: {
    url: '/subscription-plan/user/cancel/registered/',
    method: 'DELETE'
  },

  REGISTER_USER_SUBSCRIPTION: {
    url: '/subscription-plan/user/register',
    method: 'POST'
  }
};
