import axios from 'axios';
import StorageHelper from './StorageHelper';
import { STORAGE_KEYS } from 'configs/AppConfig';
import API from '../api/content';
import snakecaseKeys from 'snakecase-keys';

axios.defaults.baseURL = (process.env.REACT_APP_BACKEND_URL || process.env.REACT_APP_SERVER_URL) + '/api/v1';
axios.defaults.timeout = 300000; // 50s

const servicePath = StorageHelper.getSessionItem(STORAGE_KEYS.path);
const currentDomain = StorageHelper.getSessionItem(STORAGE_KEYS.domain);
const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;

export const uploadContent = (payload, onUploadProgress) => {
  const { params, file } = payload;
  const data = new FormData();
  data.append('file', file);
  data.append('content-data', new Blob([JSON.stringify(snakecaseKeys(params))], {
    type: 'application/json'
  }));

  const api = { ...API.UPLOAD_CONTENT };
  const options = {
    headers: {
      'Content-Type': undefined,
      'X-Access-Token': StorageHelper.getCookie(userStore)
    },
    onUploadProgress: onUploadProgress
  };

  return axios.post(api.url, data, options);
};

export const replaceContent = (payload, onUploadProgress) => {
  const { contentId, file } = payload;
  const data = new FormData();
  data.append('file', file);

  const api = {
    ...API.REPLACE_CONTENT,
    url: API.REPLACE_CONTENT.url + contentId
  };
  const options = {
    headers: {
      'Content-Type': undefined,
      'X-Access-Token': StorageHelper.getCookie(userStore)
    },
    onUploadProgress: onUploadProgress
  };

  return axios.put(api.url, data, options);
};

export const importCSV = (payload) => {
  const { params, file } = payload;
  const data = new FormData();
  data.append('file', file);

  const api = { ...API.IMPORT_CSV };
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Access-Token': StorageHelper.getCookie(userStore)
    },
    params: params
  };

  return axios.post(api.url, data, options);
};
