import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { makeStyles } from '@material-ui/core/styles';
import { getIsContentInfoLoad } from '../../state/search/searchSelector';
import { connect, useSelector } from 'react-redux';
import Util from 'utils/Util';
import { useIntl } from 'react-intl';
import {
  getIsListLoad,
  getSubscriptionList
} from '../../state/subscription/SubscriptionSelector';
import { useHistory } from 'react-router-dom';
import { APP_URL, DURATION_MAP, IMAGE_PATH } from 'configs/AppConfig';
import { useMedia } from 'react-use';
import { Tooltip } from '@material-ui/core';

const Style = {
  itemDetail: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'column'
    // maxHeight: '350px'
  },
  discountPrice: {
    fontSize: '1rem',
    color: '#929292'
  }
};
const SubscriptionModal = props => {
  const {
    isOpen,
    toggle,
    className,
    subscriptionList,
    isListLoad
  } = props;

  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const below768 = useMedia('(max-width: 768px)');
  const style = useSelector((state) => state.search.contentDetailSetting) || {};
  const isLoggedIn = useSelector((state) => state.session.isAuthenticated);
  const useStyle = makeStyles((theme) => (
    {
      itemMax: {
        maxWidth: 300,
        minWidth: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block'
      },
      coverCard: {
        display: 'flex',
        overflowX: 'auto',
        paddingBottom: '10px',
        color: 'black'
      },
      imageSubscription: {
        objectFit: 'contain',
        height: '100%',
        width: '100%'
      },
      itemCard: {
        width: '300px',
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.125)',
        borderRadius: '0.25rem'
      },
      noImage: {
        marginBottom: '0.5rem',
        marginRight: '0.5rem',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #d0d0d0',
        '& img': {
          maxHeight: 260,
          maxWidth: 260,
          objectFit: 'contain',
          minWidth: 150
        }
      },
      favoriteIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: '#F08828',
        margin: '0.8rem 1rem',
        fontSize: '1rem',
        background: '#fff',
        borderRadius: '50%',
        border: '1.5px solid #999999',
        width: '28px',
        height: '28px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)'
        }
      },
      registered: {
        position: 'absolute',
        top: 0,
        margin: '0.8rem 1rem',
        fontSize: '1.5rem',
        height: 28,
        width: 28,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
      },
      bold: {
        fontWeight: 700
      },
      buttonView: {
        color: '#ffffff',
        fontWeight: 700,
        marginTop: '1rem'
      },
      iconButton: {
        marginRight: '0.5rem'
      },
      skeletonItemImage: {
        width: '100%',
        height: '200px',
        marginBottom: '0.5rem'
      },
      spin: {
        display: 'flex',
        justifyContent: 'center'
      },
      skeletonItemButton: {
        width: '115px!important',
        height: '45px'
      },
      contentImage: {
        width: '50%',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #d0d0d0',
        height: below768 ? '160px' : '200px',
        // width: below768 ? '120px' : '150px',
        maxWidth: '100%'
      },
      modalStyle: {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        color: style.color || '',
        fontSize: style.fontSize || '',
        border: style.borderColor ? `1px solid ${style.borderColor}` : '',
        '& .modal-content': {
          borderRadius: 0,
          backgroundColor: `${style.backgroundColor || '#fff'}!important`
        },
        '& .modal-body': {
          padding: '1.5rem 2rem 1rem 2rem'
        },
        '& div': {
          wordBreak: 'break-word',
          wordWrap: 'break-word',
          display: 'flex'
        }
      }
    }
  ));

  const classes = useStyle();

  const returnNumberFreePeriod = _days => {
    return `${f({ id: 'number.day' }, { days: _days })}`;
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} className={`${className} ${classes.modalStyle}`} size="lg" backdrop={'static'}>
      <ModalHeader toggle={toggle}>{f({ id: 'label.purchase_subscription' })}</ModalHeader>
      <ModalBody id='modalBody'>
        {
          isListLoad && <div className={classes.spin} style={{ fontSize: 16, color: 'black' }}>
            <Spinner color='primary' style={{ marginRight: '20px' }} /> <TranslateMessage id='progress.loading'/>
          </div>
        }
        {
          !isListLoad && <div className={classes.coverCard}>
            {
              subscriptionList.length > 0
                ? subscriptionList.map(item => {
                  return (
                    <div key={item.id} className={item.id !== subscriptionList[0].id ? 'ml-3' : undefined} style={{ fontSize: 16, paddingRight: 2 }}>
                      <div className={classes.itemCard} style={{ width: subscriptionList.length > 2 ? '300px' : '350px' }}>
                        <div className="card-header" style={ Style.cardHeader }>
                          <h4 style={{ textAlign: 'center' }} className="text-bold"><Tooltip title={item.name}><span className={classes.wrapText}>{item.name}</span></Tooltip></h4>
                          <div>
                            <div className={`${classes.contentImage}`} >
                              <div style={{ width: '100%', height: '100%' }}>
                                <img
                                  className={classes.imageSubscription}
                                  src={APP_URL.UPLOAD_PATH + '/' + IMAGE_PATH.SUBSCRIPTION + item.imageName}
                                />
                              </div>
                            </div>
                            <div style={{ width: '50%', marginLeft: '10px', height: '100%' }}>
                              <div className="text-left" style={ Style.itemDetail }>
                                <div style={{ flexGrow: 11, width: '100%', display: 'flex', flexDirection: 'column' }}>
                                  {
                                    isLoggedIn ? <h5 className="text-bold">
                                      <span>{`${f({ id: 'label.price' })}: `}</span>
                                      {
                                        (item.discountPrice || item.discountPrice === 0) && item.price !== item.discountPrice
                                          ? <><del style={{ fontSize: '1rem', color: '#929292' }} className="mr-2">¥{Util.formatThousandsNumber(Util.addTax10(item.price))}</del></>
                                          : null
                                      }
                          ¥{Util.formatThousandsNumber(Util.addTax10((item.discountPrice || item.discountPrice === 0) ? item.discountPrice : item.price))}
                                      {` (${f({ id: 'label.inclusiveTax' }, { value: '10%' })})`}
                                    </h5> : <h5 className="text-bold">
                                      <span>{`${f({ id: 'label.price' })}: `}</span>
                          ¥{Util.formatThousandsNumber(Util.addTax10(item.price))}
                                      {` (${f({ id: 'label.inclusiveTax' }, { value: '10%' })})`}
                                    </h5>
                                  }
                                  <div className='mb-2'>
                                    <span>{f({ id: 'label.duration' })}: {item.duration + ' ' + f({ id: `subscription.${DURATION_MAP.get(0)}` })}</span>
                                  </div>
                                  {
                                    item.numberFreeTerm ? <div className="mb-2">
                                      <span>{f({ id: 'label.numberFreePeriod' })}: {returnNumberFreePeriod(item.numberFreeTerm)}</span>
                                    </div> : null
                                  }
                                </div>
                                <div style={{ flexGrow: 1 }}>
                                  <button className=" btn btn-xsm btn-round btn-block btn-success text-capitalize mt-2" onClick={() => history.push('/subscription/' + item.id)}>
                                    {f({ id: 'label.detail' })}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body p-0" style={{ display: 'flex', flexDirection: 'column' }}>
                          <ul className="list-group list-group-flush pb-1" style={{ display: 'flex', flexDirection: 'column' }}>
                            <h5 className="t-level-5 text-center my-2">{f({ id: 'label.contentGroups' })}</h5>
                            <li className="list-group-item" style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', maxHeight: '30vh' }}>
                              {
                                item.subscriptionContentGroups.map(contentGroup => {
                                  return (
                                    <div key={contentGroup.contentGroupId} style={{ display: 'flex' }}>
                                      <i className="far fa-check-circle text-success mr-1" style={{ alignSelf: 'center' }}></i>
                                      {contentGroup.contentGroupName}
                                    </div>
                                  );
                                })
                              }
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })
                : <div>{f({ id: 'label.noSubscriptionPlan' })}</div>
            }
          </div>
        }
      </ModalBody>
    </Modal>
  );
};

SubscriptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.object,
  contentInfo: PropTypes.any,
  isLoaded: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  contentSetting: PropTypes.array,
  openViewOnline: PropTypes.func,
  renderContentInfo: PropTypes.func,
  contentGroupId: PropTypes.number,
  getSubscriptionList: PropTypes.func,
  subscriptionList: PropTypes.array,
  isListLoad: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    isLoaded: getIsContentInfoLoad(state),
    subscriptionList: getSubscriptionList(state),
    isListLoad: getIsListLoad(state)
  };
};

export default connect(mapStateToProps)(props => (
  <SubscriptionModal
    {...props}
  />
));
