export const getTabs = state => state.search.tabs;
export const getPagination = state => state.search.pagination;
export const getData = state => state.search.data;
export const getIsDataLoad = state => state.search.IsDataLoad;
export const getTab = state => state.search.tabs;
export const getContentInfo = state => state.search.contentDetail;
export const getIsContentInfoLoad = state => state.search.IsContentDetailLoad;
export const isLoadContentDetailError = state => state.search.isLoadContentDetailError;

export const getSearchSetting = state => state.search.searchSetting;
export const getLoginSetting = state => state.search.settingLogin;
export const getIsLoadSetting = state => state.search.isLoadSetting;
export const getOptions = state => state.search.options;
export const getPageTitle = state => state.search.pageTitle;
export const getPageLogo = state => state.search.pageLogo;
export const getPageFavicon = state => state.search.pageFavicon;
export const getContentDetailParams = state => state.search.contentDetailParams;
export const getBeViewerHost = state => state.search.beViewerHost;
export const getPrintViewer = state => state.search.enablePrint;

export const getHeaderSetting = state => state.search.headerSetting;
export const getBodySetting = state => state.search.bodySetting;
export const getFooterSetting = state => state.search.footerSetting;
export const getInputSetting = state => state.search.inputSetting;
export const getContentDetailSetting = state => state.search.contentDetailSetting;
export const getTabSetting = state => state.search.tabSetting;
export const getLayoutViewPage = state => state.search.viewPage;
export const getCompanyName = state => state.search.companyName;
export const getSidebarSetting = state => state.search.sidebarSetting;
export const getBannerSetting = state => state.search.bannerSetting;

export const getFullTextSearchContents = state => state.search.ftsContents;
export const getFullTextSearchPagination = state => state.search.ftsPagination;

export const getAccountType = state => state.search.accountType;
export const getPasswordPolicy = state => state.search.strictPassword;
export const getAllowLoginByIpAddress = state => state.search.allowLoginByIpAddress;

export const getTitleOfEmailField = state => state.search.titleOfEmailField;
export const getTitleOfPasswordField = state => state.search.titleOfPasswordField;

export const getMoreLessBtnSetting = state => state.search.moreLessBtnSetting;

export const getServicePath = state => state.search.paths;
export const getShowSidebar = state => state.search.showSidebar;
export const getSelectedTabId = state => state.search.selectedTabId;

export const getGoogleOption = state => state.search.googleOption;
export const getGoogleId = state => state.search.googleId;
export const getGoogleAutoLoad = state => state.search.googleAutoLoad;

export const getMicrosoftLoginOption = state => state.search.microsoftLoginOption;

export const getNewInformation = state => state.search.newInformation;
export const getAllowForgotPassword = state => state.search.allowForgotPassword;
export const getDomainType = state => state.search.domainType;
export const getIsCancelAccount = state => state.search.isCancelAccount;
export const getSearchRequests = state => state.search.searchRequests;
export const getFulltextSearch = state => state.search.fulltextSearch;
export const getLayoutSettingAll = state => state.search.layoutSettingAll;
export const getIsSearch = state => state.search.IsSearch;
export const getSearchQuery = state => state.search.searchQuery;
// export const getTabSearchMap = state => state.search.tabSearchMap;
export const getInitialValuesSearch = state => state.search.initialValuesSearch;
export const getIdTabActive = state => state.search.idTabActive;
export const getIsOpenSearchFullScreen = state => state.search.isOpenSearchFullScreen;
export const getIsAutoRegisterGakkenId = state => state.search.isAutoRegisterGakkenAccountAtLogin;
export const getSpecialContentsGroupId = state => state.search.specialContentsGroupId;
