import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { ActionContext } from '../layout/MainContainer';
import {
  getViewerToken,
  getPrintSettingViewer,
  getIsErrorViewerOnline,
  getContentTypeViewer,
  getWatermark
} from 'state/content/contentSelector';
import {
  getAccountType,
  getBeViewerHost
} from 'state/search/searchSelector';
import { getAuthUser } from 'state/session/sessionSelector';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { DOMAIN_TYPE, OPTION_LOGIN, PRINT_SETTING, STORAGE_KEYS, UPLOAD_FILE_TYPES } from 'configs/AppConfig';
import { getServicePath, getViewSettings } from 'state/company/companySelector';
import StorageHelper from 'utils/StorageHelper';
// import { useMedia } from 'react-use';
import Util from 'utils/Util';

const Style = {
  fullSize: {
    width: '100%',
    height: 'calc(var(--vh, 1vh) * 100)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      fontWeight: 700,
      background: 'rgb(229, 57, 53)',
      color: '#fff',
      padding: '1rem'
    }
  },
  fullSizeEpubMobile: {
    width: '100VW',
    height: '100svh',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    border: 'none'
  },
  fullSizeEpubOldMobile: {
    width: '100VW',
    height: '100%',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    border: 'none'
  }
};

export const SearchViewBook = props => {
  const {
    classes,
    viewerToken,
    beViewerHost,
    isError,
    enablePrint,
    authInfo,
    contentType,
    watermark = {},
    servicePath,
    viewSettings,
    contentActions: { getViewerToken, getViewerPublicToken }
  } = props;
  const { id, contentGroupId, viewToken } = useParams();
  const contentViewId = id || viewToken;
  const location = useLocation() || {};
  const history = useHistory();

  const currentDomain = StorageHelper.getSessionItem(STORAGE_KEYS.domain);
  const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;
  const isAuthen = StorageHelper.getLocalObject(userStore);
  const isToken = StorageHelper.getCookie(userStore);
  const accountType = useSelector(getAccountType);
  // const below768 = useMedia('(max-width: 768px)');

  const isMobile = Util.mobileCheck();
  const isMobileTablet = Util.mobileAndTabletCheck();
  const browserVersion = Util.detectBrowserVersion();
  const [isOldVersion, setIsOldVersion] = useState(false);

  useEffect(() => {
    if (browserVersion.includes('Safari')) {
      const arr = browserVersion.split(' ');
      const currentVersion = arr[1];
      if (currentVersion <= 15) {
        setIsOldVersion(true);
      } else {
        setIsOldVersion(false);
      }
    }
  }, [browserVersion]);
  useEffect(() => {
    if (!isAuthen && !isToken && accountType !== OPTION_LOGIN.OPEN_TYPE) {
      StorageHelper.setSessionItem(STORAGE_KEYS.urlContentCallback, `${window.location.href}`);
      history.push({ pathname: '/login' });
    }
  }, [isAuthen, isToken, accountType]);

  useEffect(() => {
    if (location) {
      if (accountType === OPTION_LOGIN.OPEN_TYPE) {
        const request = {
          contentId: contentViewId,
          contentGroupId: contentGroupId
        };
        if (viewToken) {
          getViewerPublicToken({ tokenId: contentViewId });
        } else {
          getViewerToken(request);
        }
      }
      if (accountType !== OPTION_LOGIN.OPEN_TYPE && isToken) {
        const request = {
          contentId: contentViewId,
          contentGroupId: contentGroupId
        };
        if (viewToken) {
          getViewerPublicToken({ tokenId: contentViewId });
        } else {
          getViewerToken(request);
        }
      }
    }
  }, [location, isToken, accountType]);

  const { watermarkContent: wmContent, color: wmColor, fontSize: wmFontSize, position: wmPosition, id: wmId } = watermark || {};

  const isFullDomain = viewSettings.domainType === DOMAIN_TYPE.FULL_DOMAIN;

  const encodeWatermarkContent = encodeURIComponent(wmContent ? wmContent.replaceAll('&nbsp;', '') : '');
  const viewerSrc = `https://${beViewerHost}/viewer/${contentType}/index.html?token=${viewerToken}`;
  const viewerParams = `${enablePrint !== PRINT_SETTING.DO_NOT_ALLOW ? `&pr=${enablePrint}` : ''}${authInfo ? '&ui=' + authInfo.beUserId : ''}`;
  const watermarkURL = `&wt=${encodeWatermarkContent}${wmFontSize ? ('&ws=' + wmFontSize) : ''}${wmColor ? ('&wc=' + encodeURIComponent(wmColor)) : ''}${wmPosition ? ('&wp=' + wmPosition) : ''}`;
  const viewerURL = `${viewerSrc}${viewerParams}${!!wmId && contentType === UPLOAD_FILE_TYPES.PDF ? watermarkURL : ''}${'&tag=' + `${isFullDomain ? window.location.hostname : servicePath}`}`;

  useEffect(() => {
    if (viewerToken && viewerToken !== '') {
      const viewer = document.getElementById('urss-viewer');
      if (viewer) {
        viewer.focus();
        viewer.onload = (e) => {
          viewer.focus();
          viewer.contentWindow.focus();
        };
      }
    }
  }, [viewerToken]);

  useEffect(() => {
    const handleWindowResize = (e) => {
      setTimeout(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 300);
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // useEffect(() => {
  //   if (isMobile || isMobileTablet) {
  //     if (contentType && contentType === 'epub') {
  //       document.body.style.margin = '0px';
  //       document.body.style.overflow = 'hidden';
  //     }
  //   }
  // }, [isMobile, isMobileTablet, contentType]);

  return (
    <React.Fragment>
      {
        viewerToken && viewerToken !== '' && beViewerHost && beViewerHost !== ''

          ? <iframe id={'urss-viewer'} className={contentType === 'epub'
            ? (isMobile || isMobileTablet)
              ? isOldVersion
                ? classes.fullSizeEpubOldMobile
                : classes.fullSizeEpubMobile
              : classes.fullSize
            : classes.fullSize}
          src={viewerURL} title="URSS View Book Online" 
          // allow="fullscreen *"
          allowFullScreen
          >
          </iframe>
          : null
      }
      {
        isError && <TranslateMessage id="label.notAllowViewBook" />
      }
    </React.Fragment>
  );
};

SearchViewBook.defaultProps = {
};

SearchViewBook.propTypes = {
  classes: PropTypes.object.isRequired,
  viewerToken: PropTypes.string,
  beViewerHost: PropTypes.string,
  searchActions: PropTypes.object.isRequired,
  contentActions: PropTypes.object.isRequired,
  enablePrint: PropTypes.any,
  isError: PropTypes.bool,
  authInfo: PropTypes.object,
  watermark: PropTypes.any,
  contentType: PropTypes.string,
  servicePath: PropTypes.any,
  viewSettings: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    viewerToken: getViewerToken(state),
    watermark: getWatermark(state),
    beViewerHost: getBeViewerHost(state),
    enablePrint: getPrintSettingViewer(state),
    isError: getIsErrorViewerOnline(state),
    authInfo: getAuthUser(state),
    contentType: getContentTypeViewer(state),
    servicePath: getServicePath(state),
    viewSettings: getViewSettings(state)
  };
};

export default connect(mapStateToProps)(
  withStyles(Style)(
    props => (
      <ActionContext.Consumer>
        {({ contentActions, searchActions }) => (
          <SearchViewBook
            {...props}
            contentActions={contentActions}
            searchActions={searchActions}
          />
        )}
      </ActionContext.Consumer>
    )
  )
);
