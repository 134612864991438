export default {
  GET_NOTIFICATIONS_PAGING: {
    url: '/notification/public/page',
    method: 'GET'
  },
  GET_NOTIFICATIONS_DETAIL: {
    url: '/notification/public/detail/',
    method: 'GET'
  },
  ADD_NOTIFICATIONS: {
    url: '/notification/add',
    method: 'POST'
  },
  UPDATE_NOTIFICATIONS: {
    url: '/notification/update/',
    method: 'PUT'
  },
  DELETE_NOTIFICATIONS: {
    url: '/notification/delete/',
    method: 'DELETE'
  }
};
