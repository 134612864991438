export default {
  GET_CONTENT_GROUP_DATA: {
    url: '/content-group/page',
    method: 'GET'
  },
  GET_CONTENT_GROUP_DETAIL: {
    url: '/content-group/detail/',
    method: 'GET'
  },
  ADD_CONTENT_GROUP: {
    url: '/content-group/add',
    method: 'POST'
  },
  UPDATE_CONTENT_GROUP: {
    url: '/content-group/update/',
    method: 'PUT'
  },
  DELETE_CONTENT_GROUP: {
    url: '/content-group/delete/',
    method: 'DELETE'
  },
  GET_CONTENT_GROUP_ALL_DATA: {
    url: '/content-group/all',
    method: 'GET'
  },
  GET_PUBLIC_CONTENT_GROUPS: {
    url: '/content-group/public/list',
    method: 'GET'
  },
  GET_PUBLIC_CONTENT_GROUPS_BY_CONTENTID: {
    url: '/content-group/public/content-layout-detail',
    method: 'GET'
  },
  GET_USER_CONTENT_GROUPS: {
    url: '/content-group/content-group-list',
    method: 'GET'
  },
  RESET_CONTENT_GROUP: {
    url: '/content-group/reset-content-group',
    method: 'POST'
  },
  GET_FAVORITES_RECENTLY_READ: {
    url: '/content-group/favorites-recently-read',
    method: 'GET'
  },
  UPDATE_REF_CODE: {
    url: '/content-group/update-ref-code',
    method: 'PUT'
  }
};
