import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { ErrorMessage } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TranslateMessage from '../TranslateMessage/TranslateMessage';
import { useSelector } from 'react-redux';

const InputField = props => {
  const {
    field, form,
    type, label, placeholder, disabled, col,
    errorMessage, isTouched, min, max, onChange, fontSize
  } = props;
  const { name } = field;
  const { errors, touched } = form;
  let showError = errors[name] && touched[name];

  const inputSetting = useSelector((state) => state.search.inputSetting) || {};

  const useStyle = makeStyles((theme) => ({
    unPaddingTop: {
      paddingTop: 0
    },
    inputBox: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: 0,
      '& label': {
        minWidth: 100,
        paddingRight: '0.5rem',
        margin: 0,
        wordBreak: 'break-all'
      }
    },
    colInput: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    input: {
      minWidth: 150,
      flex: '1 0 100%',
      margin: '0.5rem 0 0.5rem 0',
      color: inputSetting.color,
      fontSize: inputSetting.fontSize || fontSize || '',
      height: inputSetting.height || '38px',
      minHeight: inputSetting.height || '',
      maxHeight: inputSetting.height || '38px',
      boxShadow: 'none!important',
      backgroundColor: inputSetting.backgroundColor,
      borderColor: inputSetting.borderColor ? `${inputSetting.borderColor}` : '#ced4da',
      '&:hover, &:focus, &:active': {
        color: inputSetting.hoverColor,
        backgroundColor: inputSetting.hoverBackgroundColor,
        borderColor: inputSetting.hoverBorderColor
          ? `${inputSetting.hoverBorderColor}`
          : inputSetting.borderColor
            ? `${inputSetting.borderColor}`
            : '#ced4da'
      }
    }
  }));

  const classes = useStyle();

  if (errorMessage) {
    showError = !!errorMessage && isTouched;
  }

  const handleChangeInput = (value) => {
    const changeEvent = {
      target: {
        name: name,
        value: value
      }
    };
    field.onChange(changeEvent);

    onChange && onChange(value);
  };

  return (
    <FormGroup className={`${classes.inputBox} ${col !== 12 && classes.colInput}`}>
      {label && <Label
        for={name}
        className={classes.unPaddingTop}
      >{label}</Label>}
      <Input
        id={`${name}-${(new Date()).getTime()}`}
        {...field}
        min={min}
        max={max}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        className={classes.input}
        onChange={(event) => handleChangeInput(event.target.value)}
        invalid={showError}
      />
      {!errorMessage && (
        <ErrorMessage name={name} component={FormFeedback} style={{
          display: showError ? 'inline' : 'none'
        }} />
      )}
      {!!errorMessage && (
        <FormFeedback style={{
          display: showError ? 'inline' : 'none'
        }}>
          <TranslateMessage id={errorMessage} />
        </FormFeedback>
      )}
    </FormGroup>
  );
};

InputField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,

  col: PropTypes.number,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.any,
  isTouched: PropTypes.bool,
  classes: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  inputSetting: PropTypes.object,
  onChange: PropTypes.func,
  fontSize: PropTypes.string
};

InputField.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  disabled: false
};

// const mapStateToProps = (state) => {
//   return {
//     inputSetting: getInputSetting(state)
//   };
// };

// export default connect(mapStateToProps)(InputField);

export default InputField;
