import CompanyTypes from './CompanyTypes';
import StorageHelper from 'utils/StorageHelper';
import { STORAGE_KEYS, PAGINATION, DOMAIN_TYPE, LAYOUT_SETTING_COLOR_DEFAULT } from 'configs/AppConfig';

const initialState = {
  companies: [],
  isSaving: false,
  isChecking: false,
  total: 0,
  totalPages: 0,
  queries: {
    ...PAGINATION,
    search_key: '',
    sort_field: '',
    sort: false
  },
  selected: [],
  companyDetail: {
    name: '',
    email: '',
    merchantCcid: '',
    merchantSecretKey: '',
    tokenApiKey: '',
    paymentMethod: '',
    domain: '',
    maxContent: 1,
    maxUser: 1,
    ownerId: '0',
    settingLogin: '',
    apiKey: '',
    beLoginName: '',
    bePassword: '',
    beViewerHost: '',
    beWebsiteHost: ''
  },
  companyDomain: StorageHelper.getSessionItem(STORAGE_KEYS.domain),
  servicePath: StorageHelper.getSessionItem(STORAGE_KEYS.path),
  endUserCompanyDetail: {},
  companyUserDetail: {
    email: '',
    fullName: ''
  },
  strictPassword: true,
  showSubscriptionStatus: true,
  optionImportUser: 1,
  pageLogo: '',
  viewSettings: {},
  pageMargin: {
    left: 40,
    right: 40
  },
  layoutSetting: {
    searchValueBorderColor: LAYOUT_SETTING_COLOR_DEFAULT.searchValueBorderColor,
    unselectSearchValueTextColor: LAYOUT_SETTING_COLOR_DEFAULT.unselectSearchValueTextColor,
    selectSearchValueTextColor: LAYOUT_SETTING_COLOR_DEFAULT.selectSearchValueTextColor,
    unselectSearchValueBackground: LAYOUT_SETTING_COLOR_DEFAULT.unselectSearchValueBackground,
    selectSearchValueBackground: LAYOUT_SETTING_COLOR_DEFAULT.selectSearchValueBackground
  },
  accountType: '',
  gakkenTermsAndCondition: ''
};

export default function companyReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case CompanyTypes.UPDATE_PAYMENT_VERITRANS_REQUEST:
      newState.isSaving = true;
      return newState;
    case CompanyTypes.UPDATE_PAYMENT_VERITRANS_SUCCESS:
      newState.isSaving = false;
      return newState;
    case CompanyTypes.UPDATE_PAYMENT_VERITRANS_ERROR:
      newState.isSaving = false;
      return newState;
    case CompanyTypes.VERIFY_PAYMENT_VERITRANS_REQUEST:
      newState.isChecking = true;
      return newState;
    case CompanyTypes.VERIFY_PAYMENT_VERITRANS_SUCCESS:
      newState.isChecking = false;
      return newState;
    case CompanyTypes.VERIFY_PAYMENT_VERITRANS_ERROR:
      newState.isChecking = false;
      return newState;
    case CompanyTypes.CREATE_COMPANIES_REQUEST:
      newState.isSaving = true;
      return newState;
    case CompanyTypes.CREATE_COMPANIES_SUCCESS:
      newState.isSaving = false;
      return newState;
    case CompanyTypes.CREATE_COMPANIES_ERROR:
      newState.isSaving = false;
      return newState;
    case CompanyTypes.UPDATE_COMPANY_DETAIL_REQUEST:
      newState.isSaving = true;
      return newState;
    case CompanyTypes.UPDATE_COMPANY_DETAIL_SUCCESS:
      newState.isSaving = false;
      return newState;
    case CompanyTypes.UPDATE_COMPANY_DETAIL_ERROR:
      newState.isSaving = false;
      return newState;
    case CompanyTypes.GET_COMPANIES_SUCCESS:
      newState.companies = [...payload.content];
      newState.totalPages = payload.totalPages;
      newState.total = payload.totalElements;
      return newState;
    case CompanyTypes.UPDATE_COMPANIES_QUERIES:
      newState.queries.page = payload.page;
      newState.queries.size = payload.size;
      newState.queries.search_key = payload.search_key;
      return newState;
    case CompanyTypes.UPDATE_SELECTED: {
      const data = { ...payload };
      const index = newState.selected.findIndex(x => x.id === data.selected.id);
      if (data.isSelected) {
        if (index === -1) {
          newState.selected = [...newState.selected, data.selected];
        }
      } else {
        if (index !== -1) {
          newState.selected.splice(index, 1);
          newState.selected = [...newState.selected];
        }
      }
      return newState;
    }
    case CompanyTypes.UPDATE_SELECTED_ALL: {
      const data = { ...payload };
      if (data.isSelected) {
        const index = newState.selected.findIndex(x => x.id === data.selected.id);
        if (index === -1) {
          for (let i = 0; i < data.selected.length; i++) {
            newState.selected = [...newState.selected, data.selected[i]];
          }
        }
      } else {
        for (let j = 0; j < data.selected.length; j++) {
          const index = newState.selected.findIndex(x => x.id === data.selected[j].id);
          if (index !== -1) {
            newState.selected.splice(index, 1);
            newState.selected = [...newState.selected];
          }
        }
      }
      return newState;
    }
    case CompanyTypes.GET_COMPANY_DETAIL_SUCCESS:
      newState.companyDetail = { ...payload.company };
      newState.companyUserDetail = { ...payload.user };
      return newState;
    case CompanyTypes.SET_COMPANY_DOMAIN:
      newState.companyDomain = payload;
      StorageHelper.setSessionItem(STORAGE_KEYS.domain, payload);
      return newState;
    case CompanyTypes.SET_SERVICE_PATH:
      newState.servicePath = payload;
      if (newState.domainType === DOMAIN_TYPE.FULL_DOMAIN) {
        StorageHelper.setSessionItem(STORAGE_KEYS.path, payload);
      } else {
        StorageHelper.setSessionItem(STORAGE_KEYS.path, payload);
      }
      return newState;
    case CompanyTypes.GET_END_USER_COMPANY_DETAIL_SUCCESS:
      newState.endUserCompanyDetail = { ...payload };
      return newState;
    case CompanyTypes.GET_GAKKEN_TERMS_SUCCESS:
      newState.gakkenTermsAndCondition = payload.termsAndCondition;
      return newState;
    case CompanyTypes.GET_SETTING_SUCCESS:
      newState.accountType = payload.accountType;
      newState.optionImportUser = payload.optionImportUser;
      newState.strictPassword = payload.strictPassword;

      newState.pageLogo = payload.logo;
      newState.pageFavicon = payload.favicon;
      newState.companyName = payload.companyName;

      newState.allowLoginByIpAddress = payload.loginByIpAddress;
      newState.showSubscriptionStatus = payload.showSubscriptionStatus;

      newState.titleOfEmailField = payload.titleOfEmailField;
      newState.titleOfPasswordField = payload.titleOfPasswordField;

      if (payload.domainType === DOMAIN_TYPE.FULL_DOMAIN) {
        newState.servicePath = '';
        StorageHelper.setSessionItem(STORAGE_KEYS.path, '');
      } else {
        newState.servicePath = payload.path;
        StorageHelper.setSessionItem(STORAGE_KEYS.path, payload.path);
      }
      // page margin
      var newPageMargin = { ...newState.pageMargin };
      var newLayoutSetting = { ...newState.layoutSetting };

      if (payload.layoutSettingResponse) {
        if (payload.layoutSettingResponse.pageMarginLeft || payload.layoutSettingResponse.pageMarginLeft === 0) {
          newPageMargin.left = payload.layoutSettingResponse.pageMarginLeft;
        }
        if (payload.layoutSettingResponse.pageMarginRight || payload.layoutSettingResponse.pageMarginRight === 0) {
          newPageMargin.right = payload.layoutSettingResponse.pageMarginRight;
        }
        Object.keys(newState.layoutSetting).forEach((key) => {
          if (payload.layoutSettingResponse[key]) {
            newLayoutSetting[key] = payload.layoutSettingResponse[key];
          }
        });
        newState.pageMargin = { ...newPageMargin };
        newState.layoutSetting = { ...newLayoutSetting };
      }

      newState.viewSettings = payload;
      return newState;
    case CompanyTypes.SHOW_GAKKEN_MY_PAGE:
      newState.showGakkenMyPage = true;
      return newState;
    default:
      newState = state;
      break;
  }
  // return new state
  return newState;
}
