import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { ActionContext, SessionContext, LayoutContext, RouterContext } from './MainContainer';
import { IMAGE_PATH, OPTION_LOGIN, USER_CONFIG } from 'configs/AppConfig';

import { getMenuRoutes } from 'state/route/routeSelector';
import { connect, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/styles';
import { getCompanyDomain, getPageFavicon, getServicePath, getViewSettings } from 'state/company/companySelector';
import { NoMatchPage } from 'configs/routes';

const ViewOnlineLayout = props => {
  const {
    children,
    pageFavicon,
    // domain,
    session: { isAuthenticated, user },
    actions: {
      sessionActions: { cacheRoutePath }
    }
  } = props;

  const theme = useTheme();
  const { viewToken } = useParams();
  const pathname = window.location.pathname;
  const contentViewToken = pathname.split('/')[2] || viewToken;
  const themeWidth = theme.breakpoints.values;
  const [drawerValue, setDrawerValue] = useState('normal');

  const viewSettings = useSelector(getViewSettings);
  const servicePath = useSelector(getServicePath);

  const isPublicViewer = servicePath === 'view-online';
  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;

  useEffect(() => {
    // Set Favicon
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
      favicon.type = 'image/png';
    }
  }, [pageFavicon]);
  /**
   * Watch layout changing
   */
  useLayoutEffect(() => {
    /**
     * Update drawer size
     * Func updateSize
     */
    const updateSize = () => {
      const width = window.innerWidth;
      if (width < themeWidth.md) {
        setDrawerValue('mobile');
      } else if (width < themeWidth.lg) {
        setDrawerValue('mini');
      } else {
        setDrawerValue('normal');
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    // Clear event listener on page will destroyed
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      cacheRoutePath();
    }
  }, []);

  useEffect(() => {
  }, [drawerValue]);

  if (isAuthenticated && (user.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || user.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel || user.roleLevel === USER_CONFIG.SERVICE_ADMIN.roleLevel)) {
    return <NoMatchPage/>;
  }
  if (isPublicViewer) {
    return <>
      {children}
    </>;
  } else {
    if (isAuthenticated || contentViewToken || isOpenType) {
      return children;
    } else {
      return <Redirect to={'/login'} push />;
    }
  }
};

ViewOnlineLayout.propTypes = {
  session: PropTypes.any,
  layout: PropTypes.any,
  actions: PropTypes.any,
  children: PropTypes.any,
  router: PropTypes.any,
  pageFavicon: PropTypes.any,
  domain: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    menuRoutes: getMenuRoutes(state),
    pageFavicon: getPageFavicon(state),
    domain: getCompanyDomain(state)
  };
};

export default connect(mapStateToProps)(props => (
  <RouterContext.Consumer>
    {router => (
      <ActionContext.Consumer>
        {actions => (
          <SessionContext.Consumer>
            {session => (
              <LayoutContext.Consumer>
                {layout => (
                  <ViewOnlineLayout
                    {...props}
                    session={session}
                    router={router}
                    layout={layout}
                    actions={actions}
                  />
                )}
              </LayoutContext.Consumer>
            )}
          </SessionContext.Consumer>
        )}
      </ActionContext.Consumer>
    )}
  </RouterContext.Consumer>
));
