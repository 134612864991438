export default {
  GET_END_USER_COMPANY_DETAIL: {
    url: '/company/public/get-detail',
    method: 'GET'
  },
  GET_SETTING: {
    url: '/service-admin/public/view-settings',
    method: 'GET'
  },
  VERIFY_PAYMENT_VERITRANS: {
    url: '/company/check-api-setting-veritrans',
    method: 'POST'
  },
  UPDATE_PAYMENT_VERITRANS: {
    url: '/company/update-veritrans-payment',
    method: 'PUT'
  },
  GET_GAKKEN_TERMS: {
    url: '/terms-and-condition/public',
    method: 'GET'
  }
};
