// panel name
const MAIN_LAYOUT = 'main';
const TODO_LAYOUT = 'todo';
const APP_TITLE = 'Unlimited Reading Service Console';

// Request configs
const REQUEST = {
  ACCESS_HEADER: 'X-Access-Token',
  RESULTS: 'results'
};

const APP_URL = {
  UPLOAD_PATH: process.env.REACT_APP_UPLOAD_PATH
  // UPLOAD_PATH: window.Configs.UPLOAD_PATH
};

const FORM_MODAL = {
  ADD: 'add',
  UPDATE: 'update'
};

const FORMS = {
  login: 'login',
  register: 'register',
  forgotPassword: 'ForgotPassword',
  userAccountSettingForm: 'UserAccountSettingForm',
  changePasswordForm: 'ChangePasswordForm',
  addContentForm: 'AddContentForm',
  changeEmailForm: 'ChangeEmailForm',
  uploadPatientForm: 'UploadPatientForm',
  disclosureGroupTitleDescriptionForm: 'DisclosureGroupTitleDescriptionForm',
  patientGroupTitleDescriptionForm: 'patientGroupTitleDescriptionForm',
  disclosureTableHeadingForm: 'DisclosureTableHeadingForm',
  patientTableHeadingForm: 'PatientTableHeadingForm',
  pageTitleForm: 'PageTitleForm',
  searchForm: 'SearchForm',
  companyDetailForm: 'CompanyDetailForm',
  companyCreateForm: 'CompanyCreateForm',
  companyUserDetailForm: 'CompanyUserDetailForm',
  adminAccountSettingForm: 'AdminAccountSettingForm',
  userGroupFrom: 'UserGroupForm'
};

// paging params
const TABLE = {
  DEFAULT_PAGE_NUMBER: 1,
  DEFAULT_PAGE_SIZE: 10
};

const PAGINATION = {
  page: 1,
  size: 10
};

const STORAGE_KEYS = {
  session: 'session',
  layout: 'layout',
  user: 'user',
  disclosureAgreement: 'disclosureAgreement',
  patientAgreement: 'patientAgreement',
  unique: 'unique',
  domain: 'domain',
  path: 'path',
  googleLogin: 'googleLogin',
  cacheStorage: 'cacheStorage',
  couponCode: 'couponCode',
  subsId: 'subsId',
  redirectUrlCallback: 'redirectUrlCallback',
  urlContentCallback: 'urlContentCallback',
  urlViewCallback: 'urlViewCallback',
  urlSubscDetail: 'urlSubscDetail',
  loginType: 'loginType',
  microsoftLogin: 'microsoftLogin',
  microsoftVerifier: 'microsoftVerifier',
  searchContent: 'searchContent',
  appMobileAuthToken: 'appMobileAuthToken',
  cacheLoginMobileApp: 'cacheLoginMobileApp'
};

// Default notification config [see usage at notificationSaga.showNotification()]
const DEFAULT_NOTIFICATION_CONFIG = {
  message: 'api.error.unknown',
  level: 'error',
  position: 'br',
  autoDismiss: 5
};

export const APP_LAUOUT = {
  HOME: 'home',
  VIEW_ONLINE: 'viewOnline',
  PUBLIC: 'public',
  UNAUTHENTICATED: 'unauthenticated'
};

export const KEY_MULTIPART_CHOICE = {
  matchValue: 'm-v-',
  contentGroup: 'c-g-'
};

const PATTERNS = {
  OLD_EMAIL_PATTERN: /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/,
  // eslint-disable-next-line no-useless-escape
  EMAIL_PATTERN: /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  DOMAIN_PATTERN: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
  IP_PATTERN: /^([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])$/,
  USERNAME_PATTERN: /^[A-Za-z0-9_.-]{6,64}$/,
  PASSWORD_PATTERN: /^.{8,}$/,
  PASSWORD_UPPER: /^(?=.*[A-Z]).{8,}$/,
  PASSWORD_LOWER: /^(?=.*[a-z]).{8,}$/,
  PASSWORD_NUMBER: /^(?=.*\d).{8,}$/,
  DATE_PATTERN: /^(([01])\d)\/(([012])\d)\/((19|20)\d{2})$/,
  DATE_FORMAT: 'MM/DD/YYYY',
  DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm:ss',
  SECTION_PATTERN: /[A-E][1-9]-[1-9]|[A-E][1-9]/g,
  NUMBER_MAX_LENGTH_64: /^[0-9]{,64}$/,
  NUMBER_MAX_LENGTH_128: /^[0-9]{,128}$/,
  NUMBER_MAX_LENGTH_255: /^[0-9]{,255}$/,
  USER_GROUP_NAME: /^[a-zA-Z0-9_ -]*$/,
  BLANK_SPACES: /[^\s*]/g,
  ELEMENT_ID: /^[a-z0-9]{20,64}$/
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const ROOT_GROUPS = ['A', 'B', 'C', 'D', 'E'];

export const GROUP_OBJECT = {
  DISCLOSURE: 'disclosure',
  PATIENT: 'patient'
};
export const GROUP_TYPES = [GROUP_OBJECT.DISCLOSURE, GROUP_OBJECT.PATIENT];
export const SECTION_TYPES = {
  DISCLOSURE: {
    type: GROUP_OBJECT.DISCLOSURE,
    value: 1
  },
  PATIENT: {
    type: GROUP_OBJECT.PATIENT,
    value: 2
  }
};

export const FILE_STATUS = {
  DONE: 0,
  PENDING: 2,
  PROGRESSING: 1
};

export const API_STATUS = {
  PROGRESSING: 0,
  SUCCESS: 1,
  ERROR: 2
};

export const LOAD_CONTENT_ACTION_TYPE = {
  PAGING: 'PAGING',
  SCROLL: 'SCROLL'
};

export const AUTHEN_TYPES = {
  EMAIL: 'email',
  ID: 'identity',
  IP: 'ip_address',
  AUTO_IP: 'auto_ip_address',
  GAKKEN_ID: 'gakken_id',
  GOOGLE_LOGIN: 'google_login',
  MICROSOFT_LOGIN: 'microsoft_login'
};

const USER_CONFIG = {
  SYSTEM_ADMIN: {
    roleLevel: 1,
    loginType: 1,
    landingPage: '/'
  },
  COMPANY_ADMIN: {
    roleLevel: 2,
    loginType: 2,
    landingPage: '/'
  },
  SERVICE_ADMIN: {
    roleLevel: 5,
    loginType: 3,
    landingPage: '/'
  },
  USER: {
    roleLevel: 3,
    loginType: 4,
    landingPage: '/'
  },
  IP: {
    roleLevel: 4,
    loginType: 5,
    landingPage: '/'
  }
};

export const GOOGLE_OPTION = {
  NONE: 'NONE',
  LOGIN: 'LOGIN'
  // LOGIN_REGISTER: 'LOGIN_REGISTER'
};

export const MICROSOFT_LOGIN_OPTION = {
  NONE: 'NONE',
  LOGIN: 'LOGIN',
  LOGIN_REGISTER: 'LOGIN_REGISTER'
};

const LANGUAGES = [
  {
    value: 'en',
    label: 'lang.en'
  },
  {
    value: 'ja',
    label: 'lang.ja'
  }
];

const DOWNLOAD_FILE_TYPES = [
  'text/csv',
  'application/pdf',
  'application/octet-stream'
];

const UPLOAD_FILE_TYPES = {
  CSV: 'csv',
  PDF: 'pdf'
};

const SEARCH_BOX_OPERATION = {
  PARTIAL_MATCH: {
    id: 1,
    label: 'label.partialMatch'
  },
  EXACT_MATCH: {
    id: 2,
    label: 'label.exactMatch'
  },
  DATE_RANGE: {
    id: 3,
    label: 'label.dateRange'
  },
  SINGLE_CHOICE: {
    id: 4,
    label: 'label.singleChoice'
  },
  MULTIPART_CHOICE: {
    id: 5,
    label: 'label.multipartChoice'
  },
  MONTH_RANGE: {
    id: 6,
    label: 'label.monthRange'
  },
  YEAR_RANGE: {
    id: 7,
    label: 'label.yearRange'
  }
};

const SEARCH_FIELD = {
  TITLE: {
    id: 1,
    dbKey: 'title',
    label: 'label.title',
    size: 6,
    hasBox: false
  },
  AUTHOR: {
    id: 2,
    dbKey: 'author',
    label: 'label.author',
    size: 6,
    hasBox: false
  },
  KEYWORDS: {
    id: 3,
    dbKey: 'keywords',
    label: 'label.keywords',
    size: 6,
    hasBox: false
  },
  TOC: {
    id: 4,
    dbKey: 'toc',
    label: 'label.toc',
    size: 12,
    hasBox: true
  },
  DISTRIBUTOR: {
    id: 5,
    dbKey: 'distributorName',
    label: 'label.distributorName',
    size: 6,
    hasBox: false
  },
  DESCRIPTION: {
    id: 6,
    dbKey: 'description',
    label: 'label.description',
    size: 12,
    hasBox: true
  },
  PARAM_1: {
    id: 7,
    dbKey: 'param1',
    label: 'label.param1',
    size: 12,
    hasBox: false
  },
  PARAM_2: {
    id: 8,
    dbKey: 'param2',
    label: 'label.param2',
    size: 12,
    hasBox: false
  },
  PARAM_3: {
    id: 9,
    dbKey: 'param3',
    label: 'label.param3',
    size: 12,
    hasBox: false
  },
  PARAM_4: {
    id: 10,
    dbKey: 'param4',
    label: 'label.param4',
    size: 12,
    hasBox: false
  },
  PARAM_5: {
    id: 11,
    dbKey: 'param5',
    label: 'label.param5',
    size: 12,
    hasBox: false
  },
  CONTENTS_GROUP: {
    id: 12,
    dbKey: 'contentsGroup',
    label: 'label.contentsGroup',
    size: 12,
    hasBox: false
  },
  CONTENTS_GROUP_SPECIAL: {
    id: 13,
    dbKey: 'contentsGroupSpecial',
    label: 'label.contentsGroupSpecial',
    size: 12,
    hasBox: false
  }
};

const SEARCH_BOX_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  PENDING: 2
};

const ENCODE_STATUS = {
  NOT_ENCODE: 1,
  ENCODING: 2,
  ENCODED: 3,
  ERROR: 4
};
const PAGING_POSITION = {
  TOP: {
    id: 1,
    label: 'label.top'
  },
  BOTTOM: {
    id: 2,
    label: 'label.bottom'
  },
  TOP_AND_BOTTOM: {
    id: 3,
    label: 'label.t&b'
  }
};

const SETTING_LOGIN = {
  NO_REQUIRE: {
    value: 0,
    name: 'noRequire'
  },
  LOGINREQUIRE: {
    value: 1,
    name: 'requireLogin'
  },
  VIEWBOOK: {
    value: 2,
    name: 'viewBook'
  }
};

const DISPLAY_SEARCH_RESULTS = {
  LIST_VIEW: 1,
  TITLE_VIEW: 2,
  ALL: 3
};
const FIELD_SIZE = [
  {
    value: 3,
    label: '25%'
  },
  {
    value: 4,
    label: '33%'
  },
  {
    value: 6,
    label: '50%'
  },
  {
    value: 12,
    label: '100%'
  }
];
export const LINK_TYPE = {
  INTERNAL_LINK: 1,
  EXTERNAL_LINK: 2
};
export const LINK_POSITION = {
  UPPER_LINK: 1,
  LOWER_LINK: 2,
  MIDDLE_LINK: 3
};
export const POSITION_CONFIG = {
  CENTER: {
    id: 1,
    label: 'label.center'
  },
  LEFT: {
    id: 2,
    label: 'label.left'
  },
  RIGHT: {
    id: 3,
    label: 'label.right'
  }
};
export const POSITION_PROFILE = {
  LEFT: 1,
  MIDDLE: 2,
  RIGHT: 3
};
export const BUTTON_ACTION = {
  VIEW_DETAIL: {
    id: 1,
    label: 'label.viewDetail'
  },
  SEARCH: {
    id: 2,
    label: 'label.search'
  },
  VIEW_BOOK: {
    id: 3,
    label: 'label.viewBook'
  }
};
export const LAYOUT_SETTING = {
  SEARCH_LAYOUT: {
    id: 1,
    label: 'label.searchLayout'
  },
  HOME_LAYOUT: {
    id: 2,
    label: 'label.homeLayout'
  },
  NO_LAYOUT: {
    id: 3,
    label: 'label.noLayout'
  },
  HOME_LAYOUT_AND_SEARCH_LAYOUT: {
    id: 4,
    label: 'label.homeLayoutAndSearchLayout'
  }
};
export const SORT_FIELD = {
  DATE: 12,
  TITLE: 1
};
export const SORT_FIELD_TYPE = {
  SORT_FIELD_SEARCH: 1,
  SORT_FIELD_HOME: 2
};
export const SEARCHBOX_TYPE = {
  COMMON_SEARCH: {
    id: 1,
    label: 'label.commonSearch'
  },
  FULLTEXT_SEARCH: {
    id: 2,
    label: 'label.fulltextSearch'
  }
};
export const CASE_CHANGE_PAGE = {
  default: 'default',
  pagination: 'pagination',
  scroll: 'scroll'
};
export const TAB_TYPE = {
  PARAM_SEARCH: {
    id: 1,
    label: 'label.paramSearch'
  },
  FULLTEXT_SEARCH: {
    id: 2,
    label: 'label.fulltextSearch'
  }
};
export const CONTENT_GROUP_TYPE = {
  NORMAL: 1,
  FAVORITE: 2,
  RECENTLY: 3,
  NEW: 4,
  POPULAR: 5,
  RECOMMEND: 6
};
export const WATERMARK_TYPE = {
  DEFAULT: '1',
  CUSTOM: '2'
};
export const WATERMARK_POSITION = {
  CENTER: 'center',
  DIAGONAL: 'diagonal',
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right'
};
export const LAYOUT_SETTING_COLOR_DEFAULT = {
  searchValueBorderColor: '#0000003b',
  unselectSearchValueTextColor: '#000',
  selectSearchValueTextColor: '#fff',
  unselectSearchValueBackground: '#fff',
  selectSearchValueBackground: '#00b27b'
};
export const IMAGE_PATH = {
  LOGO: 'logo/',
  FAVICON: 'favicon/',
  SUBSCRIPTION: 'subscription/',
  BANNER: 'banner/'
};
export const OPTION_LOGIN = {
  USERNAME: 'USERNAME',
  EMAIL: 'EMAIL',
  GAKKEN_ID: 'GAKKEN_ID',
  ALL: 'ALL',
  OPEN_TYPE: 'OPEN_TYPE'
};
export const PASSWORD_POLICY = {
  STRICT_PASSWORD: 'STRICT_PASSWORD',
  WEAK_PASSWORD: 'WEAK_PASSWORD'
};
export const BANNER_TYPE = {
  MESSAGE: 1,
  IMAGE: 2,
  SLIDE: 3
};
export const SUBSCRIPTION_TYPE = {
  NONE: 1,
  LOGIN: 2
};
export const AccountStatus = {
  ALLOW: 0,
  STOP: 1
};
export const PaymentMethod = {
  VERITRANS: 'VERITRANS',
  GMO: 'GMO',
  SHOPIFY: 'SHOPIFY',
  GAKKEN_ID: 'GAKKEN_ID'
};
export const SUBSCRIPTION_STATUS = {
  ADD: 0,
  UPDATE: 1,
  DELETE: 2
};
export const PRINT_SETTING = {
  DO_NOT_ALLOW: 0,
  PRINT_ALL_PAGES: 1,
  PRINT_CURRENT_PAGE: 2
};
export const OPTION_IMPORT_USER = {
  OVERWRITE_EXISTING: 1,
  IGNORE_EXISTING: 2,
  DELETE: 3
};
export const SORT_DIRECTION = {
  ASCENDING: 'asc',
  DESCENDING: 'desc'
};
export const CONTENT_DETAIL_LAYOUT = {
  SIMPLE: 'SIMPLE',
  LIST_SAME_GROUP: 'LIST_SAME_GROUP',
  SERIES: 'SERIES'
};
export const WATERMARK_TAGS = {
  user_name: 'username',
  user_email: 'userEmail',
  user_id: 'userId',
  user_param1: 'userParam1',
  user_param2: 'userParam2',
  user_param3: 'userParam3',
  user_param4: 'userParam4',
  user_param5: 'userParam5',
  user_group_name: 'userGroupName'
};

export const POSITION_FAVORITE_BUTTON = {
  IMAGE: 'IMAGE',
  INFORMATION: 'INFORMATION'
};
export const TYPE_PRE_NEXT_PAGE = {
  GET_CONTENT_BY_CONTENT_GROUP: 1,
  SEARCH_FULL_TEXT_BY_CONTENT_GROUP: 2,
  SEARCH_CONTENT_BY_CONTENT_GROUP: 3
};

export const POSITION_LINK_FOOTER = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
};
export const PARAM_SERIES_CONTENT = {
  1: 'param1',
  2: 'param2',
  3: 'param3',
  4: 'param4',
  5: 'param5'
};
export const PAGING_TYPE = {
  SCROLL: 'SCROLL',
  NUMBER_PAGING: 'NUMBER_PAGING'
};

export const EXCEPT_ERRORS = [703];
export const PUBLIC_PAGE = ['view-online', 'direct-view', 'content', '404', 'login', 'search', 'forget-password'];
export const HIDE_HOME_SIDEBAR = ['profile', 'subscription', 'gakken-payment', 'order-entry', 'veritrans-payment', 'thank-you'];
export const IGNORE_MARGIN_PAGE = ['profile', 'subscription'];
export const IGNORE_SERVICE_PATH = [
  'login', 'forget-password',
  'direct-view', 'view-online',
  'search', 'user', 'profile',
  'subscription', 'home-search',
  'content'
];

export const DOMAIN_SYSTEM_ADMIN = [
  'urs-admin.keyring.net',
  'urs-admin-stg.keyring.net',
  'urss-admin.689cloud.com',
  'urss-admin.localhost'
];

export const DURATION_MAP = new Map([
  [0, 'day'],
  [1, 'month'],
  [2, 'year']
]);

export const GAKKEN_LINK = {
  LOGIN_PAGE: 'https://id-qas.gakken.jp/common-login?response_type=code',
  MY_PAGE: 'https://id-qas.gakken.jp/my-page',
  REGISTER_PAGE: 'https://id-qas.gakken.jp/user-register-1?response_type=code'
};

export const DOMAIN_TYPE = {
  FULL_DOMAIN: 'FULL_DOMAIN',
  PATH: 'PATH'
};

export {
  //* *********//
  FORM_MODAL,
  MAIN_LAYOUT,
  TODO_LAYOUT,
  APP_TITLE,
  //* *********//
  REQUEST,
  //* *********//
  FORMS,
  //* *********//
  PAGINATION,
  TABLE,
  //* *********//
  STORAGE_KEYS,
  //* *********//
  DEFAULT_NOTIFICATION_CONFIG,
  //* *********//
  PATTERNS,
  USER_CONFIG,
  APP_URL,
  LANGUAGES,
  DOWNLOAD_FILE_TYPES,
  UPLOAD_FILE_TYPES,
  SEARCH_BOX_OPERATION,
  SEARCH_FIELD,
  SEARCH_BOX_STATUS,
  ENCODE_STATUS,
  PAGING_POSITION,
  DISPLAY_SEARCH_RESULTS,
  SETTING_LOGIN,
  FIELD_SIZE
};
