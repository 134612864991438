export const getSubscriptionList = state => state.subscription.subscriptionList;
export const getPagination = state => state.subscription.pagination;
export const getSubscriptionTotalRecords = state => state.subscription.pagination.totalRecords;
export const getSubscriptionTotalPages = state => state.subscription.pagination.totalPages;

export const getSubscriptionDetail = state => state.subscription.subscriptionDetail;
export const getIsSubscriptionDetailLoad = state => state.subscription.isSubscriptionDetailLoad;

export const getIsListLoad = state => state.subscription.isListLoad;

export const getIsSaving = state => state.subscription.isSaving;

export const getIsOpenPopUp = state => state.subscription.isOpenPopUp;
