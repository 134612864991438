const _prefix = '@subscription-ordinal/';

export default {
  GET_SUBSCRIPTION_ORDINAL_PAGE_REQUEST: _prefix + 'GET_SUBSCRIPTION_ORDINAL_PAGE_REQUEST',
  GET_SUBSCRIPTION_ORDINAL_PAGE_SUCCESS: _prefix + 'GET_SUBSCRIPTION_ORDINAL_PAGE_SUCCESS',
  GET_SUBSCRIPTION_ORDINAL_PAGE_ERROR: _prefix + 'GET_SUBSCRIPTION_ORDINAL_PAGE_ERROR',

  ADD_SUBSCRIPTION_ORDINAL_REQUEST: _prefix + 'ADD_SUBSCRIPTION_ORDINAL_REQUEST',

  GET_SUBSCRIPTION_ORDINAL_DETAIL_REQUEST: _prefix + 'GET_SUBSCRIPTION_ORDINAL_DETAIL_REQUEST',
  GET_SUBSCRIPTION_ORDINAL_DETAIL_SUCCESS: _prefix + 'GET_SUBSCRIPTION_ORDINAL_DETAIL_SUCCESS',
  GET_SUBSCRIPTION_ORDINAL_DETAIL_ERROR: _prefix + 'GET_SUBSCRIPTION_ORDINAL_DETAIL_ERROR'
};
