import React from 'react';
import ActionButton from '../common/Button/ActionButton';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getViewSettings } from 'state/company/companySelector';
import { OPTION_LOGIN, STORAGE_KEYS } from 'configs/AppConfig';
import StorageHelper from 'utils/StorageHelper';

const Styles = {
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
    backgroundColor: '#FFFFFF'
  },
  error: {
    fontSize: 100,
    textAlign: 'center'
  }
};

const GakkenPaymentError = () => {
  const history = useHistory();
  const viewSettings = useSelector(getViewSettings);
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;

  const cacheGakkenRedirectUrl = StorageHelper.getSessionItem(STORAGE_KEYS.redirectUrlCallback);

  const goBack = () => {
    if (cacheGakkenRedirectUrl) {
      window.open(`${cacheGakkenRedirectUrl}`, '_self');
      StorageHelper.removeSessionItem(STORAGE_KEYS.redirectUrlCallback);
    } else {
      history.goBack();
    }
  };

  return (
    <div
      style={Styles.root}
      className="d-flex flex-column align-content-center justify-content-center text-center pb-md-5"
    >
      <div className="pb-5">
        <h1 style={Styles.error}>ERROR</h1>
        <h3 className="d-none d-sm-block">SO SORRY, PAYMENT BY GAKKEN IS FAIL</h3>
        <div className="d-sm-none">
          <h3>SO SORRY</h3>
          <h3>PAYMENT BY GAKKEN IS FAIL</h3>
        </div>
        {
          isOpenType
            ? null
            : <ActionButton type="primary" onClick={goBack}>
          Go back
            </ActionButton>
        }
      </div>
    </div>
  );
};

export default GakkenPaymentError;
