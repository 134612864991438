import { all, put, select, takeEvery } from 'redux-saga/effects';
import SearchTypes from './SearchTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/search';
import API_CONTENT from '../../api/content';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { getCompanyDomain, getServicePath } from '../company/companySelector';
import { getIsMobileApp, isAuthenticated } from '../session/sessionSelector';

function * getData () {
  yield takeEvery(SearchTypes.GET_DATA_REQUEST, _getData);
}

function * _getData ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const isMobileApp = yield select(getIsMobileApp);
  const { searchRequests, pagination, path, isScrollPage } = payload;
  const request = snakecaseKeys({ ...pagination, ...domain, ...path });
  yield createRequest({
    api: {
      ...API.GET_DATA,
      data: snakecaseKeys([...searchRequests]),
      params: {
        path: path,
        page: request.page,
        size: request.size,
        domain: domain,
        sort: request.sort,
        is_mobile_app: isMobileApp,
        sort_field: request.sort_by
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.GET_DATA_SUCCESS,
        payload: Util.toCamelCaseKey({ ...response.data, searchRequests, isScrollPage: isScrollPage || false })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.GET_DATA_ERROR,
        payload: status
      });
    }
  });
}

function * getPublicData () {
  yield takeEvery(SearchTypes.GET_PUBLIC_DATA_REQUEST, _getPublicData);
}

function * _getPublicData ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const isMobileApp = yield select(getIsMobileApp);
  const { searchRequests, pagination, isScrollPage } = payload;
  const request = snakecaseKeys({ ...pagination, ...domain });
  yield createRequest({
    api: {
      ...API.GET_PUBLIC_DATA,
      data: snakecaseKeys([...searchRequests]),
      params: {
        page: request.page,
        size: request.size,
        domain: domain,
        path: servicePath,
        sort: request.sort,
        is_mobile_app: isMobileApp,
        sort_field: request.sort_by
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.GET_PUBLIC_DATA_SUCCESS,
        payload: Util.toCamelCaseKey({ ...response.data, searchRequests, isScrollPage: isScrollPage || false })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.GET_PUBLIC_DATA_ERROR,
        payload: status
      });
    }
  });
}

function * getDetail () {
  yield takeEvery(SearchTypes.GET_CONTENT_INFO_REQUEST, _getDetail);
}

function * _getDetail ({ payload }) {
  const referer = document.referrer;
  const { contentId, contentToken, contentGroupId } = payload;
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const request = snakecaseKeys(
    {
      domain: domain,
      path: servicePath,
      content_group_id: contentGroupId,
      id: contentId,
      detail_id: contentToken
    }
  );
  yield createRequest({
    api: {
      ...API.GET_INFO,
      url: `${API.GET_INFO.url}`,
      params: { ...request },
      headers: {
        'cus-referer': referer || ''
      }
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.GET_CONTENT_INFO_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.GET_CONTENT_INFO_ERROR,
        payload: status
      });
    }
  });
}

function * loadOptions () {
  yield takeEvery(SearchTypes.GET_OPTIONS_REQUEST, _loadOptions);
}

function * _loadOptions ({ payload }) {
  yield put({
    type: SearchTypes.GET_OPTIONS_SUCCESS,
    payload: {
      requiredLogin: 1
    }
  });
}

function * searchFullText () {
  yield takeEvery(SearchTypes.SEARCH_FULL_TEXT_REQUEST, _searchFullText);
}

function * _searchFullText ({ payload }) {
  const { pagination, searchKey, contentGroupId, isScrollPage } = payload;
  const { page, size, sort } = pagination;
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const isAuthen = yield select(isAuthenticated);
  const isMobileApp = yield select(getIsMobileApp);
  const request = snakecaseKeys({ page, size, sort, sort_field: pagination.sort_by, contentGroupId, searchKey, domain: domain, path: servicePath, isMobileApp });
  const api = isAuthen ? API.SEARCH_FULL_TEXT_AUTHEN : API.SEARCH_FULL_TEXT;
  yield createRequest({
    api: {
      ...api,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.SEARCH_FULL_TEXT_SUCCESS,
        payload: Util.toCamelCaseKey({ ...response.data, fulltextSearch: payload.searchKey, isScrollPage: isScrollPage || false })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.SEARCH_FULL_TEXT_ERROR,
        payload: status
      });
    }
  });
}

function * loadContentByContentGroupSpecial () {
  yield takeEvery(SearchTypes.GET_CONTENT_BY_CONTENT_GROUP_SPECIAL_REQUEST, _loadContentByContentGroupSpecial);
}

function * _loadContentByContentGroupSpecial ({ payload }) {
  const { contentGroupId, isScrollPage } = payload;
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const isMobileApp = yield select(getIsMobileApp);
  delete payload.isScrollPage;
  const request = snakecaseKeys({ ...payload, domain, path: servicePath, isMobileApp });
  yield createRequest({
    api: {
      ...API_CONTENT.GET_CONTENT_BY_CONTENT_GROUP,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SearchTypes.GET_CONTENT_BY_CONTENT_GROUP_SPECIAL_SUCCESS,
        payload: Util.toCamelCaseKey({ ...response.data, isScrollPage: !!isScrollPage, specialContentsGroupId: contentGroupId })
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SearchTypes.GET_CONTENT_BY_CONTENT_GROUP_SPECIAL_ERROR,
        payload: { status, contentGroupId }
      });
    }
  });
}

export default function * searchSaga () {
  yield all([
    getData(),
    getPublicData(),
    getDetail(),
    loadOptions(),
    searchFullText(),
    loadContentByContentGroupSpecial()
  ]);
}
