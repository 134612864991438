import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PublicFooter from '../core/PublicFooter';
import { connect, useSelector } from 'react-redux';
import { ActionContext, RouterContext } from './MainContainer';
import PublicAppBar from '../core/PublicAppBar';
import { makeStyles } from '@material-ui/core';
import { LAYOUT_SETTING } from 'configs/AppConfig';
import { getLayoutViewPage } from 'state/search/searchSelector';
import HomeHeader from 'components/home/HomeHeader';

const DefaultLayout = ({ children, useAppBar, router: { location } }) => {
  const style = useSelector((state) => state.search.bodySetting) || {};
  const layoutViewPage = useSelector(getLayoutViewPage);
  const isHomeAndSearchLayout = layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id;
  const [showLoginFormModal, setShowLoginFormModal] = useState(false);
  const useStyle = makeStyles((theme) => (
    {
      body: {
        backgroundColor: `${style.backgroundColor}!important`
      }
    }
  ));

  const classes = useStyle();

  return (
    <div className={`wrapper ${classes.body}`}>
      {useAppBar && (isHomeAndSearchLayout
        ? <HomeHeader
          showLoginFormModal={showLoginFormModal}
          layoutViewPage={layoutViewPage}
          setShowLoginFormModal={setShowLoginFormModal}
          isNotShowBanner={true}
        />
        : <PublicAppBar location={location} />)}
      <main role="main" className={`flex-shrink-0 footer-offset ${classes.body}`}>
        <div className="container-fluid p-3 pt-5">
          {children}
        </div>
      </main>
      <PublicFooter />
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.any,
  useAppBar: PropTypes.bool,
  router: PropTypes.any
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(props => (
  <RouterContext.Consumer>
    {router => (
      <ActionContext.Consumer>
        {() => (
          <DefaultLayout
            {...props}
            router={router}
          />
        )}
      </ActionContext.Consumer>
    )}
  </RouterContext.Consumer>
));
