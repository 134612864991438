import ContentTypes from './ContentTypes';

export function loadContentData (data) {
  return {
    type: ContentTypes.GET_CONTENT_DATA_REQUEST,
    payload: data
  };
}

export function addContent (data) {
  return {
    type: ContentTypes.ADD_CONTENT_REQUEST,
    payload: data
  };
}

export function updateContent (data) {
  return {
    type: ContentTypes.UPDATE_CONTENT_REQUEST,
    payload: data
  };
}

export function deleteContent (data) {
  return {
    type: ContentTypes.DELETE_CONTENT_REQUEST,
    payload: data
  };
}

export function loadContentDetail (data) {
  return {
    type: ContentTypes.GET_CONTENT_DETAIL_REQUEST,
    payload: data
  };
}

export function uploadContent (data) {
  return {
    type: ContentTypes.UPLOAD_CONTENT_REQUEST,
    payload: data
  };
}

export function setUploadProgress (id, progress) {
  return {
    type: ContentTypes.SET_UPLOAD_PROGRESS,
    payload: {
      id,
      progress
    }
  };
}

export function successUploadContent (id) {
  return {
    type: ContentTypes.SUCCESS_UPLOAD_CONTENT,
    payload: id
  };
}

export function failureUploadContent (id) {
  return {
    type: ContentTypes.FAILURE_UPLOAD_CONTENT,
    payload: id
  };
}

export function getViewerToken (data) {
  return {
    type: ContentTypes.GET_VIEWER_TOKEN,
    payload: data
  };
}
export function getViewerPublicToken (data) {
  return {
    type: ContentTypes.GET_VIEWER_PUBLIC_TOKEN,
    payload: data
  };
}

export function closeUploadProgress (data) {
  return {
    type: ContentTypes.CLOSE_UPLOAD_PROGRESS,
    payload: data
  };
}

export function exportContents (data) {
  return {
    type: ContentTypes.EXPORT_CONTENTS_REQUEST,
    payload: data
  };
}

export function addFavoriteContent (data) {
  return {
    type: ContentTypes.ADD_FAVORITE_CONTENT,
    payload: data
  };
}

export function removeFavoriteContent (data) {
  return {
    type: ContentTypes.REMOVE_FAVORITE_CONTENT,
    payload: data
  };
}

export function addTrendingContent (data) {
  return {
    type: ContentTypes.ADD_TRENDING_CONTENT,
    payload: data
  };
}

export function removeTrendingContent (data) {
  return {
    type: ContentTypes.REMOVE_TRENDING_CONTENT,
    payload: data
  };
}

export function loadSeriesContent (data) {
  return {
    type: ContentTypes.GET_SERIES_CONTENT_REQUEST,
    payload: data
  };
}

export function getDownloadContentId (data) {
  return {
    type: ContentTypes.GET_DOWNLOAD_CONTENT_ID_REQUEST,
    payload: data
  };
}

export function resetLoadingDownloadContent () {
  return {
    type: ContentTypes.RESET_LOADING_DOWNLOAD_CONTENT
  };
}

export function resetDownloadContentId () {
  return {
    type: ContentTypes.RESET_DOWNLOAD_CONTENT_ID
  };
}
