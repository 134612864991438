const _prefix = '@sort/';

export default {
  ADD_SORT_REQUEST: _prefix + 'ADD_SORT_REQUEST',
  UPDATE_SORT_REQUEST: _prefix + 'UPDATE_SORT_REQUEST',
  DELETE_SORT_REQUEST: _prefix + 'DELETE_SORT_REQUEST',
  GET_SORT_REQUEST: _prefix + 'GET_SORT_REQUEST',
  GET_SORT_SUCCESS: _prefix + 'GET_SORT_SUCCESS',

  GET_ALL_SORT_REQUEST: _prefix + 'GET_ALL_SORT_REQUEST',
  GET_ALL_SORT_SUCCESS: _prefix + 'GET_ALL_SORT_SUCCESS',

  RESET_SORT_REQUEST: _prefix + 'RESET_SORT_REQUEST',

  UPDATE_ORDINAL_SORT_REQUEST: _prefix + 'UPDATE_ORDINAL_SORT_REQUEST'
};
