
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useMedia } from 'react-use';
import { useSelector } from 'react-redux';
import { getBannerSetting } from 'state/search/searchSelector';
import { BANNER_TYPE } from 'configs/AppConfig';
const WIDTH_SLIDE = 948;

const CarouselHeader = (props) => {
  const { items, onChange, showDot = true, className } = props;
  const below768 = useMedia('(max-width: 768px)');
  const bannerSetting = useSelector(getBannerSetting) || {};
  const [positionIconArrow, setPositionIconArrow] = useState(10);
  const [bannerHeight, setBannerHeight] = useState(200);
  const [isResize, setIsResize] = useState(false);
  const { slideSpeed } = bannerSetting;
  const [selectedItem, setSelectedItem] = useState(0);
  const useStyle = makeStyles(() => (
    {
      carousel: {
        // height: '400px',
        '& .react-multi-carousel-dot': {
          '& button': {
            borderRadius: '50%!important'
          }
        },
        '& .react-multi-carousel-dot--active': {
          '& button': {
            background: '#148def',
            borderColor: '#148def'
          }
        },
        '& .slider-wrapper li ': {
          filter: below768 ? 'none' : 'blur(10px)',
          maxWidth: WIDTH_SLIDE,
          // minWidth: 50,
          height: '100%'
        },
        '& .slider-wrapper li.selected': {
          padding: 0,
          filter: 'none'
        }
      },
      item: {
        background: '#fff'
      },
      indicatorArrow: {
        display: 'inline-block',
        color: '#6c757d'
      },
      disabledIndicator: {
        opacity: 0.3,
        pointerEvents: 'none'
      },
      iconArrow: {
        height: below768 || bannerHeight <= 200 ? '40px' : '50px',
        width: below768 || bannerHeight <= 200 ? '40px' : '50px',
        borderRadius: '50%',
        fontSize: '1.5rem',
        padding: '0.5rem 1.16rem',
        background: '#00b27b',
        color: 'white',
        position: 'absolute',
        top: `calc(50% - ${below768 || bannerHeight <= 200 ? '20px' : '25px'})`,
        zIndex: 2,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          background: '#4dc9a3'
        }
      },
      iconPrev: {
        left: positionIconArrow
      },
      iconNext: {
        right: positionIconArrow
      }
    }
  ));
  const classes = useStyle();
  const CustomLeftArrow = (props) => (
    <span className={`${classes.iconArrow} ${classes.iconPrev}`} { ...props }>
      <i style={below768 || bannerHeight <= 200 ? { fontSize: 13 } : {}} className="fas fa-arrow-left"></i>
    </span>
  );

  const CustomRightArrow = (props) => (
    <span className={`${classes.iconArrow} ${classes.iconNext}`} { ...props }>
      <i style={below768 || bannerHeight <= 200 ? { fontSize: 13 } : {}} className="fas fa-arrow-right"></i>
    </span>
  );

  const getPercentWidthWindow = () => {
    if (window.innerWidth <= WIDTH_SLIDE + 100) {
      return 90;
    }
    const pt = (WIDTH_SLIDE * 100) / window.innerWidth;
    return Number.parseFloat(pt.toFixed(4));
  };

  const handleResizeScreen = () => {
    setIsResize(true);
    if (below768) {
      setPositionIconArrow(10);
    } else {
      // setPositionIconArrow(`calc(25% - ${bannerHeight <= 200 ? 20 : 25}px)`);
      const distance = window.innerWidth - WIDTH_SLIDE;
      if (distance <= 100) {
        if (distance <= 90) {
          setPositionIconArrow('25px');
        }
        if (distance < 0) {
          setPositionIconArrow('10px');
        }
      } else {
        setPositionIconArrow(`${((window.innerWidth - WIDTH_SLIDE) / 2) - 30}px`);
      }
    }
  };

  useEffect(() => {
    handleResizeScreen();
    window.addEventListener('resize', handleResizeScreen);
    return () => {
      window.removeEventListener('resize', handleResizeScreen);
    };
  }, []);

  useEffect(() => {
    handleResizeScreen();
  }, [bannerHeight, below768]);

  useEffect(() => {
    if (isResize) {
      setIsResize(false);
    }
  }, [isResize]);

  useEffect(() => {
    if (bannerSetting.typeBanner === BANNER_TYPE.SLIDE) {
      const bannerDesktop = bannerSetting.heightBanner ? bannerSetting.heightBanner : 329;
      const bannerMobile = bannerSetting.mobileBannerHeight ? bannerSetting.mobileBannerHeight : 256;
      setBannerHeight(below768 ? bannerMobile : bannerDesktop);
      return;
    }
    setBannerHeight(below768 ? bannerSetting.mobileBannerHeight : bannerSetting.heightBanner);
  }, [bannerSetting, below768]);

  return (
    <div className={`${classes.carousel} ${className}`}>
      {
        !isResize &&
        <Carousel
          selectedItem={(selectedItem || selectedItem === 0) ? selectedItem : 0}
          onChange={onChange}
          dynamicHeight={true}
          // preventMovementUntilSwipeScrollTolerance={true}
          additionalTransfrom={0}
          autoPlay={slideSpeed && slideSpeed !== 0}
          interval={slideSpeed ? slideSpeed * 1000 : undefined}
          slidesToSlide={1}
          renderArrowPrev={(onClick) => <CustomLeftArrow onClick={onClick} />}
          renderArrowNext={(onClick) => <CustomRightArrow onClick={onClick} />}
          showIndicators={showDot}
          infiniteLoop={true}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          centerMode={below768 ? undefined : true}
          centerSlidePercentage={below768 ? undefined : getPercentWidthWindow()}
          onClickItem={(e) => {
            setSelectedItem(e);
          }}
        >
          {
            items.map((item, index) => <div
              key={`carousel-${index}`}
              className={classes.item}
            >
              {item}
            </div>)
          }
        </Carousel>
      }
    </div>
  );
};
CarouselHeader.propTypes = {
  items: PropTypes.any,
  onChange: PropTypes.func,
  selectedItem: PropTypes.any,
  showDot: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.string
};

export default CarouselHeader;
