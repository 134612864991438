import { all, put, takeEvery } from 'redux-saga/effects';
import SubscriptionOrdinalTypes from './SubscriptionOrdinalTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/subscription-ordinal';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { PAGINATION } from 'configs/AppConfig';

function * getSubscriptionOrdinalPage () {
  yield takeEvery(SubscriptionOrdinalTypes.GET_SUBSCRIPTION_ORDINAL_PAGE_REQUEST, _getSubscriptionOrdinalPage);
}

function * _getSubscriptionOrdinalPage ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_SUBSCRIPTION_ORDINAL_PAGE,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: SubscriptionOrdinalTypes.GET_SUBSCRIPTION_ORDINAL_PAGE_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionOrdinalTypes.GET_SUBSCRIPTION_ORDINAL_PAGE_ERROR,
        payload: status
      });
    }
  });
}

function * getSubscriptionOrdinalDetail () {
  yield takeEvery(SubscriptionOrdinalTypes.GET_SUBSCRIPTION_ORDINAL_DETAIL_REQUEST, _getSubscriptionOrdinalDetail);
}

function * _getSubscriptionOrdinalDetail ({ payload }) {
  const api = {
    ...API.GET_SUBSCRIPTION_ORDINAL_DETAIL,
    url: API.GET_SUBSCRIPTION_ORDINAL_DETAIL.url + payload
  };
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: SubscriptionOrdinalTypes.GET_SUBSCRIPTION_ORDINAL_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: SubscriptionOrdinalTypes.GET_SUBSCRIPTION_ORDINAL_DETAIL_ERROR,
        payload: status
      });
    }
  });
}

function * addSubscriptionOrdinal () {
  yield takeEvery(SubscriptionOrdinalTypes.ADD_SUBSCRIPTION_ORDINAL_REQUEST, _addSubscriptionOrdinal);
}

function * _addSubscriptionOrdinal ({ payload }) {
  const request = snakecaseKeys({ ...payload });

  yield createRequest({
    api: {
      ...API.ADD_SUBSCRIPTION_ORDINAL,
      data: request.data
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SubscriptionOrdinalTypes.GET_SUBSCRIPTION_ORDINAL_PAGE_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    }
  });
}

export default function * subscriptionOrdinalSaga () {
  yield all([
    getSubscriptionOrdinalPage(),
    getSubscriptionOrdinalDetail(),
    addSubscriptionOrdinal()
  ]);
}
