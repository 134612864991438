import PaymentTypes from './PaymentTypes';

export function authorizeCharge (data) {
  return {
    type: PaymentTypes.AUTHORIZE_CHARGE_REQUEST,
    payload: data
  };
}
export function veritransCreateOrderReceive (data) {
  return {
    type: PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE,
    payload: data
  };
}
export function veritransConfirmOrder (data) {
  return {
    type: PaymentTypes.VERITRANS_CONFIRM_ORDER,
    payload: data
  };
}
export function veritransUpdateCard (data) {
  return {
    type: PaymentTypes.VERITRANS_UPDATE_CARD,
    payload: data
  };
}
export function gmoCharge (data) {
  return {
    type: PaymentTypes.GMO_CHARGE_REQUEST,
    payload: data
  };
}
export function gmoUpdateCard (data) {
  return {
    type: PaymentTypes.GMO_UPDATE_CARD_REQUEST,
    payload: data
  };
}

export function resetHtml () {
  return {
    type: PaymentTypes.RESET_HTML
  };
}

export function resetPaymentError () {
  return {
    type: PaymentTypes.RESET_PAYMENT_ERROR
  };
}

export function resetPaymentStatus () {
  return {
    type: PaymentTypes.RESET_PAYMENT_STATUS
  };
}

export function createOrderReceive (payload) {
  return {
    type: PaymentTypes.CREATE_ORDER_RECEIVE_REQUEST,
    payload: payload
  };
}

export function gakkenPaymentInfo (payload) {
  return {
    type: PaymentTypes.GAKKEN_PAYMENT_INFO_REQUEST,
    payload: payload
  };
}

export function confirmOrderReceive (payload) {
  return {
    type: PaymentTypes.CONFIRM_ORDER_RECEIVE_REQUEST,
    payload: payload
  };
}

export function resetGakkenPaymentInfo () {
  return {
    type: PaymentTypes.RESET_GAKKEN_PAYMENT_INFO
  };
}

export function setCacheCouponCode (payload) {
  return {
    type: PaymentTypes.SET_CACHE_COUPON_CODE,
    payload: payload
  };
}
export function setCacheSubscriptionId (payload) {
  return {
    type: PaymentTypes.SET_CACHE_SUBSCRIPTION_ID,
    payload: payload
  };
}
export function setCacheRedirectUrlCallback (payload) {
  return {
    type: PaymentTypes.SET_CACHE_REDIRECT_URL_CALLBACK,
    payload: payload
  };
}
export function resetCreateOrderReceive () {
  return {
    type: PaymentTypes.RESET_CREATE_ORDER_RECEIVE
  };
}
export function resetCreateOrderReceiveVeritrans () {
  return {
    type: PaymentTypes.RESET_CREATE_ORDER_RECEIVE_VERITRANS
  };
}
