import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SessionContext, ActionContext, RouterContext } from './MainContainer';
import { connect, useSelector } from 'react-redux';
import { AUTHEN_TYPES, LAYOUT_SETTING, OPTION_LOGIN, SETTING_LOGIN, STORAGE_KEYS, USER_CONFIG } from 'configs/AppConfig';
import { Redirect, useHistory } from 'react-router-dom';
import DefaultLayout from './DefaultLayout';
import {
  getIsLoadSetting,
  getLoginSetting,
  getLayoutViewPage,
  getAllowLoginByIpAddress
} from 'state/search/searchSelector';
import {
  getCompanyDomain, getPageMargin, getViewSettings
} from 'state/company/companySelector';
import {
  getAuthUser,
  isAuthenticated
} from 'state/session/sessionSelector';
import { Grid } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import find from 'lodash/find';
import Util from 'utils/Util';
import { NoMatchPage } from 'configs/routes';
import { useMedia } from 'react-use';
import StorageHelper from 'utils/StorageHelper';

const PublicRoute = props => {
  const {
    children,
    isLoadSetting,
    loginSetting,
    layoutViewPage,
    isAuthen,
    authUser,
    allowLoginByIpAddress,
    actions: {
      sessionActions: { login, setCallstartAppWithCustomAuth }
    },
    session: { user = {} }
  } = props;
  const history = useHistory();

  const viewSettings = useSelector(getViewSettings);
  const pageMargin = useSelector(getPageMargin);
  const isCallStartAppWithCustomAuth = useSelector((state) => state.session.isCallStartAppWithCustomAuth);
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const below768 = useMedia('(max-width: 768px)');
  const search = window.location.search;

  useEffect(() => {
    if (!isOpenType && (loginSetting || loginSetting === 0)) {
      if (!isAuthen && loginSetting === SETTING_LOGIN.LOGINREQUIRE.value) {
        history.push('/login');
      }
    }
  }, [isAuthen, loginSetting, isOpenType]);

  useEffect(() => {
    if (!isOpenType && ((loginSetting && loginSetting === 0) || (allowLoginByIpAddress && !isAuthen))) {
      doAutoIpLogin();
    }
  }, [allowLoginByIpAddress, loginSetting, isAuthen, isOpenType]);

  useEffect(() => {
    const appMobileAuthToken = StorageHelper.getLocalItem(STORAGE_KEYS.appMobileAuthToken);
    if (isCallStartAppWithCustomAuth && search.includes('?UserID=') && appMobileAuthToken && viewSettings.accountType === OPTION_LOGIN.GAKKEN_ID) {
      const request = {
        Token: appMobileAuthToken,
        CustomName: viewSettings.customName
      };
      window.BEObj.startAppWithCustomAuth(request);
      setCallstartAppWithCustomAuth(false);
    }
  }, [isCallStartAppWithCustomAuth, viewSettings, search]);

  const doAutoIpLogin = async () => {
    const userTrace = await Util.getUserTrace();
    const ip = userTrace.ip;
    login({
      ip: ip,
      loginType: AUTHEN_TYPES.AUTO_IP
    });
  };

  const userConfig = find(USER_CONFIG, { roleLevel: user.roleLevel });

  if (isAuthen && (authUser.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || authUser.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel || authUser.roleLevel === USER_CONFIG.SERVICE_ADMIN.roleLevel)) {
    return <NoMatchPage/>;
  }
  if (isLoadSetting) {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
      >
        <Spinner color="primary" style={{ marginRight: 20 }} /> <TranslateMessage id="progress.loading" />
      </Grid>);
  } else {
    if (loginSetting === SETTING_LOGIN.LOGINREQUIRE.value && !isAuthen) {
      return <Redirect to={'/login'} push />;
    } else if (layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT.id) {
      return <Redirect to={'/'} push />;
    } else {
      if (user.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || user.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel) {
        return <Redirect to={`/${userConfig.landingPage}`} push />;
      } else {
        return (
          <DefaultLayout useAppBar className={'bg-info'}>
            {
              layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id
                ? <div style={{ marginLeft: below768 ? '0px' : `${pageMargin.left}px`, marginRight: below768 ? '0px' : `${pageMargin.right}px` }}>
                  {children}
                </div>
                : children
            }
          </DefaultLayout>
        );
      }
    }
  }
};

PublicRoute.propTypes = {
  routes: PropTypes.array,
  actions: PropTypes.object.isRequired,
  location: PropTypes.object,
  session: PropTypes.object.isRequired,
  isLoadSetting: PropTypes.bool,
  loginSetting: PropTypes.any,
  domain: PropTypes.any,
  layoutViewPage: PropTypes.any,
  children: PropTypes.any,
  isAuthen: PropTypes.bool,
  authUser: PropTypes.object,
  allowLoginByIpAddress: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    isLoadSetting: getIsLoadSetting(state),
    loginSetting: getLoginSetting(state),
    domain: getCompanyDomain(state),
    isAuthen: isAuthenticated(state),
    layoutViewPage: getLayoutViewPage(state),
    authUser: getAuthUser(state),
    allowLoginByIpAddress: getAllowLoginByIpAddress(state)

  };
};

export default connect(mapStateToProps)(props => (
  <RouterContext.Consumer>
    {({ location }) => (
      <ActionContext.Consumer>
        {actions => (
          <SessionContext.Consumer>
            {session => <PublicRoute
              {...props}
              actions={actions}
              location={location}
              session={session}
            />}
          </SessionContext.Consumer>
        )}
      </ActionContext.Consumer>
    )}
  </RouterContext.Consumer>
));
