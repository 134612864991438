import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { ActionContext, RouterContext } from '../layout/MainContainer';
import { getEndUserCompanyDetail, getServicePath, getViewSettings } from 'state/company/companySelector';
import { getAuthUser, getIsMobileApp, isAuthenticated } from 'state/session/sessionSelector';

import { Box, makeStyles } from '@material-ui/core';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import { CONTENT_GROUP_TYPE, DOMAIN_TYPE, OPTION_LOGIN } from 'configs/AppConfig';
import classNames from 'classnames';
import HomeSort from './components/HomeSort';
import Drawer from '@material-ui/core/Drawer';
import { useMedia } from 'react-use';
import { ExitToApp, LocalLibrary, Person, VpnKey, Subscriptions } from '@material-ui/icons';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { useHistory, useParams } from 'react-router-dom';
import { getNewInformation } from 'state/search/searchSelector';
import { useIntl } from 'react-intl';

const HomeSidebar = props => {
  const {
    isOpenSidebar,
    setIsOpenSidebar,
    contentGroups,
    onSelectContentGroup,
    sort = {},
    changeSort,
    pageSize,
    isDisableSort,
    listSorts = [],
    isAuthen,
    sessionActions: { logout },
    setShowLoginFormModal,
    authUser,
    showSidebar,
    handleLoadContent,
    newInformation,
    isMobileApp
  } = props;

  const { contentGroupId, contentId } = useParams();
  const { formatMessage: f } = useIntl();
  const style = useSelector((state) => state.search.sidebarSetting) || {};
  const styleHeader = useSelector((state) => state.search.headerSetting) || {};
  const viewSettings = useSelector(getViewSettings);
  const servicePath = useSelector(getServicePath);
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;

  const { background, active, text, textActive, barBackground, barText } = style;
  const below768 = useMedia('(max-width: 768px)');
  const history = useHistory();
  const isHomeSearchPage = window.location.pathname.includes('home-search');
  const isFullDomain = viewSettings.domainType === DOMAIN_TYPE.FULL_DOMAIN;
  const redirectUrl = isFullDomain ? `${window.location.origin}/login` : `${window.location.origin}/${servicePath}/login`;

  const useStyle = makeStyles(() => (
    {
      sideBar: {
        minWidth: 200,
        width: below768 ? '66vw' : 200,
        // color: text || '#707070',
        // fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        background: background || '#fff',
        zIndex: 100
        // fontSize: below768 ? '12px' : '16px'
      },
      customCSS: {
        color: text || '#707070',
        fontWeight: 700,
        fontSize: below768 ? '12px' : '16px'
      },
      sideBarmobile: {
        minWidth: 200,
        width: '66vw',
        // height: 'calc(100vh - 80px)',
        height: 'calc(var(--vh, 1vh) * 100) -80',
        inset: '80px 0px 0px 0px!important',
        paddingBottom: 40,
        '& .MuiBackdrop-root': {
          height: 'calc(100vh - 80px)',
          top: '80px'
        }
      },
      groupSort: {
        background: barBackground || '#e2e2e2',
        color: barText || '#707070',
        padding: '0.5rem 1rem',
        width: '100%',
        border: '1px solid #fff'
      },
      newInformation: {
        background: barBackground || '#e2e2e2',
        color: barText || '#707070',
        padding: '0.5rem 1rem',
        width: '100%',
        border: '1px solid #fff'
      },
      sortOrder: {
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
        cursor: 'pointer',
        height: 'fit-content',
        minHeight: 36,
        '& div': {
          padding: '0.5rem 1rem',
          flex: '1 0 0px',
          display: 'flex',
          flexFlow: 'row wrap',
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        },
        '& .disabled': {
          cursor: 'default',
          color: '#cfccd6'
        },
        '& div:first-child': {
          borderRight: '1px solid #e2e2e2'
        },
        '& div:hover': {
          ['@media (min-width:619px)']: { // eslint-disable-line no-useless-computed-key
            background: active || '#1aa758',
            color: textActive || '#fff'
          }
        },
        '& div.disabled:hover': {
          ['@media (min-width:619px)']: { // eslint-disable-line no-useless-computed-key
            background: 'none',
            color: '#cfccd6'
          }
        }
      },
      sortGenres: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      },
      sortItem: {
        padding: '0.5rem 1rem',
        cursor: 'pointer',
        wordBreak: 'break-word',
        wordWrap: 'break-word'
      },
      sortItemWrapper: {
        borderBottom: '1px solid #e2e2e2',
        '&:hover': {
          ['@media (min-width:619px)']: { // eslint-disable-line no-useless-computed-key
            background: active || '#1aa758',
            color: textActive || '#fff'
          }
        }
      },
      iconOpen: {
        transform: 'rotate(180deg)'
      },
      hideSidebar: {
        transform: 'translateX(-500px)',
        transition: 'transform .3s ease-in-out',
        width: 0,
        minWidth: 'unset',
        position: 'fixed'
      },
      selected: {
        background: active || '#1aa758',
        color: textActive || '#fff'
      },
      disabled: {
        opacity: 0.6,
        pointerEvents: 'none'
      },
      sortSelectbox: {
        paddingRight: '1rem'
      },
      headerItem: {
        color: styleHeader.color || '#d0d0d0'
      },
      sortIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      textInfo: {
        // '& p:has(em)': {
        //   transform: 'skewX(-8deg)'
        // },
        // '& span:has(em)': {
        //   transform: 'skewX(-8deg)'
        // }
        // '& em': {
        //   color: '',
        //   fontWeight: '',
        //   fontSize: '',
        //   fontStyle: ''
        // }
      }
    }
  ));

  const registerGakkenUser = async () => {
    const gakkenUrl = `${process.env.REACT_APP_GAKKEN_LINK}/user-register-1?response_type=code&client_id=${viewSettings.gakkenServiceCode}&redirect_uri=${redirectUrl}`;
    window.location.replace(gakkenUrl);
  };

  const classes = useStyle();

  const specialContentGroup = contentGroups.filter(item => item.type !== CONTENT_GROUP_TYPE.NORMAL);
  const normalContentGroup = contentGroups.filter(item => item.type === CONTENT_GROUP_TYPE.NORMAL);

  const onSort = (sort, asc) => {
    const params = { sortField: sort, sort: asc };
    changeSort(params);
  };

  const handleLogout = () => {
    logout();
  };

  const renderInformation = () => {
    let newFormat;
    // eslint-disable-next-line no-unused-vars
    if (newInformation.includes('<ul>')) {
      newFormat = newInformation.replaceAll('<ul>', '<ul style="margin: 0; padding: 0px 0px 0px 20px">').replaceAll('&nbsp;', '').replaceAll('<li></li>', '');

      if (newFormat.includes('</li>')) {
        return (
          <React.Fragment>
            <div className={`${classes.newInformation} ${classes.customCSS}`}>
              <TranslateMessage id="title.newInformation" />
            </div>
            <div className={classes.textInfo} style={{ padding: '5px 5px 5px 7px' }}>
              <div className={classes.textInfo} style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: newFormat }} />
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <></>
        );
      }
    } else {
      // if (newInformation.includes('<p></p>')) {
      //   newFormat = newInformation.trim().replaceAll('&nbsp;', '').replaceAll('<p></p>', '').split('\n');
      // } else {
      newFormat = newInformation.trim().replaceAll('&nbsp;', '').replaceAll('<p></p>', '').split('\n');
      // }
      const isNewFormat = newFormat.filter(item => item !== '');
      if (isNewFormat.length === 0) {
        return (
          <></>
        );
      } else {
        return (
          <React.Fragment>
            <div className={`${classes.newInformation} ${classes.customCSS}`}>
              <TranslateMessage id="title.newInformation" />
            </div>
            <div style={{ padding: '5px 5px 5px 7px' }}>
              <ul>
                {newFormat.map((item, index) => (
                  item && item !== '' && <li key={index} className={classes.textInfo}>
                    <div className={classes.textInfo} style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: item }} />
                  </li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        );
      }
    }
  };

  const sidebarContent = () => (
    <React.Fragment>
      <React.Fragment>
        {
          listSorts.length > 0 && <React.Fragment>
            <div className={`${classes.groupSort} ${classes.customCSS}`}>
              <TranslateMessage id="title.sortOrder" />
            </div>
            {
              listSorts.length > 2
                ? <div className={`${classes.sortSelectbox} ${isDisableSort && classes.disabled} ${classes.customCSS}`}>
                  <HomeSort
                    listSortField={listSorts}
                    valueSort={sort.sortField}
                    isAsc={sort.sort}
                    onSort={onSort}
                    disabled={isDisableSort}
                  />
                </div>
                : <div className={`${classes.sortOrder} ${isDisableSort && classes.disabled} ${classes.customCSS}`}>
                  {
                    listSorts.map((sortItem, index) => {
                      const isDisabled = (isHomeSearchPage && sortItem.field === 12);
                      return (
                        <div key={`sort-item-${index}`} onClick={() => !isDisabled && onSort(sortItem.field, sort.sortField === sortItem.field ? !sort.sort : sortItem.isAcsDefault)} className={`${sort.sortField === sortItem.field && classes.selected} ${isDisabled ? 'disabled' : ''}`}>
                          <span>{sortItem.title}</span>
                          {
                            sort.sortField === sortItem.field && sort.sort === false && (
                              <i className={classNames(['fas fa-sort-down ml-1', classes.sortIcon])}></i>
                            )
                          }
                          {
                            sort.sortField === sortItem.field && sort.sort === true && (
                              <i className={classNames(['fas fa-sort-up ml-1', classes.sortIcon])}></i>
                            )
                          }
                        </div>
                      );
                    })
                  }
                </div>
            }
          </React.Fragment>
        }
        {
          specialContentGroup && (
            specialContentGroup.map(item => (
              <div
                key={`special-group-${item.id}`}
                className={classNames(
                  {
                    [classes.groupSort]: true,
                    [classes.sortItem]: true,
                    [classes.selected]: `${contentGroupId}` === `${item.id}` && !contentId,
                    [classes.customCSS]: true
                  }
                )}
                onClick={() => {
                  onSelectContentGroup(item.id, `${contentGroupId}` === `${item.id}` && !contentId);
                  const pageNum = item.page ? item.page : 1;
                  const numItems = contentGroupId
                    ? pageSize
                    : item.isWrapLine ? item.itemSize : item.numRow * item.itemSize;
                  handleLoadContent(item, pageNum, numItems);
                }}
              >
                {item.name}
              </div>
            ))
          )
        }
        <div className={`${classes.groupSort} ${classes.customCSS}`}>
          <TranslateMessage id="title.differentGenres" />
        </div>
        <div className={classes.sortGenres}>
          {
            normalContentGroup.map(item => {
              return (
                <div
                  key={`cg-${item.id}`}
                  className={`${classes.sortItemWrapper} ${`${contentGroupId}` === `${item.id}` && !contentId && classes.selected} ${classes.customCSS}`}
                  onClick={() => onSelectContentGroup(item.id, `${contentGroupId}` === `${item.id}` && !contentId)}
                >
                  <div className={classes.sortItem}>
                    {item.name}
                  </div>
                </div>
              );
            })
          }
        </div>
      </React.Fragment>
      {newInformation && renderInformation()}
    </React.Fragment>

  );

  return (
    <React.Fragment>
      {
        below768
          ? <Drawer
            anchor="left"
            open={isOpenSidebar}
            classes={{
              root: classNames(classes.sideBarmobile),
              paper: classNames(classes.sideBarmobile)
            }}
            onClose={() => setIsOpenSidebar(false)}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <Box display="flex" padding={'16px 6px'} justifyContent="space-around">
              <Box className={classes.headerItem}>
                <LocalLibrary onClick={() => history.push('/home-search')} />
              </Box>
              {
                isOpenType
                  ? null
                  : isAuthen
                    ? <React.Fragment>
                      {
                        authUser.roleLevel !== 4 && !isMobileApp && <Box className={classes.headerItem}>
                          <Person className="mr-2" onClick={() => history.push('/profile')} />
                        </Box>
                      }
                      {
                        !isMobileApp &&
                        <Box className={classes.headerItem}>
                          <Subscriptions onClick={() => history.push('/subscription')} />
                        </Box>
                      }
                      {
                        !isMobileApp &&
                        <Box className={classes.headerItem}>
                          <ExitToApp onClick={() => { handleLogout() }} />
                        </Box>
                      }
                    </React.Fragment>
                    : <>
                      <Box className={classes.headerItem}>
                        {
                          viewSettings.accountType === OPTION_LOGIN.GAKKEN_ID && viewSettings.isAutoRegisterGakkenAccountAtLogin &&
                          <div
                            className={classes.headerItem}
                            onClick={() => registerGakkenUser()}
                          >
                            <PersonAdd className="mr-2" titleAccess={f({ id: 'menu.registerUser' })}/>
                          </div>
                        }
                      </Box>
                      <Box className={classes.headerItem}>
                        <VpnKey onClick={() => {
                          setShowLoginFormModal(true);
                          setIsOpenSidebar(false);
                        }} />
                      </Box>
                    </>
              }
            </Box>
            {
              showSidebar
                ? <div className={`home-sidebar ${classes.sideBar} ${!isOpenSidebar && classes.hideSidebar}`}>
                  {sidebarContent()}
                </div >
                : null
            }
            {/* {
              <div className={`home-sidebar ${classes.sideBar}`}>
                {newInformation}
              </div>
            } */}
          </Drawer>
          : showSidebar
            ? <div className={`home-sidebar ${classes.sideBar} ${!isOpenSidebar && classes.hideSidebar}`}>
              {sidebarContent()}
            </div >
            : null
      }
    </React.Fragment>
  );
};

HomeSidebar.propTypes = {
  companyActions: PropTypes.object.isRequired,
  companyDetail: PropTypes.object,
  isOpenSidebar: PropTypes.bool,
  isDisableSort: PropTypes.bool,
  contentGroups: PropTypes.array,
  onSelectContentGroup: PropTypes.func,
  sort: PropTypes.object,
  changeSort: PropTypes.func,
  listSorts: PropTypes.any,
  setIsOpenSidebar: PropTypes.func,
  isAuthen: PropTypes.bool,
  sessionActions: PropTypes.object.isRequired,
  showSidebar: PropTypes.bool,
  authUser: PropTypes.object,
  setShowLoginFormModal: PropTypes.func,
  router: PropTypes.any,
  handleLoadContent: PropTypes.func,
  pageSize: PropTypes.number,
  signOut: PropTypes.func,
  newInformation: PropTypes.any,
  isMobileApp: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    companyDetail: getEndUserCompanyDetail(state),
    isAuthen: isAuthenticated(state),
    authUser: getAuthUser(state),
    newInformation: getNewInformation(state),
    isMobileApp: getIsMobileApp(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <RouterContext.Consumer>
      {router => (
        <ActionContext.Consumer>
          {({ companyActions, sessionActions }) => (
            <HomeSidebar
              {...props}
              router={router}
              companyActions={companyActions}
              sessionActions={sessionActions}
            />
          )}
        </ActionContext.Consumer>
      )}
    </RouterContext.Consumer>
  )
);
