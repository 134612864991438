import SubscriptionTypes from './SubscriptionTypes';

export function getSubscriptionPage (data) {
  return {
    type: SubscriptionTypes.GET_SUBSCRIPTION_PAGE_REQUEST,
    payload: data
  };
}

export function getSubscriptionList (data) {
  return {
    type: SubscriptionTypes.GET_SUBSCRIPTION_LIST_REQUEST,
    payload: data
  };
}

export function getSubscriptionDetail (data) {
  return {
    type: SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_REQUEST,
    payload: data
  };
}

export function addSubscription (data) {
  return {
    type: SubscriptionTypes.ADD_SUBSCRIPTION_REQUEST,
    payload: data
  };
}

export function getUserRegisterSubscriptionPage (data) {
  return {
    type: SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_PAGE_REQUEST,
    payload: data
  };
}

export function getUserRegisterSubscriptionDetail (data) {
  return {
    type: SubscriptionTypes.GET_USER_REGISTER_SUBSCRIPTION_DETAIL_REQUEST,
    payload: data
  };
}

export function cancelUserRegisterSubscription (data) {
  return {
    type: SubscriptionTypes.CANCEL_USER_REGISTER_SUBSCRIPTION_REQUEST,
    payload: data
  };
}

export function registerUserSubscription (data) {
  return {
    type: SubscriptionTypes.REGISTER_USER_SUBSCRIPTION_REQUEST,
    payload: data
  };
}

export function openSubscriptionPopUp () {
  return {
    type: SubscriptionTypes.OPEN_SUBSCRIPTION_POPUP
  };
}

export function closeSubscriptionPopUp () {
  return {
    type: SubscriptionTypes.CLOSE_SUBSCRIPTION_POPUP
  };
}
