import TransactionTypes from './TransactionTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  isLoading: true,
  transactionList: [],
  pagination: {
    ...PAGINATION,
    search_key: '',
    totalPages: 0,
    totalRecords: 0
  }
};

export default function transactionReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case TransactionTypes.GET_TRANSACTION_SUCCESS:
      newState.transactionList = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageable.pageSize;
      newState.pagination.page = payload.pageable.pageNumber + 1;
      newState.pagination = { ...state.pagination, ...newState.pagination };
      newState.isLoading = false;
      return newState;
    case TransactionTypes.GET_TRANSACTION_ERROR:
      newState.isLoading = false;
      return newState;
    case TransactionTypes.GET_TRANSACTION_REQUEST:
      newState.isLoading = true;
      return newState;
    default:
      newState = state;
      break;
  }

  // return new state
  return newState;
}
