import CompanyTypes from './CompanyTypes';

export function setCompanyDomain (payload) {
  return {
    type: CompanyTypes.SET_COMPANY_DOMAIN,
    payload: payload
  };
}

export function loadGakkenTerms (payload) {
  return {
    type: CompanyTypes.GET_GAKKEN_TERMS_REQUEST,
    payload: payload
  };
}

export function setServicePath (payload) {
  return {
    type: CompanyTypes.SET_SERVICE_PATH,
    payload: payload
  };
}

export function loadEndUserCompanyDetail (payload) {
  return {
    type: CompanyTypes.GET_END_USER_COMPANY_DETAIL_REQUEST,
    payload: payload
  };
}

export function loadSetting (payload) {
  return {
    type: CompanyTypes.GET_SETTING_REQUEST,
    payload: payload
  };
}

export function verifyPaymentVeritrans (payload) {
  return {
    type: CompanyTypes.VERIFY_PAYMENT_VERITRANS_REQUEST,
    payload: payload
  };
}

export function updatePaymentVeritrans (payload) {
  return {
    type: CompanyTypes.UPDATE_PAYMENT_VERITRANS_REQUEST,
    payload: payload
  };
}
