import SubscriptionOrdinalTypes from './SubscriptionOrdinalTypes';

export function getSubscriptionOrdinalPage (data) {
  return {
    type: SubscriptionOrdinalTypes.GET_SUBSCRIPTION_ORDINAL_PAGE_REQUEST,
    payload: data
  };
}

export function getSubscriptionOrdinalDetail (data) {
  return {
    type: SubscriptionOrdinalTypes.GET_SUBSCRIPTION_ORDINAL_DETAIL_REQUEST,
    payload: data
  };
}

export function addSubscriptionOrdinal (data) {
  return {
    type: SubscriptionOrdinalTypes.ADD_SUBSCRIPTION_ORDINAL_REQUEST,
    payload: data
  };
}
