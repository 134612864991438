import SortTypes from './SortTypes';

export function loadAllSort (data) {
  return {
    type: SortTypes.GET_ALL_SORT_REQUEST,
    payload: data
  };
}
export function addSort (data) {
  return {
    type: SortTypes.ADD_SORT_REQUEST,
    payload: data
  };
}
export function getSortDetail (data) {
  return {
    type: SortTypes.GET_SORT_REQUEST,
    payload: data
  };
}
export function updateSort (data) {
  return {
    type: SortTypes.UPDATE_SORT_REQUEST,
    payload: data
  };
}
export function deleteSort (data) {
  return {
    type: SortTypes.DELETE_SORT_REQUEST,
    payload: data
  };
}

export function updateOrdinalSort (data) {
  return {
    type: SortTypes.UPDATE_ORDINAL_SORT_REQUEST,
    payload: data
  };
}

export function resetSort (data) {
  return {
    type: SortTypes.RESET_SORT_REQUEST,
    payload: data
  };
}
