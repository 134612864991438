import { all, put, select, takeEvery } from 'redux-saga/effects';
import snakecaseKeys from 'snakecase-keys';
import createRequest from 'api/httpRequest';
import API from 'api/payment';
import PaymentTypes from './PaymentTypes';
import Util from 'utils/Util';
import NotificationTypes from 'state/notification/NotificationTypes';
import HomeTypes from 'state/home/HomeTypes';
import SubscriptionTypes from 'state/subscription/SubscriptionTypes';
import SessionTypes from 'state/session/SessionTypes';
import { getServicePath } from 'state/company/companySelector';
import ErrorTypes from 'state/handler/ErrorTypes';
// import { getAuthUser } from 'state/session/sessionSelector';

// const Multipayment = window.Multipayment;

function * authorizeCharge () {
  yield takeEvery(PaymentTypes.AUTHORIZE_CHARGE_REQUEST, _authorizeCharge);
}

function * _authorizeCharge ({ payload }) {
  const path = yield select(getServicePath);
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.AUTHORIZE_CHARGE,
      params: { ...request }

    },
    onSuccess: function * (response) {
      if (response.data) {
        yield put({
          type: PaymentTypes.AUTHORIZE_CHARGE_SUCCESS,
          payload: Util.toCamelCaseKey(response.data)
        });
      } else {
        yield put({
          type: NotificationTypes.SHOW_NOTIFICATION,
          payload: {
            config: {
              message: `api.register.${response.status}`,
              level: 'success',
              autoDismiss: 3,
              position: 'tc'
            }
          }
        });
        yield put({
          type: SessionTypes.GET_AUTH_INFO_REQUEST,
          payload: { path: path }
        });
        if (payload.data.updateContentGroup) {
          yield put({
            type: HomeTypes.GET_PUBLIC_CONTENT_GROUP_REQUEST
          });
        } else {
          yield put({
            type: SubscriptionTypes.GET_SUBSCRIPTION_LIST_REQUEST
          });
        }
      }
    },
    onError: function * () {
      yield put({
        type: PaymentTypes.AUTHORIZE_CHARGE_ERROR
      });
    }
  });
}

function * veritransCreateOrderReceive () {
  yield takeEvery(PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE, _veritransCreateOrderReceive);
}

function * _veritransCreateOrderReceive ({ payload }) {
  const request = {
    coupon_code: payload.couponCode,
    subscription_plan_id: payload.subscriptionPlanId,
    redirect_url: payload.redirectUrl,
    state: payload.state,
    card_holder_email: payload.cardHolderEmail,
    card_holder_name: payload.cardHolderName,
    customer_ip: payload.customerIp,
    token: payload.token
  };
  const path = yield select(getServicePath);
  yield createRequest({
    api: {
      ...API.VERITRANS_ORDER_RECEIVE,
      data: { ...request }
    },
    ignoreErrorMessage: true,
    onSuccess: function * ({ data }) {
      yield put({
        type: PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE_SUCCESS,
        payload: Util.toCamelCaseKey(data)
      });
      if (data === 'ok') {
        yield put({
          type: SessionTypes.GET_AUTH_INFO_REQUEST,
          payload: { path: path }
        });
      }
    },
    onError: function * (error, response) {
      yield put({
        type: PaymentTypes.VERITRANS_CREATE_ORDER_RECEIVE_ERROR
      });
      if (error.status === 400 && error.data === 'Token error') {
        yield put({ type: ErrorTypes.API_RESPONSE_ERROR, response: { ...response, changeMessage: 'invalidCardNumber' } });
      } else {
        yield put({ type: ErrorTypes.API_RESPONSE_ERROR, response: { ...response } });
      }
      // if (status === 201 && !window.location.origin.includes('admin')) {
      //   yield put(changeRoute('/'));
      // }
    }
  });
}

function * veritransConfirmOrder () {
  yield takeEvery(PaymentTypes.VERITRANS_CONFIRM_ORDER, _veritransConfirmOrder);
}

function * _veritransConfirmOrder ({ payload }) {
  const servicePath = yield select(getServicePath);
  yield createRequest({
    api: {
      ...API.VERITRANS_CONFIRM_ORDER,
      data: {
        order_id: payload.orderId,
        coupon_code: payload.couponCode
      }
    },
    onSuccess: function * (response) {
      if (response.status === 200) {
        yield put({
          type: SubscriptionTypes.GET_SUBSCRIPTION_PAGE_REQUEST,
          payload: {
            size: 100
          }
        });
        yield put({
          type: NotificationTypes.SHOW_NOTIFICATION,
          payload: {
            config: {
              message: `api.register.${response.status}`,
              level: 'success',
              autoDismiss: 3,
              position: 'tc'
            }
          }
        });
        yield put({
          type: PaymentTypes.VERITRANS_CONFIRM_ORDER_SUCCESS,
          payload: {}
        });
        yield put({
          type: SessionTypes.GET_AUTH_INFO_REQUEST,
          payload: { path: servicePath }
        });
      } else {
        yield put({
          type: PaymentTypes.VERITRANS_CONFIRM_ORDER_ERROR
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: PaymentTypes.VERITRANS_CONFIRM_ORDER_ERROR
      });
      // if (status === 201 && !window.location.origin.includes('admin')) {
      //   yield put(changeRoute('/'));
      // }
    }
  });
}

function * veritransUpdateCard () {
  yield takeEvery(PaymentTypes.VERITRANS_UPDATE_CARD, _veritransUpdateCard);
}

function * _veritransUpdateCard ({ payload }) {
  const path = yield select(getServicePath);
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.VERITRANS_UPDATE_CARD,
      params: { ...request }
    },
    ignoreErrorMessage: true,
    onSuccess: function * (response) {
      if (response.data) {
        yield put({
          type: PaymentTypes.VERITRANS_UPDATE_CARD_SUCCESS,
          payload: Util.toCamelCaseKey(response.data)
        });
        yield put({
          type: NotificationTypes.SHOW_NOTIFICATION,
          payload: {
            config: {
              message: `api.update.${response.status}`,
              level: 'success',
              autoDismiss: 3,
              position: 'tc'
            }
          }
        });
        yield put({
          type: SessionTypes.GET_AUTH_INFO_REQUEST,
          payload: { path: path }
        });
      }
    },
    onError: function * (error, response) {
      yield put({
        type: PaymentTypes.VERITRANS_UPDATE_CARD_ERROR
      });
      if (error.status === 400) {
        yield put({ type: ErrorTypes.API_RESPONSE_ERROR, response: { ...response, changeMessage: 'invalidCardNumber' } });
      } else {
        yield put({ type: ErrorTypes.API_RESPONSE_ERROR, response: { ...response } });
      }
    }
  });
}

function * gmoCharge () {
  yield takeEvery(PaymentTypes.GMO_CHARGE_REQUEST, _gmoCharge);
}

function * _gmoCharge ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GMO_CHARGE,
      params: { ...request }
    },
    onSuccess: function * (response) {
      if (response.status === 200) {
        yield put({
          type: PaymentTypes.GMO_CHARGE_SUCCESS,
          payload: {}
        });
        yield put({
          type: SubscriptionTypes.GET_SUBSCRIPTION_PAGE_REQUEST,
          payload: {
            size: 100
          }
        });
        yield put({
          type: NotificationTypes.SHOW_NOTIFICATION,
          payload: {
            config: {
              message: `api.register.${response.status}`,
              level: 'success',
              autoDismiss: 3,
              position: 'tc'
            }
          }
        });
        if (payload.token) {
          const servicePath = yield select(getServicePath);
          yield put({
            type: SessionTypes.GET_AUTH_INFO_REQUEST,
            payload: { path: servicePath }
          });
        }
      } else {
        yield put({
          type: PaymentTypes.GMO_CHARGE_ERROR
        });
      }
    },
    onError: function * () {
      yield put({
        type: PaymentTypes.GMO_CHARGE_ERROR
      });
    }
  });
}

function * gmoUpdateCard () {
  yield takeEvery(PaymentTypes.GMO_UPDATE_CARD_REQUEST, _gmoUpdateCard);
}

function * _gmoUpdateCard ({ payload }) {
  const path = yield select(getServicePath);
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GMO_UPDATE_CARD,
      params: { ...request }
    },
    onSuccess: function * (response) {
      if (response.data) {
        yield put({
          type: PaymentTypes.GMO_UPDATE_CARD_SUCCESS,
          payload: Util.toCamelCaseKey(response.data)
        });
        yield put({
          type: NotificationTypes.SHOW_NOTIFICATION,
          payload: {
            config: {
              message: `api.update.${response.status}`,
              level: 'success',
              autoDismiss: 3,
              position: 'tc'
            }
          }
        });
        yield put({
          type: SessionTypes.GET_AUTH_INFO_REQUEST,
          payload: { path: path }
        });
      }
    },
    onError: function * () {
      yield put({
        type: PaymentTypes.GMO_UPDATE_CARD_ERROR
      });
    }
  });
}

function * createOrderReceive () {
  yield takeEvery(PaymentTypes.CREATE_ORDER_RECEIVE_REQUEST, _createOrderReceive);
}

function * _createOrderReceive ({ payload }) {
  const request = {
    coupon_code: payload.couponCode,
    subscription_plan_id: payload.subscriptionPlanId,
    redirect_url: payload.redirectUrl,
    state: payload.state
  };
  yield createRequest({
    api: {
      ...API.CREATE_ORDER_BY_GAKKEN_ID,
      data: { ...request }
    },
    onSuccess: function * ({ data }) {
      yield put({
        type: PaymentTypes.CREATE_ORDER_RECEIVE_SUCCESS,
        payload: Util.toCamelCaseKey(data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: PaymentTypes.CREATE_ORDER_RECEIVE_ERROR
      });
      // if (status === 201 && !window.location.origin.includes('admin')) {
      //   yield put(changeRoute('/'));
      // }
    }
  });
}

function * gakkenPaymentInfo () {
  yield takeEvery(PaymentTypes.GAKKEN_PAYMENT_INFO_REQUEST, _gakkenPaymentInfo);
}

function * _gakkenPaymentInfo ({ payload }) {
  const request = {
    coupon_code: payload.couponCode,
    subscription_plan_id: payload.subscriptionPlanId,
    gid_order_no: payload.gidOrderNo
  };
  yield createRequest({
    api: {
      ...API.GAKKEN_PAYEMNT_INFO,
      params: { ...request }
    },
    onSuccess: function * ({ data }) {
      yield put({
        type: PaymentTypes.GAKKEN_PAYMENT_INFO_SUCCESS,
        payload: Util.toCamelCaseKey(data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: PaymentTypes.GAKKEN_PAYMENT_INFO_ERROR,
        payload: {}
      });
      // if (status === 201 && !window.location.origin.includes('admin')) {
      //   yield put(changeRoute('/'));
      // }
    }
  });
}

function * confirmOrderReceive () {
  yield takeEvery(PaymentTypes.CONFIRM_ORDER_RECEIVE_REQUEST, _confirmOrderReceive);
}

function * _confirmOrderReceive ({ payload }) {
  const servicePath = yield select(getServicePath);
  yield createRequest({
    api: {
      ...API.CONFIRM_ORDER_BY_GAKKEN_ID,
      data: {
        gid_order_no: payload.gidOrderNo,
        periodic_payment_id: payload.periodicPaymentId,
        coupon_code: payload.couponCode
      }
    },
    onSuccess: function * (response) {
      if (response.status === 200) {
        yield put({
          type: SubscriptionTypes.GET_SUBSCRIPTION_PAGE_REQUEST,
          payload: {
            size: 100
          }
        });
        yield put({
          type: NotificationTypes.SHOW_NOTIFICATION,
          payload: {
            config: {
              message: `api.register.${response.status}`,
              level: 'success',
              autoDismiss: 3,
              position: 'tc'
            }
          }
        });
        yield put({
          type: PaymentTypes.CONFIRM_ORDER_RECEIVE_SUCCESS,
          payload: {}
        });
        yield put({
          type: SessionTypes.GET_AUTH_INFO_REQUEST,
          payload: { path: servicePath }
        });
      } else {
        yield put({
          type: PaymentTypes.CONFIRM_ORDER_RECEIVE_ERROR
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: PaymentTypes.CONFIRM_ORDER_RECEIVE_ERROR
      });
      // if (status === 201 && !window.location.origin.includes('admin')) {
      //   yield put(changeRoute('/'));
      // }
    }
  });
}

export default function * paymentSaga () {
  yield all([
    authorizeCharge(),
    gmoUpdateCard(),
    gmoCharge(),
    createOrderReceive(),
    gakkenPaymentInfo(),
    confirmOrderReceive(),
    veritransUpdateCard(),
    veritransCreateOrderReceive(),
    veritransConfirmOrder()
  ]);
}
