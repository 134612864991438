import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { NoMatchPage } from 'configs/routes';

const LayoutWrapRoute = ({
  routes
}) => {
  return (
    <Switch>
      {routes.map(({ component: Component, exact, path, layout: Layout, ...rest }) => {
        return (
          <Route
            key={path}
            exact={exact}
            path={path}
            render={() => {
              return Layout
                ? <Layout>
                  <Component parentPath={path} {...rest} />
                </Layout>
                : <Component parentPath={path} {...rest} />
              ;
            }}
          />
        );
      })}
      <Route component={NoMatchPage} />
    </Switch>
  );
};

LayoutWrapRoute.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.any.isRequired,
      routes: PropTypes.array
    })
  ).isRequired
};
export default LayoutWrapRoute;
