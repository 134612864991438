export default {
  GET_DATA: {
    url: '/content/search',
    method: 'POST'
  },
  GET_PUBLIC_DATA: {
    url: '/content/public/search',
    method: 'POST'
  },
  GET_INFO: {
    url: '/content/public/detail',
    method: 'GET'
  },
  GET_SETTING: {
    url: '/service-admin/public/view-settings',
    method: 'GET'
  },
  SEARCH_FULL_TEXT: {
    url: '/content/public/full-text-search',
    method: 'POST'
  },
  SEARCH_FULL_TEXT_AUTHEN: {
    url: '/content/full-text-search',
    method: 'POST'
  }
};
