export default {
  GET_SUBSCRIPTION_ORDINAL_PAGE: {
    url: '/discount-ordinal/page',
    method: 'GET'
  },
  GET_SUBSCRIPTION_ORDINAL_DETAIL: {
    url: '/discount-ordinal/',
    method: 'GET'
  },
  ADD_SUBSCRIPTION_ORDINAL: {
    url: '/discount-ordinal/add',
    method: 'POST'
  },
  UPDATE_SUBSCRIPTION_ORDINAL: {
    url: '/discount-ordinal/',
    method: 'PUT'
  },
  DELETE_SUBSCRIPTION_ORDINAL: {
    url: '/discount-ordinal/',
    method: 'DELETE'
  }
};
