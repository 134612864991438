import NotificationsTypes from './NotificationsTypes';

export function getNotificationsDetail (data) {
  return {
    type: NotificationsTypes.GET_NOTIFICATIONS_DETAIL_REQUEST,
    payload: data
  };
}

export function getNotificationsPaging (data) {
  return {
    type: NotificationsTypes.GET_NOTIFICATIONS_PAGING_REQUEST,
    payload: data
  };
}

export function updateNotifications (data) {
  return {
    type: NotificationsTypes.UPDATE_NOTIFICATIONS_REQUEST,
    payload: data
  };
}
