import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const Style = {
  sortBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  sortIcon: {
    fontSize: '1.5rem',
    cursor: 'pointer',
    marginLeft: '0.5rem'
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: 'none'
  }
};

const HomeSort = props => {
  const {
    data,
    classes,
    onSort,
    name,
    valueSort,
    isAsc = false,
    disabled,
    listSortField
  } = props;

  const { formatMessage: f } = useIntl();
  const isHomeSearchPage = window.location.pathname.includes('home-search');

  const style = useSelector((state) => state.search.sidebarSetting) || {};
  const { background, active, text, textActive } = style;

  const [sort, setSort] = useState(null);

  useEffect(() => {
    if (valueSort) {
      const init = listSortField.find(option => option.field === valueSort);
      init && setSort({
        ...init,
        value: init.field,
        label: init.title
      });
    } else {
      setSort(null);
    }
  }, [data, valueSort, listSortField]);

  const handleSortOrder = () => {
    onSort && onSort(sort ? sort.value : null, !isAsc);
  };

  const changeSortValue = (selectedOption) => {
    setSort(selectedOption);
    onSort && onSort(selectedOption ? selectedOption.value : null, selectedOption.isAcsDefault);
  };

  return (
    <div className={classes.sortBox}>
      <Select
        id={name}
        value={sort}
        onChange={changeSortValue}
        placeholder={f({ id: 'label.sortBy' })}
        options={listSortField.map(item => {
          return {
            ...item,
            value: item.field,
            label: item.title,
            isDisabled: isHomeSearchPage && item.field === 12
          };
        })}
        className={classes.sortDropDown}
        isDisabled={disabled}
        styles={{
          container: base => ({
            ...base,
            width: '100%'
          }),
          control: base => ({
            ...base,
            border: 'none',
            boxShadow: 'none',
            backgroundColor: active || '#1aa758',
            '&:hover': {
              border: 'none',
              boxShadow: 'none'
            }
          }),
          option: (provided, { isSelected, isDisabled }) => ({
            ...provided,
            color: isDisabled && isHomeSearchPage ? '#cfccd6' : (isSelected ? `${textActive || '#fff'}!important` : `${text || '#707070'}!important`),
            backgroundColor: isSelected ? `${active || '#1aa758'}!important` : `${background || '#fff'}!important`,
            cursor: isDisabled && isHomeSearchPage ? 'default' : 'pointer',
            '&:hover': isDisabled && isHomeSearchPage ? {} : {
              color: `${textActive || '#fff'}!important`,
              backgroundColor: `${active || '#1aa758'}!important`
            }
          }),
          menuList: (base) => ({
            ...base,
            paddingBottom: 0,
            paddingTop: 0
          }),
          indicatorSeparator: () => ({
            display: 'none'
          }),
          indicatorsContainer: (base) => ({
            ...base,
            color: textActive || '#fff'
          }),
          singleValue: (base) => ({
            ...base,
            color: textActive || '#fff'
          }),
          valueContainer: (base) => ({
            ...base,
            paddingLeft: '1rem'
          }),
          placeholder: () => ({
            color: textActive || '#fff'
          })
        }}
      />
      {
        isAsc
          ? <span className={`${classes.sortIcon} ${!valueSort || disabled ? classes.disabled : ''}`} onClick={() => handleSortOrder()}>
            <i className="fas fa-sort-amount-up"></i>
          </span>
          : <span className={`${classes.sortIcon} ${!valueSort || disabled ? classes.disabled : ''}`} onClick={() => handleSortOrder()}>
            <i className="fas fa-sort-amount-down"></i>
          </span>
      }
    </div>
  );
};

HomeSort.defaultProps = {
  data: '1,2,3,4',
  onSort: null
};

HomeSort.propTypes = {
  data: PropTypes.string,
  onSort: PropTypes.func,
  classes: PropTypes.any,
  name: PropTypes.any,
  valueSort: PropTypes.any,
  isAsc: PropTypes.bool,
  disabled: PropTypes.bool,
  listSortField: PropTypes.array
};

export default withStyles(Style)(props => (<HomeSort {...props} />));
