export const getPublicContentGroups = state => state.home.contentGroups;
export const getContentGroupByContentId = state => state.home.contentGroupByContentId;
export const getShowFavorite = state => state.home.showFavorite;
export const getShowHomeSearch = state => state.home.showHomeSearch;
export const getSearchRequest = state => state.home.searchRequest;
export const getFullTextSearchRequest = state => state.home.fullTextSearchRequest;
export const getFulltextSearch = state => state.home.fulltextSearch;
export const getIsClearData = state => state.home.isClearData;
export const getCacheSearchRequest = state => state.home.cacheSearchRequest;
export const getListDataNextPage = state => state.home.dataNextPage;
export const getListDataPrePage = state => state.home.dataPrevPage;
export const getSortOrder = state => state.home.sortOrder;

// export const getShowTrending = state => state.home.showTrending;
