import React from 'react';
import { takeEvery, select } from 'redux-saga/effects';
import TranslateMessage from '../../components/common/TranslateMessage/TranslateMessage';
import NotificationTypes from './NotificationTypes';
import { DEFAULT_NOTIFICATION_CONFIG } from '../../configs/AppConfig';
import { getIntlProvider } from '../locale/localeSelector';
import { getNotificationProvider } from './notificationSelector';
import AppStyle from '../../assets/styles/AppStyle';

export default function * notificationSaga () {
  yield takeEvery(NotificationTypes.SHOW_NOTIFICATION, showNotification);
}

function * showNotification ({ payload: { config } }) {
  const notification = yield select(getNotificationProvider);
  const _config = Object.assign({}, DEFAULT_NOTIFICATION_CONFIG, config);

  if (!_config.children) {
    let _icon;
    switch (_config.level) {
      case 'success':
        _icon = <i className="fa fa-2x fa-check" />;
        break;
      case 'warning':
        _icon = <i className="fa fa-2x fa-exclamation-triangle" />;
        break;
      case 'error':
        _icon = <i className="fa fa-2x fa-exclamation-circle" />;
        break;
      case 'info':
        _icon = <i className="fa fa-2x fa-info-circle" />;
        break;
      default:
        _icon = '';
    }

    _config.children = (
      <p style={{ marginBottom: 0 }}>
        {_icon}
        <strong>
          <TranslateMessage
            style={{ marginLeft: AppStyle.page.spacing }}
            id={_config.message}
          />
        </strong>
      </p>
    );

    delete _config.message;
  } else {
    _config.message = yield translate(_config.message);
  }

  notification && notification.addNotification(_config);
}

export function * translate (id) {
  if (!id) {
    throw new Error('Missing message id to translate');
  }

  const intl = yield select(getIntlProvider);
  return intl ? yield intl.formatMessage({ id: id, defaultMessage: id }) : id;
}
