import AccountTypes from './AccountTypes';

export function loadData (queries) {
  return {
    type: AccountTypes.GET_ACCOUNT_SETTING_REQUEST,
    payload: queries
  };
}

export function loadDataError (tableName) {
  return {
    type: AccountTypes.GET_ACCOUNT_SETTING_ERROR,
    payload: tableName
  };
}

export function updateUserInfo (data) {
  return {
    type: AccountTypes.UPDATE_ACCOUNT_SETTING_REQUEST,
    payload: data
  };
}

export function updateUserNotiPrefer (data) {
  return {
    type: AccountTypes.UPDATE_USER_NOTI_PREFER_REQUEST,
    payload: data
  };
}

export function resetUserPassword (data) {
  return {
    type: AccountTypes.RESET_USER_PASSWORD_REQUEST,
    payload: data
  };
}

export function changeUserPassword (data) {
  return {
    type: AccountTypes.CHANGE_PASSWORD_REQUEST,
    payload: data
  };
}

export function forgotPassword (data) {
  return {
    type: AccountTypes.FORGOT_PASSWORD_REQUEST,
    payload: data
  };
}

export function changeUserEmail (data) {
  return {
    type: AccountTypes.CHANGE_USER_EMAIL_REQUEST,
    payload: data
  };
}

export function cancelAccount (data) {
  return {
    type: AccountTypes.CANCEL_ACCOUNT_USER_REQUEST,
    payload: data
  };
}
