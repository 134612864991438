import NotificationsTypes from './NotificationsTypes';
import { PAGINATION } from '../../configs/AppConfig';

const initialState = {
  notificationsList: [],
  pagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0
  },
  isLoading: false,
  notificationsDetail: null
};

export default function notificationsReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case NotificationsTypes.GET_NOTIFICATIONS_PAGING_SUCCESS:
      newState.notificationsList = [...payload.content];
      newState.pagination.totalRecords = payload.totalElements;
      newState.pagination.totalPages = payload.totalPages;
      newState.pagination.size = payload.pageSize ? payload.pageSize : payload.pageable.pageSize;
      newState.pagination.page = payload.pageNumber ? payload.pageNumber : payload.pageable.pageNumber + 1;
      newState.pagination = { ...newState.pagination };
      return newState;
    case NotificationsTypes.GET_NOTIFICATIONS_DETAIL_SUCCESS:
      newState.notificationsDetail = { ...payload };
      return newState;
    default:
      newState = state;
      break;
  }

  // return new state
  return newState;
}
