const _prefix = '@content/';

export default {
  GET_CONTENT_DATA_REQUEST: _prefix + 'GET_CONTENT_DATA_REQUEST',
  GET_CONTENT_DATA_SUCCESS: _prefix + 'GET_CONTENT_DATA_SUCCESS',
  GET_CONTENT_DATA_ERROR: _prefix + 'GET_CONTENT_DATA_ERROR',

  ADD_CONTENT_REQUEST: _prefix + 'ADD_CONTENT_REQUEST',
  UPDATE_CONTENT_REQUEST: _prefix + 'UPDATE_CONTENT_REQUEST',
  DELETE_CONTENT_REQUEST: _prefix + 'DELETE_CONTENT_REQUEST',

  GET_CONTENT_DETAIL_REQUEST: _prefix + 'GET_CONTENT_DETAIL_REQUEST',
  GET_CONTENT_DETAIL_SUCCESS: _prefix + 'GET_CONTENT_DETAIL_SUCCESS',
  GET_CONTENT_DETAIL_ERROR: _prefix + 'GET_CONTENT_DETAIL_ERROR',

  UPDATE_PAGINATION: _prefix + 'UPDATE_PAGINATION',

  EXPORT_DATA_REQUEST: _prefix + 'EXPORT_DATA_REQUEST',
  UPLOAD_CONTENT_REQUEST: _prefix + 'UPLOAD_CONTENT_REQUEST',
  SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
  SUCCESS_UPLOAD_CONTENT: _prefix + 'SUCCESS_UPLOAD_CONTENT',
  FAILURE_UPLOAD_CONTENT: _prefix + 'FAILURE_UPLOAD_CONTENT',

  GET_VIEWER_TOKEN: _prefix + 'GET_VIEWER_TOKEN',
  GET_VIEWER_TOKEN_SUCCESS: _prefix + 'GET_VIEWER_TOKEN_SUCCESS',
  GET_VIEWER_TOKEN_ERROR: _prefix + 'GET_VIEWER_TOKEN_ERROR',

  GET_VIEWER_PUBLIC_TOKEN: _prefix + 'GET_VIEWER_PUBLIC_TOKEN',
  GET_VIEWER_PUBLIC_TOKEN_SUCCESS: _prefix + 'GET_VIEWER_PUBLIC_TOKEN_SUCCESS',
  GET_VIEWER_PUBLIC_TOKEN_ERROR: _prefix + 'GET_VIEWER_PUBLIC_TOKEN_ERROR',

  CLOSE_UPLOAD_PROGRESS: _prefix + 'CLOSE_UPLOAD_PROGRESS',
  EXPORT_CONTENTS_REQUEST: _prefix + 'EXPORT_CONTENTS_REQUEST',

  ADD_FAVORITE_CONTENT: _prefix + 'ADD_FAVORITE_CONTENT',
  REMOVE_FAVORITE_CONTENT: _prefix + 'REMOVE_FAVORITE_CONTENT',

  ADD_TRENDING_CONTENT: _prefix + 'ADD_TRENDING_CONTENT',
  REMOVE_TRENDING_CONTENT: _prefix + 'REMOVE_TRENDING_CONTENT',

  GET_SERIES_CONTENT_REQUEST: _prefix + 'GET_SERIES_CONTENT_REQUEST',
  GET_SERIES_CONTENT_SUCCESS: _prefix + 'GET_SERIES_CONTENT_SUCCESS',
  GET_SERIES_CONTENT_ERROR: _prefix + 'GET_SERIES_CONTENT_ERROR',

  GET_DOWNLOAD_CONTENT_ID_REQUEST: _prefix + 'GET_DOWNLOAD_CONTENT_ID_REQUEST',
  GET_DOWNLOAD_CONTENT_ID_SUCCESS: _prefix + 'GET_DOWNLOAD_CONTENT_ID_SUCCESS',
  GET_DOWNLOAD_CONTENT_ID_ERROR: _prefix + 'GET_DOWNLOAD_CONTENT_ID_ERROR',
  RESET_DOWNLOAD_CONTENT_ID: _prefix + 'RESET_DOWNLOAD_CONTENT_ID',
  RESET_LOADING_DOWNLOAD_CONTENT: _prefix + 'RESET_LOADING_DOWNLOAD_CONTENT'

};
