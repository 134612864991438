
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getContentDetailParams, getContentInfo, getIsContentInfoLoad, getLayoutViewPage, getLoginSetting, isLoadContentDetailError } from 'state/search/searchSelector';
import {
  getIsListLoad,
  getSubscriptionList
} from 'state/subscription/SubscriptionSelector';
import PropTypes from 'prop-types';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { useIntl } from 'react-intl';
import { AUTHEN_TYPES, BUTTON_ACTION, CONTENT_DETAIL_LAYOUT, CONTENT_GROUP_TYPE, DOMAIN_TYPE, LAYOUT_SETTING, OPTION_LOGIN, PARAM_SERIES_CONTENT, PATTERNS, POSITION_CONFIG, POSITION_FAVORITE_BUTTON, SEARCH_FIELD, SETTING_LOGIN, STORAGE_KEYS, USER_CONFIG } from 'configs/AppConfig';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Col, Row, Spinner } from 'reactstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Util from 'utils/Util';
import { Box, Chip, Grid, TextField } from '@material-ui/core';
import { getIsMobileApp, isAuthenticated } from 'state/session/sessionSelector';
import { ActionContext } from 'components/layout/MainContainer';
import { useParams, useHistory } from 'react-router-dom';
import ModalConfirm from 'components/common/Modal/ModalConfirm';
import LoginModal from 'components/modal/LoginModal';
import LoginEndUserForm from './form/LoginEndUserForm';
import { getCompanyDomain, getServicePath, getViewSettings } from 'state/company/companySelector';
import md5 from 'md5';
import SubscriptionModal from './SubscriptionModal';
import StorageHelper from 'utils/StorageHelper';
import { useMedia } from 'react-use';
import SeriesContent from './SeriesContent';

const DetailContentPage = props => {
  const {
    contentInfo = {},
    isLoaded,
    contentSetting,
    onSelectFavorite,
    isLoggedIn,
    showFavorite,
    domain,
    isError,
    isLoggedContentDirect,
    layoutViewPage,
    subscriptionActions: {
      getSubscriptionList: fetchSubscriptionList
    },
    searchActions: {
      loadDetail
    },
    sessionActions: {
      login, getAuthInfo
    },
    paymentActions: {
      resetPaymentStatus,
      // gakkenPaymentInfo,
      // confirmOrderReceive,
      resetGakkenPaymentInfo,
      resetCreateOrderReceive
      // setCacheRedirectUrlCallback
    },
    contentActions: {
      getDownloadContentId,
      resetLoadingDownloadContent
    }
  } = props;
  const { contentId, contentGroupId, contentToken } = useParams();
  const history = useHistory();
  const imageContentRef = React.useRef();
  const below768 = useMedia('(max-width: 768px)');

  const [sortFields, setSortFields] = useState([]);
  const [longFields, setLongFields] = useState([]);
  const [widthHeightIcon, setWidthHeightIcon] = useState(28);

  const [isShowSubscription, setIsShowSubscription] = useState(false);
  const [showConfirmViewer, setShowConfirmViewer] = useState(false);
  const [showLoginFormModal, setShowLoginFormModal] = useState(false);
  const [isShowViewBook, setIsShowViewBook] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  // const [isFullSlotParam, setIsFullSlotParam] = useState(true);
  // const [showInfo, setShowInfo] = useState(false);
  // const [cacheGakkenPayment, setCacheGakkenPayment] = useState({});

  const [contentDetailId, setContentDetailId] = useState('');
  const [url, setUrl] = useState('');

  const { formatMessage: f } = useIntl();

  const isBEOStaging = !process.env.REACT_APP_IS_BEO_STAGING
    ? process.env.REACT_APP_IS_STAGING_SERVER : (process.env.REACT_APP_IS_BEO_STAGING === 'true');

  const style = useSelector((state) => state.search.contentDetailSetting) || {};
  const showSubscriptionStatus = useSelector((state) => state.company.showSubscriptionStatus);
  const isPaymentSuccess = useSelector((state) => state.payment.isPaymentSuccess);
  const isPaymentError = useSelector((state) => state.payment.isPaymentError);
  const isMobileApp = useSelector(getIsMobileApp);
  const viewSettings = useSelector(getViewSettings);
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const isNolayout = layoutViewPage === LAYOUT_SETTING.NO_LAYOUT.id;
  const isFullDomain = viewSettings.domainType === DOMAIN_TYPE.FULL_DOMAIN;
  const servicePath = useSelector(getServicePath);
  const downloadContentIdApp = useSelector((state) => state.content.downloadContentObj);
  const isGettingDownloadContentIdApp = useSelector((state) => state.content.isLoading);
  // const redirectUrlGakkenPayment = window.location.href;

  const urlGakkenPayment = useSelector((state) => state.payment.redirectUrl);
  const isLoadingGakkenInfo = useSelector((state) => state.payment.isLoadingGakkenInfo);
  const isCreateOrderReceive = useSelector((state) => state.payment.isCreateOrderReceive);
  // const isGakkenInfo = useSelector((state) => state.payment.isGakkenInfo);
  const isRediectUrl = useSelector((state) => state.payment.isRediectUrl);
  const positionFavourite = viewSettings.contentSearchResultSettingResponse ? (viewSettings.contentSearchResultSettingResponse.buttonFavouritePosition ? viewSettings.contentSearchResultSettingResponse.buttonFavouritePosition : POSITION_FAVORITE_BUTTON.IMAGE) : POSITION_FAVORITE_BUTTON.IMAGE;

  const loginSetting = useSelector(getLoginSetting);
  const isRequireLogin = loginSetting === SETTING_LOGIN.LOGINREQUIRE.value;
  const isLayout3 = layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id;
  const isFavouriteInfo = positionFavourite === POSITION_FAVORITE_BUTTON.INFORMATION;
  const viewContentInfo = contentInfo.view === true || contentInfo.view === false ? contentInfo.view : true;
  // const queryURL = new URLSearchParams(search);

  const styleButtonViewBook = style.buttons && style.buttons.length > 0 ? style.buttons.filter(item => item.actionType === BUTTON_ACTION.VIEW_BOOK.id)[0] : {};

  const allowViewBook = isLoggedIn || isOpenType || isNolayout;
  // const widthButton = viewSettings.contentSearchResultSettingResponse
  //   ? (viewSettings.contentSearchResultSettingResponse.width ? viewSettings.contentSearchResultSettingResponse.width : '')
  //   : '';

  const urlViewer = url + '/view-online/' + (contentInfo.id || contentDetailId) + `${contentGroupId ? '/' + contentGroupId : ''}`;
  const useStyle = makeStyles(() => (
    {
      itemMax: {
        maxWidth: 300,
        minWidth: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block'
      },
      coverCard: {
        display: 'flex',
        overflowX: 'auto',
        paddingBottom: '10px',
        color: 'black'
      },
      itemCard: {
        width: '300px',
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.125)',
        borderRadius: '0.25rem'
      },
      noImage: {
        marginBottom: '0.5rem',
        marginRight: '0.5rem',
        position: 'relative',
        overflow: 'hidden',
        '& img': {
          maxHeight: 300,
          maxWidth: 300,
          objectFit: 'contain',
          minWidth: 180,
          border: '1px solid #d0d0d0'
        }
      },
      noImageLayout3: {
        marginBottom: '0.5rem',
        // marginRight: '4.5rem',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 550,
        // width: '90%',
        height: '100%',
        '& img': {
          // width: '100%',
          // maxHeight: '100%',
          maxHeight: 300,
          maxWidth: 300,
          minWidth: 180,
          objectFit: 'contain',
          border: '1px solid #d0d0d0'
        },
        '& .favoriteIcon': {
          top: 5,
          right: below768 ? 5 : 0
        }
      },
      favoriteIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: '#F08828',
        margin: !isLayout3 ? '4px 0.5rem' : (below768 ? '' : '0 5px 4px 5px'),
        fontSize: '1rem',
        background: '#fff',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)'
        },
        '& .wrap-icon': {
          background: '#fff',
          borderRadius: '50%',
          border: '1.5px solid #999999',
          width: isFavouriteInfo ? 28 : widthHeightIcon,
          height: isFavouriteInfo ? 28 : widthHeightIcon,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      favoriteIcon3: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: '#fff',
        margin: !isLayout3 ? '4px 0.5rem' : (below768 ? '' : '0 0.5rem 4px 0.5rem'),
        fontSize: '1rem',
        background: '#fff',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)'
        },
        '& .wrap-icon': {
          background: '#00B27B',
          borderRadius: '50%',
          border: '1.5px solid #999999',
          width: isFavouriteInfo ? 34 : widthHeightIcon,
          height: isFavouriteInfo ? 34 : widthHeightIcon,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      noFavoriteIcon3: {
        position: 'absolute',
        top: 0,
        right: 0,
        // color: '#F08828',
        margin: !isLayout3 ? '4px 0.5rem' : (below768 ? '' : '0 0.5rem 4px 0.5rem'),
        fontSize: '1rem',
        background: '#fff',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)'
        },
        '& .wrap-icon': {
          background: '#fff',
          borderRadius: '50%',
          border: '1.5px solid #999999',
          width: isFavouriteInfo ? 34 : widthHeightIcon,
          height: isFavouriteInfo ? 34 : widthHeightIcon,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      wrapFavoriteIconLayout3: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      },
      favoriteIconLayout3: {
        color: '#fff',
        margin: '4px 0.5rem',
        fontSize: '1rem',
        background: '#fff',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)'
        },
        '& .wrap-icon': {
          background: '#00B27B',
          borderRadius: '50%',
          border: '1.5px solid #999999',
          width: isFavouriteInfo ? 34 : widthHeightIcon,
          height: isFavouriteInfo ? 34 : widthHeightIcon,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      noFavoriteIconLayout3: {
        // color: '#F08828',
        margin: '4px 0.5rem',
        fontSize: '1rem',
        background: '#fff',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)'
        },
        '& .wrap-icon': {
          background: '#fff',
          borderRadius: '50%',
          border: '1.5px solid #999999',
          width: isFavouriteInfo ? 34 : widthHeightIcon,
          height: isFavouriteInfo ? 34 : widthHeightIcon,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      registered: {
        position: 'absolute',
        top: 4,
        margin: '0 0.5rem',
        fontSize: '1.5rem',
        height: widthHeightIcon,
        width: widthHeightIcon,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
      },
      bold: {
        fontWeight: 700
      },
      buttonView: {
        color: '#ffffff',
        fontWeight: 700,
        marginTop: '1rem'
      },
      iconButton: {
        marginRight: '0.5rem'
      },
      skeletonItemImage: {
        width: '100%',
        height: '100%',
        marginBottom: '0.5rem'
      },
      spin: {
        display: 'flex',
        justifyContent: 'center'
      },
      skeletonItemButton: {
        width: '115px!important',
        height: '45px'
      },
      modalStyle: {
        padding: 20,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        color: style.color || '',
        fontSize: style.fontSize || '',
        border: style.borderColor ? `1px solid ${style.borderColor}` : '',
        backgroundColor: `${style.backgroundColor || 'transparent'}!important`,
        '& div': {
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        },
        minHeight: '100%'
      },
      smallFieldBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'start',
        position: 'relative'
      },
      btnViewBook: {
        // fontSize: below768 ? '12px' : '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '50px',
        maxWidth: '100%',
        width: below768 ? '100%' : styleButtonViewBook.width ? styleButtonViewBook.width : (below768 ? '100%' : ''),
        border: '2px solid',
        boxShadow: 'none!important',
        backgroundColor: styleButtonViewBook.backgroundColor || '#e26042',
        color: styleButtonViewBook.color || '#fff',
        borderColor: styleButtonViewBook.borderColor || 'transparent',
        height: below768 ? 'auto' : styleButtonViewBook.height || 'auto',
        paddingTop: styleButtonViewBook.height ? 0 : 6,
        paddingBottom: styleButtonViewBook.height ? 0 : 6,
        '&:hover, &:focus, &:active': {
          backgroundColor: `${styleButtonViewBook.hoverBackgroundColor || styleButtonViewBook.backgroundColor || '#e26042a8'}!important`,
          color: `${styleButtonViewBook.hoverColor || styleButtonViewBook.color || '#fff'}!important`,
          borderColor: `${styleButtonViewBook.hoverBorderColor || styleButtonViewBook.borderColor || 'transparent'}!important`
        }
      },
      contentOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
        padding: '4px'
      },
      noData: {
        textAlign: 'center',
        padding: '1rem',
        flex: '1 0 100%'
      },
      chip: {
        background: '#fff',
        backgroundColor: 'white',
        height: '28px',
        color: '#00b27b',
        fontSize: '12px',
        fontWeight: 'bold',
        border: '1px solid #d0d0d0'
      },
      wrapFlexCenter: {
        display: 'flex',
        alignItems: 'center'
      },
      contentImage: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: below768 ? '0 10%' : ' 0 4rem 0 0',
        marginBottom: below768 && '25px'
      },
      contentInfo: {
        width: '100%',
        paddingLeft: '3rem',
        ['@media (max-width:960px)']: { // eslint-disable-line no-useless-computed-key
          paddingLeft: 0
        }
      },
      field0: {
        fontSize: 18,
        fontWeight: 700,
        color: '#585757'
      },
      field1: {
        fontSize: 13,
        fontWeight: 400,
        color: '#585757'
      },
      field2: {
        fontSize: 13,
        fontWeight: 400,
        color: '#999999'
      },
      btnLoading: {
        opacity: 0.5
      }
    }
  ));

  const classes = useStyle();
  useEffect(() => {
    const protocol = window.location.protocol;
    const hash = window.location.hash !== '' ? '#' : '';
    const port = window.location.port;
    const path = isFullDomain
      ? ''
      : '/' + servicePath;
    if (port) {
      setUrl(protocol + '//' + domain + ':' + port + path + hash);
    } else {
      setUrl(protocol + '//' + domain + path + hash);
    }
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    if (isLoggedContentDirect) {
      getAuthInfo();
    }
  }, [isLoggedContentDirect]);
  useEffect(() => {
    if (contentGroupId && (contentGroupId !== 'home-search' && contentGroupId !== 'search' &&
    contentGroupId !== 'user' && contentGroupId !== 'profile' &&
    contentGroupId !== 'subscription' && contentGroupId !== 'view-online' && contentGroupId !== 'content' && contentGroupId !== 'gakken-payment' && contentGroupId !== 'thank-you' && contentGroupId !== 'order-entry')) {
      const validate = /[a-z]/g;
      const format = validate.exec(contentGroupId.toString());
      if (format) {
        history.push('/');
      }
    }
  }, [contentGroupId]);

  useEffect(() => {
    if (contentId) {
      const regex = PATTERNS.ELEMENT_ID;
      const validId = regex.test(contentId.toString());
      if (!validId) {
        if (isNolayout) {
          setTimeout(() => {
            history && history.push('/404');
          }, 200);
        } else {
          history.push('/');
        }
      }
    }
    if (contentId && !contentGroupId && (allowViewBook || layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id || layoutViewPage === LAYOUT_SETTING.SEARCH_LAYOUT.id)) {
      handleLoadDetail({ contentId });
    }
  }, [contentId, allowViewBook]);

  useEffect(() => {
    if (isLoggedIn) {
      if (isShowViewBook && contentDetailId) {
        const windowReference = window.open(urlViewer);
        if (!windowReference) {
          setShowConfirmViewer(true);
        }
        setIsShowViewBook(false);
      }
    } else {
      setIsShowViewBook(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const cacheStorage = StorageHelper.getSessionObject(STORAGE_KEYS.cacheStorage);
    if (isLoggedIn && cacheStorage && cacheStorage.isShowViewBook) {
      if (contentId) {
        const windowReference = window.open(urlViewer);
        if (!windowReference) {
          setShowConfirmViewer(true);
        }
        setIsShowViewBook(false);
      }
      StorageHelper.removeSessionItem(STORAGE_KEYS.cacheStorage);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (contentId && contentGroupId) {
      if (!isRequireLogin) {
        handleLoadDetail({ contentId, contentGroupId });
      } else {
        if (isLoggedIn) {
          handleLoadDetail({ contentId, contentGroupId });
        }
      }
    }
  }, [contentId, contentGroupId, allowViewBook, isRequireLogin, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      if (isShowSubscription) {
        setIsShowSubscription(false);
      }
      if (showRegister) {
        setShowRegister(false);
      }
    }
  }, [isLoggedIn, isShowSubscription, showRegister]);
  useEffect(() => {
    if (contentToken) {
      if (isOpenType || isLoggedContentDirect || !isRequireLogin) {
        handleLoadDetail({ contentToken });
      }
    }
  }, [contentToken, isOpenType, isLoggedContentDirect, loginSetting]);
  useEffect(() => {
    let arraySortField = [];
    let arrayLongField = [];
    var count = 0;
    if (contentSetting && contentSetting.length > 0) {
      for (const item of contentSetting) {
        const displayField = { itemSetting: item, contentInfo, isFullWidth: false, isFullSlot: true, count };
        if (item.hasBox) {
          arrayLongField = [...arrayLongField, displayField];
        } else {
          arraySortField = [...arraySortField, displayField];
        }
      }
      setSortFields(arraySortField);
      setLongFields(arrayLongField);
    } else {
      setSortFields(arraySortField);
      setLongFields(arrayLongField);
    }
  }, [contentInfo]);

  useEffect(() => {
    if (isPaymentSuccess || isPaymentError) {
      handleLoadDetail({ contentId });
      resetGakkenPaymentInfo();
      resetPaymentStatus();
    }
  }, [isPaymentSuccess, isPaymentError]);

  useEffect(() => {
    if (urlGakkenPayment) {
      if (isRediectUrl) {
        window.open(`${urlGakkenPayment}`, '_self');
      } else {
        resetCreateOrderReceive();
      }
    }
    if (!isCreateOrderReceive && urlGakkenPayment === '') {
      setShowRegister(false);
    }
  }, [urlGakkenPayment, isCreateOrderReceive]);

  useEffect(() => {
    handleResizeScreen();
  }, [imageContentRef.current]);

  useEffect(() => {
    window.addEventListener('resize', handleResizeScreen);
    return () => {
      window.removeEventListener('resize', handleResizeScreen);
    };
  }, []);

  useEffect(() => {
    if (downloadContentIdApp) {
      resetLoadingDownloadContent();
      window.BEObj.openContent({
        DownloadID: downloadContentIdApp.downloadId,
        AuthID: downloadContentIdApp.authId,
        AuthName: downloadContentIdApp.authName,
        AuthKey: downloadContentIdApp.authKey,
        CustomName: downloadContentIdApp.customName,
        ShowAlertSB: false,
        ShowAlertNSB: false
      });
    }
  }, [downloadContentIdApp]);

  const handleResizeScreen = () => {
    if (imageContentRef && imageContentRef.current && imageContentRef.current.clientWidth) {
      const width = imageContentRef.current.clientWidth;
      const height = imageContentRef.current.clientHeight;
      var size = height > width ? width * 0.08 : height * 0.08;
      if (size < 15) {
        size = 15;
      }
      if (size > 45) {
        size = 45;
      }
      setWidthHeightIcon(size);
    }
  };

  const renderOverlayText = (contentGroupType) => {
    var label = '';
    switch (contentGroupType) {
      case CONTENT_GROUP_TYPE.NEW:
        label = f({ id: 'label.newOverlay' });
        break;
      case CONTENT_GROUP_TYPE.POPULAR:
        label = f({ id: 'label.popularOverlay' });
        break;
      case CONTENT_GROUP_TYPE.RECOMMEND:
        label = f({ id: 'label.recommendOverlay' });
        break;
      default:
        label = '';
    }
    if (label === '') {
      return null;
    }
    if (isLayout3) {
      return <span style={{ color: '#00b27b', fontWeight: below768 && 'bold' }}>{label}</span>;
    }
    return (<Chip size='30px' className={`${classes.chip}`} label={label} />);
  };
  const handleLoadDetail = ({ contentId, contentGroupId, contentToken }) => {
    loadDetail({ contentId, contentGroupId, contentToken });
  };
  const handleSetInnerHTML = (text) => {
    return (<span dangerouslySetInnerHTML={{ __html: text }} />);
  };
  const returnTemplateContentInfo = (searchField, itemSetting, contentInfo, isFullWidth, isFullSlot, count, className) => {
    if (!itemSetting.isShowLabel && (!contentInfo[searchField.dbKey] || contentInfo[searchField.dbKey] === '')) {
      return null;
    }
    return (
      <React.Fragment key={`key-detail-${searchField.dbKey}-${(new Date()).getTime()}`}>
        <Col
          sm={isFullWidth ? 12 : itemSetting.size}
          id={`key-detail-${searchField.dbKey}-${(new Date()).getTime()}`}
          className={`mB-1 ${itemSetting.hasBox && 'has-box'} ${className}`}
          style={below768 && itemSetting.hasBox ? { padding: '0 10px' } : {}}
        >
          {
            ((itemSetting.isShowLabel && isLayout3) || !isLayout3) &&
            <span >
              {`${itemSetting.title}: `}
            </span>
          }
          {
            itemSetting.hasBox
              ? <TextField variant={'outlined'} multiline type={'textarea'} value={contentInfo[searchField.dbKey]} />
              : handleSetInnerHTML(contentInfo[searchField.dbKey])
          }
        </Col>
      </React.Fragment>
    );
  };
  const renderContentInfo = ({ itemSetting, contentInfo, isFullWidth, isFullSlot, count, index }) => {
    let className = null;
    if (isLayout3) {
      switch (index) {
        case 0:
          className = classes.field0;
          break;
        case 1:
          className = classes.field1;
          break;
        case 2:
          className = classes.field2;
          break;
        default:
          className = classes.field1;
          break;
      }
    }

    const searchField = getSearchField(itemSetting.field);
    if (searchField) {
      return returnTemplateContentInfo(searchField, itemSetting, contentInfo, isFullWidth, isFullSlot, count, className);
    }
  };

  const getSearchField = (field) => {
    switch (field) {
      case SEARCH_FIELD.TITLE.id:
        return SEARCH_FIELD.TITLE;
      case SEARCH_FIELD.AUTHOR.id:
        return SEARCH_FIELD.AUTHOR;
      case SEARCH_FIELD.KEYWORDS.id:
        return SEARCH_FIELD.KEYWORDS;
      case SEARCH_FIELD.TOC.id:
        return SEARCH_FIELD.TOC;
      case SEARCH_FIELD.DISTRIBUTOR.id:
        return SEARCH_FIELD.DISTRIBUTOR;
      case SEARCH_FIELD.DESCRIPTION.id:
        return SEARCH_FIELD.DESCRIPTION;
      case SEARCH_FIELD.PARAM_1.id:
        return SEARCH_FIELD.PARAM_1;
      case SEARCH_FIELD.PARAM_2.id:
        return SEARCH_FIELD.PARAM_2;
      case SEARCH_FIELD.PARAM_3.id:
        return SEARCH_FIELD.PARAM_3;
      case SEARCH_FIELD.PARAM_4.id:
        return SEARCH_FIELD.PARAM_4;
      case SEARCH_FIELD.PARAM_5.id:
        return SEARCH_FIELD.PARAM_5;
      default:
        return null;
    }
  };

  const showSubscriptionList = contentGroupId => {
    fetchSubscriptionList({ contentGroupId: contentGroupId });
    setIsShowSubscription(true);
  };
  const openViewOnline = (content, contentGroupId) => {
    setIsShowViewBook(true);
    if (allowViewBook) {
      window.open(urlViewer, '_blank');
    } else {
      setContentDetailId(content.id);
      setShowLoginFormModal(true);
    }
  };
  const cancelLoginModal = () => {
    setShowLoginFormModal(false);
    setIsShowViewBook(false);
  };
  const handleLoginFormSubmit = async (data, authenType) => {
    setShowLoginFormModal(false);
    let request;
    if (authenType === AUTHEN_TYPES.EMAIL) {
      request = {
        password: md5(data.password),
        rememberMe: data.rememberMe,
        email: data.email,
        loginType: AUTHEN_TYPES.EMAIL,
        loginRole: USER_CONFIG.USER.loginType,
        path: data.path
      };
    } else if (authenType === AUTHEN_TYPES.GOOGLE_LOGIN) {
      request = data;
    } else {
      const userTrace = await Util.getUserTrace();
      const ip = userTrace.ip;
      request = {
        ip: ip,
        loginType: AUTHEN_TYPES.IP
      };
    }
    login(request);
  };

  const getJustifyContent = (positionId) => {
    switch (positionId) {
      case POSITION_CONFIG.CENTER.id:
        return 'center';
      case POSITION_CONFIG.LEFT.id:
        return 'flex-start';
      case POSITION_CONFIG.RIGHT.id:
        return 'flex-end';
      default:
        break;
    }
  };

  const renderButton = () => {
    return (
      <Button
        color={'warning'}
        className={`${classes.buttonView} ${classes.btnViewBook} ${isGettingDownloadContentIdApp && classes.btnLoading}`}
        onClick={() => {
          if (isMobileApp) {
            const request = {
              contentGroupId: contentGroupId,
              contentId: contentInfo.id
            };
            if (contentToken) {
              request.contentDetailId = contentToken;
              delete request.contentId;
            }
            if (!isGettingDownloadContentIdApp) {
              getDownloadContentId(request);
            }
          } else if (allowViewBook) {
            if (!contentInfo.isSubscriptionPlan || contentInfo.isRegistered) {
              openViewOnline(contentInfo, contentGroupId);
            } else {
              showSubscriptionList(contentGroupId);
            }
          } else {
            openViewOnline(contentInfo, contentGroupId);
          }
        }}
      >
        {
          !isMobileApp && isLayout3 && styleButtonViewBook.isShowIcon && styleButtonViewBook.icon && styleButtonViewBook.icon !== '' &&
            <span className={classes.iconButton}>
              <i className={styleButtonViewBook.icon}></i>
            </span>
        }
        {
          isMobileApp
            ? isGettingDownloadContentIdApp
              ? <Spinner/>
              : <TranslateMessage id={'label.download'} />
            : (styleButtonViewBook.label && styleButtonViewBook.label !== ''
              ? <span>{styleButtonViewBook.label}</span>
              : <React.Fragment>
                <TranslateMessage id={'label.displayBook'} />
              </React.Fragment>)
        }
      </Button>
    );
  };

  const nameSeries = contentInfo[PARAM_SERIES_CONTENT[viewSettings.contentSearchResultSettingResponse.paramSeriesContent]];

  if (isError) {
    return (
      <div className={classes.noData}>
        <TranslateMessage id="text.noData" />
      </div>
    );
  } else {
    return (
      <div className={`${classes.modalStyle}`}>
        {
          <React.Fragment>
            {
              isLoaded || !window.BEObj || isLoadingGakkenInfo
                ? <SkeletonTheme>
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col sm={4}>
                          <div><Skeleton className={classes.skeletonItemImage} /></div>
                        </Col>
                        <Col sm={8}>
                          <div>
                            <h5><Skeleton /></h5>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} className={'text-left'} >
                      <div><Skeleton className={classes.skeletonItemButton} /></div>
                    </Col>
                  </Row>
                </SkeletonTheme>
                : <Row>
                  <Col sm={12} style={{ padding: isLayout3 ? '0 10px' : '0 15px' }}>
                    <div className={classes.smallFieldBox}>
                      {
                        isLayout3 &&
                        <Grid container spacing={0} style={{ width: '100%' }}>
                          {/* content Image */}
                          <Grid item md={6} style={{ width: '100%' }}>
                            <div className={`${classes.contentImage}`}>
                              <div ref={imageContentRef} className={classes.noImageLayout3}>
                                <img src={contentInfo.beContentsId ? window.BEObj.getContentsURL({ cid: contentInfo.beContentsId, type: 2, staging: isBEOStaging, cdn: true }) : require('../../assets/img/no-image.png')} />
                                {
                                  showSubscriptionStatus && contentInfo.isRegistered
                                    ? <div className={`${classes.registered}`} title={f({ id: contentInfo.isSubscriptionPlan ? 'label.subscribed' : 'label.free' })}
                                      style={{
                                        right: (isLoggedIn && showFavorite) ? isLayout3 ? 35 : widthHeightIcon * 1.2 : 0,
                                        top: 10
                                      }}>
                                      <i className={'fas fa-check-circle'} style={{ color: 'green', fontSize: isLayout3 ? 26 : widthHeightIcon }} />
                                    </div>
                                    : null
                                }
                                {
                                  (below768 || (isLoggedIn && showFavorite && positionFavourite === POSITION_FAVORITE_BUTTON.IMAGE)) && (
                                    <Box
                                      className={`${isLayout3 ? contentInfo.isFavorite ? classes.favoriteIcon3 : classes.noFavoriteIcon3 : classes.favoriteIcon} favoriteIcon`}
                                      onClick={() => onSelectFavorite(contentInfo.id, contentInfo.isFavorite)}
                                    >
                                      <div className="wrap-icon"
                                        style={{
                                          width: isLayout3 ? below768 ? 29 : 28 : widthHeightIcon,
                                          height: isLayout3 ? below768 ? 29 : 28 : widthHeightIcon
                                        }}>
                                        {
                                          isLayout3
                                            ? contentInfo.isFavorite
                                              ? (<i className={'far fa-bookmark'} style={{ fontSize: 15 }}></i>)
                                              : (<i className="far fa-bookmark" style={{ fontSize: 15 }}></i>)
                                            : contentInfo.isFavorite
                                              ? (<i className={'fas fa-bookmark'} style={{ fontSize: widthHeightIcon * 0.5 }}></i>)
                                              : (<i className="far fa-bookmark" style={{ fontSize: widthHeightIcon * 0.5 }}></i>)
                                        }
                                      </div>
                                    </Box>
                                  )
                                }
                              </div>
                            </div>
                          </Grid>
                          {/* content Info */}
                          <Grid item md={6} className={`${classes.wrapFlexCenter} ${classes.contentInfo}`}>
                            <div className={'row'} style={{ flex: '1 0 0px', minWidth: 280 }} >
                              {
                                contentInfo.type && renderOverlayText(contentInfo.type) &&
                                <Col sm={ !below768 && isFavouriteInfo ? 6 : 12 } className={`${!below768 && 'mB-1'} ${classes.wrapFlexCenter}`}>
                                  {renderOverlayText(contentInfo.type)}
                                </Col>
                              }
                              {
                                below768 &&
                                  <>
                                    {
                                      sortFields.map((item, index) => {
                                        if (index === 0 || index === 1) {
                                          return renderContentInfo({ ...item, index });
                                        } else {
                                          return null;
                                        }
                                      })
                                    }
                                    {(isMobileApp || viewContentInfo) &&
                                    <Col sm={12} className='mB-2' >
                                      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                        { renderButton() }
                                      </div>
                                    </Col>}
                                  </>
                              }
                              {
                                !below768 && isFavouriteInfo && showFavorite && <Col sm={contentInfo.type && renderOverlayText(contentInfo.type) ? 6 : 12} className={`mB-1 ${classes.wrapFavoriteIconLayout3}`}>
                                  <Box className={`${isLayout3 ? contentInfo.isFavorite ? classes.favoriteIconLayout3 : classes.noFavoriteIconLayout3 : classes.noFavoriteIconLayout3} favoriteIcon`} >
                                    <div className="wrap-icon" onClick={() => onSelectFavorite(contentInfo.id, contentInfo.isFavorite)}>
                                      {
                                        isLayout3
                                          ? contentInfo.isFavorite
                                            ? (<i className={'far fa-bookmark'}></i>)
                                            : (<i className="far fa-bookmark"></i>)
                                          : contentInfo.isFavorite
                                            ? (<i className={'fas fa-bookmark'}></i>)
                                            : (<i className="far fa-bookmark"></i>)
                                      }
                                    </div>
                                  </Box>
                                </Col>
                              }
                              {
                                sortFields.map((item, index) => {
                                  if (!below768 || (index !== 0 && index !== 1)) {
                                    return renderContentInfo({ ...item, index });
                                  } else {
                                    return null;
                                  }
                                })
                              }
                              {
                                (!below768 && (isMobileApp || viewContentInfo) && (longFields.length === 0 && (!style.buttonPosition || style.buttonPosition === POSITION_CONFIG.RIGHT.id))) &&
                                <Col sm={12} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                  { renderButton() }
                                </Col>
                              }
                            </div>
                          </Grid>
                        </Grid>
                      }
                      {
                        !isLayout3 &&
                        <>
                          <div className={classes.noImage}>
                            {
                              contentInfo.type && <div className={classes.contentOverlay}>
                                {renderOverlayText(contentInfo.type)}
                              </div>
                            }
                            <img src={contentInfo.beContentsId ? window.BEObj.getContentsURL({ cid: contentInfo.beContentsId, type: 2, staging: isBEOStaging, cdn: true }) : require('../../assets/img/no-image.png')} />
                            {
                              showSubscriptionStatus && contentInfo.isRegistered
                                ? <div className={`${classes.registered}`} title={f({ id: contentInfo.isSubscriptionPlan ? 'label.subscribed' : 'label.free' })} style={
                                  {
                                    right: (isLoggedIn && showFavorite) ? 40 : 0
                                  }
                                }>
                                  <i className={'fas fa-check-circle'} style={{ color: 'green', fontSize: widthHeightIcon }} />
                                </div>
                                : null
                            }
                            {
                              isLoggedIn && showFavorite && (
                                <Box
                                  className={`${classes.favoriteIcon} favoriteIcon`}
                                  onClick={() => onSelectFavorite(contentInfo.id, contentInfo.isFavorite)}
                                >
                                  <div className="wrap-icon">
                                    {
                                      contentInfo.isFavorite
                                        ? (<i className={'fas fa-bookmark'}></i>)
                                        : (<i className="far fa-bookmark"></i>)
                                    }
                                  </div>
                                </Box>
                              )
                            }
                          </div>
                          <div className={'row'} style={{ flex: '1 0 0px', minWidth: 280 }}>
                            {
                              sortFields.map((item, index) => renderContentInfo({ ...item, index }))
                            }
                          </div>
                        </>
                      }
                    </div>
                  </Col>
                  <Col sm={12} className={'mt-2'} >
                    <Row>
                      {
                        longFields.map((item) => renderContentInfo(item))
                      }
                    </Row>
                  </Col>
                  {
                    (isMobileApp || viewContentInfo) && !isLayout3 &&
                      <Col sm={12} className={Util.getTextAlign(style.buttonPosition || POSITION_CONFIG.RIGHT.id)}>
                        <Grid container style={{ padding: 0 }} justifyContent={getJustifyContent(style.buttonPosition || POSITION_CONFIG.RIGHT.id)}>
                          <Grid item sm={ below768 ? 12 : 6 } className={`${classes.wrapFlexCenter}`} style={{ width: below768 ? '100%' : '', justifyContent: getJustifyContent(style.buttonPosition || POSITION_CONFIG.RIGHT.id) }}>
                            { renderButton() }
                          </Grid>
                        </Grid>
                      </Col>
                  }
                  {
                    (isMobileApp || viewContentInfo) && isLayout3 && (!below768 && (longFields.length > 0 || style.buttonPosition === POSITION_CONFIG.LEFT.id || style.buttonPosition === POSITION_CONFIG.CENTER.id)) &&
                      <Col sm={12} className={Util.getTextAlign(style.buttonPosition || POSITION_CONFIG.RIGHT.id)}>
                        <Grid container style={{ padding: 0 }} justifyContent={getJustifyContent(style.buttonPosition || POSITION_CONFIG.RIGHT.id)}>
                          <Grid item sm={ below768 ? 12 : 6 } className={`${classes.wrapFlexCenter}`} style={{ justifyContent: 'center', width: below768 ? '100%' : '' }}>
                            { renderButton() }
                          </Grid>
                        </Grid>
                      </Col>
                  }

                  <Col sm={12} style={{ margin: '2rem 0' }}>
                    {
                      viewSettings.contentSearchResultSettingResponse &&
                      viewSettings.contentSearchResultSettingResponse.contentLayoutDetail === CONTENT_DETAIL_LAYOUT.SERIES &&
                      viewSettings.contentSearchResultSettingResponse.paramSeriesContent && nameSeries && nameSeries !== '' &&
                      <SeriesContent
                        nameSeries={nameSeries}
                        showFavorite={showFavorite}
                        idContentDetail={contentInfo.id ? contentInfo.id : contentInfo.detailId}
                        onSelectFavorite={onSelectFavorite}
                      />
                    }
                  </Col>
                </Row>
            }
          </React.Fragment>
        }
        <SubscriptionModal
          isOpen={isShowSubscription}
          toggle={() => setIsShowSubscription(false)}
        />
        {/* <CommonModal
          isOpen={showRegister}
          toggle={isOpenClose ? () => setShowRegister(false) : null}
          title={nameSubscription || ''}
          size="lg"
          backdrop={'static'}
        >
          <SubscriptionFormDetail
            onCancel={handleCancelSubscriptionDetail}
            onSubmit={handleFormSubmit}
            detailMode={false}
            completePayment={handleCompletePayment}
            showNotification={showNotification}
            resetPaymentStatus={resetPaymentStatus}
            setIsOpenClose={setIsOpenClose}
            getCouponUser={getCouponUser}
            isCreateOrderReceive={isLoadCreateOrderReceive}
          />
        </CommonModal> */}
        {/* <CommonModal
          isOpen={showInfo}
          // toggle={() => setShowInfo(false)}
          title={f({ id: 'title.confirmGakkenPayment' })}
          size='lg'
          backdrop='static'
        >
          <ConFirmGakkenPaymentForm
            onSubmit={handleConfirmGakkenPayment}
            onClose={cancelConfirmGakkenPayment}
          />
        </CommonModal> */}
        <ModalConfirm
          isOpen={showConfirmViewer}
          toggle={() => setShowConfirmViewer(false)}
          message={f({ id: 'label.confirmViewNewTab' })}
          doConfirm={() => openViewOnline({ id: contentDetailId || contentId }, contentGroupId)}
          doCancel={() => setShowConfirmViewer(false)}
          backdrop={'static'}
          level={'info'}
        />
        <LoginModal
          isOpen={showLoginFormModal}
          toggle={() => cancelLoginModal()}
          size="md"
          backdrop={'static'}
        >
          <LoginEndUserForm
            onCancel={() => cancelLoginModal()}
            onSubmit={handleLoginFormSubmit}
            isShowViewBook={isShowViewBook}
          />
        </LoginModal>
      </div>
    );
  }
};

DetailContentPage.propTypes = {
  subscriptionActions: PropTypes.any,
  searchActions: PropTypes.any,
  sessionActions: PropTypes.any,
  couponActions: PropTypes.any,
  notificationActions: PropTypes.any,
  paymentActions: PropTypes.any,
  homeActions: PropTypes.any,
  routeActions: PropTypes.any,
  scrollbarActions: PropTypes.any,
  contentActions: PropTypes.any,
  className: PropTypes.object,
  contentInfo: PropTypes.any,
  isLoaded: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  contentSetting: PropTypes.array,
  onSelectFavorite: PropTypes.func,
  showFavorite: PropTypes.bool,
  subscriptionList: PropTypes.array,
  isListLoad: PropTypes.bool,
  domain: PropTypes.any,
  isError: PropTypes.bool,
  isLoadContentDetail: PropTypes.bool,
  isLoggedContentDirect: PropTypes.bool,
  layoutViewPage: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    isLoaded: getIsContentInfoLoad(state),
    subscriptionList: getSubscriptionList(state),
    isLoggedIn: isAuthenticated(state),
    contentInfo: getContentInfo(state),
    domain: getCompanyDomain(state),
    isError: isLoadContentDetailError(state),
    contentSetting: getContentDetailParams(state),
    isListLoad: getIsListLoad(state),
    layoutViewPage: getLayoutViewPage(state)
  };
};

export default connect(mapStateToProps)(props => (
  <ActionContext.Consumer>
    {({
      subscriptionActions,
      searchActions,
      sessionActions,
      couponActions,
      notificationActions,
      paymentActions,
      homeActions,
      routeActions,
      scrollbarActions,
      contentActions
    }) => (
      <DetailContentPage
        {...props}
        subscriptionActions={subscriptionActions}
        searchActions={searchActions}
        sessionActions={sessionActions}
        couponActions={couponActions}
        notificationActions={notificationActions}
        paymentActions={paymentActions}
        homeActions={homeActions}
        routeActions={routeActions}
        scrollbarActions={scrollbarActions}
        contentActions={contentActions}
      />
    )}
  </ActionContext.Consumer>
));
