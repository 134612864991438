import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'reactstrap';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import * as _ from 'lodash';
// import { CONTENT_GROUP_TYPE } from 'configs/AppConfig';
// import CancelIcon from '@material-ui/icons/Cancel';
import { CONTENT_GROUP_TYPE, LAYOUT_SETTING, PAGINATION } from 'configs/AppConfig';
import { ActionContext } from 'components/layout/MainContainer';
import { getIsSeriesContentLoad, getPaginationSeriesContent, getSeriesContent } from 'state/content/contentSelector';
import { isAuthenticated } from 'state/session/sessionSelector';
import { getLayoutViewPage } from 'state/search/searchSelector';

const heightSkeleton = [160, 140, 120, 140, 140, 120, 160, 180, 120, 150];
const heightSkeletonMoreOn = [190, 170, 150, 170, 170, 150, 190, 210, 150, 180];

const offsetLazyload = 250;

const SeriesContent = props => {
  const {
    // isLoggedIn,
    // onSelectFavorite,
    // showFavorite,
    pagination,
    // showTrending,
    // onSelectTrending,
    // handleChangePage,
    idContentDetail,
    nameSeries,
    seriesContent,
    isSeriesContentLoad,
    layoutViewPage,
    contentActions: {
      loadSeriesContent
    }
  } = props;

  const { formatMessage: f } = useIntl();
  const history = useHistory();

  const [isMoreOn, setIsMoreOn] = useState(false);
  const styleMoreLessBtn = useSelector((state) => state.search.moreLessBtnSetting) || null;
  const { moreLessBackground, moreLessTextColor } = styleMoreLessBtn;
  const below768 = useMedia('(max-width: 768px)');

  const isBEOStaging = !process.env.REACT_APP_IS_BEO_STAGING
    ? process.env.REACT_APP_IS_STAGING_SERVER : (process.env.REACT_APP_IS_BEO_STAGING === 'true');

  const isLayout3 = layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id;

  // const pathname = window.location.pathname;
  // const isSearchContent = pathname.includes('search');

  const useStyle = makeStyles(() => (
    {
      title: {
        padding: below768 ? '0.5rem 0 0 0' : '0.5rem 1rem',
        fontWeight: 700,
        cursor: 'pointer',
        // borderLeft: '1px solid #d2d2d2',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .button-more-less': {
          borderRadius: '19px!important',
          backgroundColor: moreLessBackground || '#6c757d',
          borderColor: moreLessBackground || '#6c757d',
          color: moreLessTextColor || '#fff',
          '&:hover': {
            backgroundColor: moreLessBackground || '#6c757d',
            borderColor: moreLessBackground || '#6c757d',
            color: moreLessTextColor || '#fff'
          },
          '&:active': {
            backgroundColor: moreLessBackground || '#6c757d',
            borderColor: moreLessBackground || '#6c757d',
            color: moreLessTextColor || '#fff'
          }
        },
        '& .tag': {
          // borderRadius: '10px',
          padding: below768 ? '0 0 6px 0' : '6px',
          fontWeight: 'bold'
          // color: 'white'
        },
        '& .free': {
          color: '#00b27b'
        },
        '& .subscription': {
          color: '#e44a4a'
        },
        '& .subscribed': {
          color: '#00fbff'
        }
      },
      contents: {
        flex: '1 0 0px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 'calc(100%)',
        justifyContent: 'center',
        '& .react-multiple-carousel__arrow': {
          display: 'none'
        }
      },
      contentWrap: {
        padding: '0.5rem',
        flex: '1 0 0px',
        minWidth: 164,
        maxWidth: 164,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative'
      },
      favoriteIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: '#F08828',
        fontSize: '1rem',
        borderRadius: '50%',
        justifyContent: 'flex-end',
        padding: below768 ? '4px' : '8px',
        margin: '0!important',
        display: 'flex',
        cursor: 'pointer',
        zIndex: 2,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)'
        },
        '& .wrap-icon': {
          background: '#fff',
          borderRadius: '50%',
          border: '1.5px solid #999999',
          width: '28px',
          height: '28px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      likeIcon: {
        width: '30%',
        height: '20%',
        position: 'absolute',
        top: 30,
        right: 0,
        color: '#40A2E3',
        fontSize: '1rem',
        borderRadius: '50%',
        justifyContent: 'flex-end',
        padding: below768 ? '4px' : '8px',
        margin: '0!important',
        display: 'flex',
        cursor: 'pointer',
        zIndex: 2,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)'
        },
        '& .wrap-icon': {
          background: '#fff',
          borderRadius: '50%',
          width: '22px',
          height: '22px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      contentWrapScale: {
        padding: below768 ? '0.13rem' : '0.25rem',
        // flex: '1 0 10%',
        // maxWidth: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        width: below768 ? '112px' : '140px',
        ['@media (max-width:1120px)']: { // eslint-disable-line no-useless-computed-key
          flex: '1 0 10%',
          maxWidth: '20%'
        },
        ['@media (max-width:900px)']: { // eslint-disable-line no-useless-computed-key
          flex: '1 0 10%',
          maxWidth: '20%'
        },
        '& .favoriteIcon': {
          margin: '0.5rem 0.5rem'
        }
      },
      contentWrapScaleMoreOn: {
        padding: below768 ? '0.25rem' : '0.5rem',
        margin: '1rem 0',
        // flex: '1 0 20%',
        width: '20%',
        display: 'flex',
        // width: below768 ? '120px' : '150px',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        ['@media (max-width:1120px)']: { // eslint-disable-line no-useless-computed-key
          // flex: '1 0 25%',
          maxWidth: '25%'
        },
        ['@media (max-width:900px)']: { // eslint-disable-line no-useless-computed-key
          // flex: '1 0 33.33%',
          maxWidth: '33.33%'
        },
        '& .favoriteIcon': {
          margin: '0.5rem 0.5rem'
        }
      },
      contentImage: {
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #d0d0d0',
        height: below768 ? 90 : 150,
        width: below768 ? 60 : 112,
        maxWidth: '100%',
        borderRadius: '9px'
      },
      contentImageSkeleton: {
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        width: below768 ? '90px' : '112px',
        maxWidth: '100%',
        borderRadius: '9px'
      },
      contentImageSkeletonMoreOn: {
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        width: below768 ? '120px' : '150px',
        maxWidth: '100%',
        borderRadius: '9px'
      },
      contentImageMoreOn: {
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #d0d0d0',
        height: below768 ? 90 : 150,
        width: below768 ? 60 : 112,
        maxWidth: '100%',
        borderRadius: '9px'
      },
      contentTitle: {
        textAlign: 'center',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        width: '100%'
      },
      noImage: {
        objectFit: 'contain',
        height: '100%',
        width: '100%'
      },
      bold: {
        fontWeight: 700
      },
      wrapSkeleton: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0.5rem',
        flex: '1 0 100%',
        overflow: 'hidden',
        alignItems: 'flex-end',
        '& span': {
          padding: '0 0.5rem'
        },
        '& .skeleton-input-component': {
          // minHeight: below768 ? '166px' : '240px',
          // maxHeight: below768 ? '166px' : '240px',
          maxWidth: below768 ? '118px' : '164px',
          minWidth: below768 ? '118px' : '164px'
        }
      },
      disabled: {
        background: '#828282!important',
        opacity: 0.8,
        display: 'none'
      },
      allContents: {
        display: 'flex',
        marginBottom: '0.5rem',
        alignItems: 'flex-end',
        width: '100%'
      },
      moreOff: {
        overflowX: 'auto',
        overflowY: 'hidden'
      },
      moreOn: {
        flexFlow: 'row wrap'
      },
      selectNumItem: {
        width: 72,
        marginLeft: '0.5rem'
      },
      noData: {
        textAlign: 'center',
        padding: '1rem',
        flex: '1 0 100%'
      },
      flexWrap: {
        flexWrap: 'wrap',
        overflowX: 'unset',
        justifyContent: 'start'
      },
      contentOverlay: {
        maxWidth: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        padding: below768 ? 1 : 4
      },
      chip: {
        background: '#fff',
        backgroundColor: 'white',
        height: '22px',
        color: '#00b27b',
        fontSize: '8px',
        fontWeight: 'bold',
        border: '1px solid #d0d0d0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiChip-label': {
          fontSize: below768 ? '8px' : '10px',
          padding: below768 ? '0 5px' : '0 6px',
          fontWeight: below768 ? 400 : '',
          fontFamily: 'Noto Sans JP'
        }
      },
      chip3: {
        background: '#fff',
        backgroundColor: 'white',
        height: '22px',
        color: '#00b27b',
        fontSize: '8px',
        fontWeight: 'bold',
        border: '1px solid #d0d0d0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiChip-label': {
          fontSize: below768 ? '8px' : '10px',
          padding: below768 ? '0 5px' : '0 6px',
          fontWeight: below768 ? 400 : '',
          fontFamily: 'Noto Sans JP'
        }
      },
      nameSeries: {
        fontSize: 18,
        fontWeight: 400,
        color: '#585757',
        display: 'flex',
        alignItems: below768 ? 'flex-start' : 'center',
        flexDirection: below768 ? 'column' : 'row'
      },
      subscriptionRegister: {
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        fontSize: '13px'
      }
    }
  ));

  const listener = () => {
    const element = document.getElementById('endPageDetail');
    if (element && element.getBoundingClientRect()) {
      const check = element.getBoundingClientRect().bottom <= window.innerHeight + offsetLazyload;
      if (check) {
        loadSeriesContent({ ...pagination, page: pagination.page + 1, id: idContentDetail });
      }
    }
  };

  const debounceListener = _.debounce(listener, 500);

  useEffect(() => {
    if (idContentDetail) {
      loadSeriesContent({ ...PAGINATION, id: idContentDetail });
    }
  }, [idContentDetail]);

  useEffect(() => {
    if (isMoreOn && pagination && pagination.totalPages > pagination.page && idContentDetail) {
      setTimeout(() => {
        if (isMoreOn) {
          listener();
        }
      }, 100);
      window.addEventListener('scroll', debounceListener, true);
      return () => {
        window.removeEventListener('scroll', debounceListener, true);
      };
    }
  }, [isMoreOn, pagination, idContentDetail]);

  const renderOverlayText = (contentGroupType) => {
    var label = '';
    switch (contentGroupType) {
      case CONTENT_GROUP_TYPE.NEW:
        label = f({ id: 'label.newOverlay' });
        break;
      case CONTENT_GROUP_TYPE.POPULAR:
        label = f({ id: 'label.popularOverlay' });
        break;
      case CONTENT_GROUP_TYPE.RECOMMEND:
        label = f({ id: 'label.recommendOverlay' });
        break;
      default:
        label = '';
    }
    return (<Chip className={`${isLayout3 ? classes.chip3 : classes.chip}`} size={ !below768 ? 'medium' : 'small'} label={label} />);
  };

  const renderContent = (item, index) => {
    return (
      <div
        key={`content-${index}-${item.id}`}
        className={classNames({
          [classes.contentWrapScale]: !isMoreOn,
          [classes.contentWrapScaleMoreOn]: isMoreOn
        })}
      >
        <div
          className={`${isMoreOn ? classes.contentImageMoreOn : classes.contentImage}`}
        >
          {
            item.type && <div className={classes.contentOverlay}>
              {renderOverlayText(item.type)}
            </div>
          }
          {/* {
            showFavorite && renderFavoriteButton(item)
          } */}
          {/* {
            showTrending && renderLikeButton(item)
          } */}
          <div onClick={() => {
            if (layoutViewPage === LAYOUT_SETTING.SEARCH_LAYOUT.id) {
              history.push(`/search/${item.id}`);
            } else {
              history.push(`/${item.contentGroups[0].id}/${item.id}`);
            }
          }} style={{ width: '100%', height: '100%' }}>
            <img
              className={classes.noImage}
              src={item.beContentsId ? window.BEObj.getContentsURL({ cid: item.beContentsId, type: 2, staging: isBEOStaging, cdn: true }) : require('assets/img/no-image.png')}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderContents = () => {
    if (seriesContent && seriesContent.length > 0) {
      return seriesContent.map((item, index) => {
        return <React.Fragment key={index}>
          {renderContent(item, index)}
        </React.Fragment>;
      });
    } else {
      return (
        <div className={classes.noData}>
          <TranslateMessage id="text.noData" />
        </div>
      );
    }
  };

  const classes = useStyle();

  // const onChangePage = (nextPage, nextSize) => {
  //   if (nextPage < 1 || nextPage > dataCurrentPage.totalPages || nextPage === dataCurrentPage.page) {
  //     return;
  //   }
  //   const params = {
  //     contentGroup: contentGroup
  //   };
  //   if (nextSize) {
  //     params.size = nextSize;
  //   }

  //   if (nextPage > dataCurrentPage.page) {
  //     if (!dataNextPage) {
  //       return;
  //     }
  //     setDataCurrentPage(dataNextPage);
  //     setDataPrevPage(dataCurrentPage);
  //     params.isNext = true;
  //     params.page = nextPage + 1;
  //     if (params.page > dataCurrentPage.totalPages) {
  //       setDataNextPage(null);
  //     }
  //   } else {
  //     if (!dataPrevPage) {
  //       return;
  //     }
  //     setDataCurrentPage(dataPrevPage);
  //     setDataNextPage(dataCurrentPage);
  //     params.isNext = false;
  //     params.page = nextPage - 1;
  //     if (params.page < 1) {
  //       setDataPrevPage(null);
  //     }
  //   }
  //   // handleChangePage(params);
  //   if (params.page <= dataCurrentPage.totalPages && params.page >= 1) {
  //     loadContentsNextPrevPage(params);
  //   }
  // };

  const renderSkeleton = () => {
    return <React.Fragment>
      {
        [...Array(PAGINATION.size).keys()].map((skeleton, index) => (
          <div
            key={`${index}`}
            className={classNames({
              [classes.contentWrapScale]: !isMoreOn,
              [classes.contentWrapScaleMoreOn]: isMoreOn
            })}
            style={{ height: `${isMoreOn ? heightSkeletonMoreOn[index % 10] : heightSkeleton[index % 10]}px` }}
          >
            <div
              style={{ height: `${isMoreOn ? heightSkeletonMoreOn[index % 10] : heightSkeleton[index % 10]}px` }}
              className={`${isMoreOn ? classes.contentImageSkeletonMoreOn : classes.contentImageSkeleton}`}
            >
              <Skeleton height={'100%'} width={'100%'} className={'skeleton-input-component'} />
            </div>
          </div>
        ))
      }
    </React.Fragment>;
  };

  // const renderFavoriteButton = (content) => {
  //   if (isLoggedIn) {
  //     return (
  //       <Box
  //         className={`${classes.favoriteIcon} favoriteIcon`}
  //         onClick={() => onSelectFavorite(content.id, content.isFavorite)}
  //       >
  //         <div className="wrap-icon">
  //           {
  //             content.isFavorite
  //               ? (<i className={'fas fa-bookmark'}></i>)
  //               : (<i className="far fa-bookmark"></i>)
  //           }
  //         </div>
  //       </Box>
  //     );
  //   }
  // };

  // const renderLikeButton = (content) => {
  //   if (isLoggedIn) {
  //     return (
  //       <Box
  //         className={`${classes.likeIcon} likeIcon`}
  //         onClick={() => onSelectTrending(content.id, content.isTrending, {
  //           ...contentGroup,
  //           contentGroupId: contentGroup.id
  //         })}
  //       >
  //         <div className="wrap-icon">
  //           {
  //             content.isFavorite
  //               ? (<i className="fas fa-thumbs-up"></i>)
  //               : (<i className="far fa-thumbs-up"></i>)
  //           }
  //         </div>
  //       </Box>
  //     );
  //   }
  // };

  const handleMoreClick = () => {
    setIsMoreOn(true);
    if (idContentDetail && pagination && pagination.totalPages > pagination.page) {
      loadSeriesContent({ ...pagination, page: pagination.page + 1, id: idContentDetail });
    }
  };

  if (!isSeriesContentLoad && seriesContent.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <div style={{ margin: '1.4rem 0' }}>
        <div className={classes.title}>
          <div className={classes.nameSeries}>
            <span style={{ wordBreak: 'break-word', display: 'flex' }}>{f({ id: 'label.nameSameSeries' }, { nameSeries: nameSeries })}</span>
            {/* {
              !below768 &&
              <span className={`tag subscription ${below768 ? '' : 'ml-2'} ${classes.subscriptionRegister}`}>
                <CancelIcon style={{ fontSize: '12px' }}/>
                {f({ id: 'label.subscriptionRegister' })}
              </span>
            } */}
          </div>
          {
            !isMoreOn && pagination.totalPages > 1 &&
            <Button onClick={handleMoreClick} className={classNames({ 'ml-2 button-more-less': true })} style={{ display: 'flex', alignItems: 'center', minWidth: below768 ? 86 : 100 }}>
              <TranslateMessage id={'button.more'} />
              <i className="fas fa-chevron-right" style={{ margin: '2px 0 0 6px' }}/>
            </Button>
          }
        </div>
        {
          // below768 &&
          // <span className={`tag subscription ${below768 ? '' : 'ml-2'} ${classes.subscriptionRegister}`} style={{ color: '#e44a4a', marginBottom: '0.5rem' }}>
          //   <CancelIcon style={{ fontSize: '12px' }}/>
          //   {f({ id: 'label.subscriptionRegister' })}
          // </span>
        }
        {
          isSeriesContentLoad && seriesContent.length === 0
            ? <div className={classes.contents}>
              <div className={`${classes.allContents} ${classes.moreOff}`}>
                {renderSkeleton()}
              </div>
            </div>
            : <div className={classes.contents}>
              <div className={`${classes.allContents} ${!isMoreOn ? classes.moreOff : classes.moreOn}`}>
                { isSeriesContentLoad && !isMoreOn ? null : renderContents() }
                {isSeriesContentLoad && renderSkeleton() }
              </div>
            </div>
        }
      </div>
      <div id='endPageDetail'></div>
    </React.Fragment>
  );
};

SeriesContent.propTypes = {
  // handleChangePage: PropTypes.func,
  // showTrending: PropTypes.bool,
  // onSelectTrending: PropTypes.func,

  onSelectFavorite: PropTypes.func,

  isLoggedIn: PropTypes.bool,
  showFavorite: PropTypes.bool,
  pagination: PropTypes.object,
  nameSeries: PropTypes.string,
  idContentDetail: PropTypes.string,
  seriesContent: PropTypes.array,
  isSeriesContentLoad: PropTypes.bool,
  contentActions: PropTypes.any,
  layoutViewPage: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    layoutViewPage: getLayoutViewPage(state),
    seriesContent: getSeriesContent(state),
    pagination: getPaginationSeriesContent(state),
    isLoggedIn: isAuthenticated(state),
    isSeriesContentLoad: getIsSeriesContentLoad(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ contentActions }) => (
        <SeriesContent
          {...props}
          contentActions={contentActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
