const _prefix = '@notifications/';
export default {
  GET_NOTIFICATIONS_DETAIL_REQUEST: _prefix + 'GET_NOTIFICATIONS_DETAIL_REQUEST',
  GET_NOTIFICATIONS_DETAIL_SUCCESS: _prefix + 'GET_NOTIFICATIONS_DETAIL_SUCCESS',

  GET_NOTIFICATIONS_PAGING_REQUEST: _prefix + 'GET_NOTIFICATIONS_PAGING_REQUEST',
  GET_NOTIFICATIONS_PAGING_SUCCESS: _prefix + 'GET_NOTIFICATIONS_PAGING_SUCCESS',

  UPDATE_NOTIFICATIONS_REQUEST: _prefix + 'UPDATE_NOTIFICATIONS_REQUEST',

  DELETE_NOTIFICATIONS_REQUEST: _prefix + 'DELETE_NOTIFICATIONS_REQUEST',

  ADD_NOTIFICATIONS_REQUEST: _prefix + 'ADD_NOTIFICATIONS_REQUEST'
};
