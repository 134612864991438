import AccountTypes from './AccountTypes';
import { API_STATUS } from 'configs/AppConfig';

const initialState = {
  categories: [],
  selected: [],
  accountDetail: {
    fullName: '',
    lang: '',
    email: '',
    password: ''
  },
  statusChangeEmail: null,
  statusChangePassword: null,
  isLoading: false
};

export default function accountReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case AccountTypes.GET_ACCOUNT_SETTING_SUCCESS:
      newState.accountDetail = { ...payload };
      return newState;
    case AccountTypes.CHANGE_USER_EMAIL_REQUEST:
      newState.statusChangeEmail = API_STATUS.PROGRESSING;
      return newState;
    case AccountTypes.CHANGE_USER_EMAIL_SUCCESS:
      newState.statusChangeEmail = API_STATUS.SUCCESS;
      return newState;
    case AccountTypes.CHANGE_USER_EMAIL_ERROR:
      newState.statusChangeEmail = API_STATUS.ERROR;
      return newState;
    case AccountTypes.CHANGE_PASSWORD_REQUEST:
      newState.statusChangePassword = API_STATUS.PROGRESSING;
      return newState;
    case AccountTypes.CHANGE_PASSWORD_SUCCESS:
      newState.statusChangePassword = API_STATUS.SUCCESS;
      return newState;
    case AccountTypes.CHANGE_PASSWORD_ERROR:
      newState.statusChangePassword = API_STATUS.ERROR;
      return newState;
    case AccountTypes.CANCEL_ACCOUNT_USER_REQUEST:
      newState.isLoading = true;
      return newState;
    case AccountTypes.CANCEL_ACCOUNT_USER_ERROR:
      newState.isLoading = false;
      return newState;
    case AccountTypes.CANCEL_ACCOUNT_USER_SUCCESS:
      newState.isLoading = false;
      return newState;
    case AccountTypes.UPDATE_ACCOUNT_SETTING_REQUEST:
      newState.isLoading = true;
      return newState;
    case AccountTypes.UPDATE_ACCOUNT_SETTING_SUCCESS:
    case AccountTypes.UPDATE_ACCOUNT_SETTING_ERROR:
      newState.isLoading = false;
      return newState;
    default:
      newState = state;
      break;
  }

  // return new state
  return newState;
}
