import { all, put, takeEvery } from 'redux-saga/effects';
import CouponTypes from './CouponTypes';
import createRequest from '../../api/httpRequest';
import API from '../../api/coupon';
import NotificationTypes from '../notification/NotificationTypes';
import snakecaseKeys from 'snakecase-keys';
import Util from '../../utils/Util';
import { PAGINATION } from 'configs/AppConfig';

function * getCouponPage () {
  yield takeEvery(CouponTypes.GET_COUPON_PAGE_REQUEST, _getCouponPage);
}

function * _getCouponPage ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_COUPON_PAGE,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_ERROR,
        payload: status
      });
    }
  });
}

function * getCouponList () {
  yield takeEvery(CouponTypes.GET_COUPON_LIST_REQUEST, _getCouponList);
}

function * _getCouponList ({ payload }) {
  yield createRequest({
    api: {
      ...API.GET_COUPON_LIST,
      params: snakecaseKeys({ ...payload })
    },
    onSuccess: function * (response) {
      yield put({
        type: CouponTypes.GET_COUPON_LIST_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_LIST_ERROR,
        payload: status
      });
    }
  });
}

function * getCouponDetail () {
  yield takeEvery(CouponTypes.GET_COUPON_DETAIL_REQUEST, _getCouponDetail);
}

function * _getCouponDetail ({ payload }) {
  const api = {
    ...API.GET_COUPON_DETAIL,
    url: API.GET_COUPON_DETAIL.url + payload
  };
  yield createRequest({
    api: api,
    onSuccess: function * (data) {
      yield put({
        type: CouponTypes.GET_COUPON_DETAIL_SUCCESS,
        payload: Util.toCamelCaseKey(data.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_DETAIL_ERROR,
        payload: status
      });
    }
  });
}

function * addCoupon () {
  yield takeEvery(CouponTypes.ADD_COUPON_REQUEST, _addCoupon);
}

function * _addCoupon ({ payload }) {
  const request = snakecaseKeys({ ...payload });

  yield createRequest({
    api: {
      ...API.ADD_COUPON,
      data: request.data
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.create.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: CouponTypes.GET_COUPON_PAGE_REQUEST,
        payload: {
          ...PAGINATION
        }
      });
    }
  });
}

function * getCouponUser () {
  yield takeEvery(CouponTypes.GET_COUPON_USER_REQUEST, _getCouponUser);
}

function * _getCouponUser ({ payload }) {
  const request = snakecaseKeys({ ...payload });
  yield createRequest({
    api: {
      ...API.GET_COUPON_USER,
      params: { ...request }
    },
    onSuccess: function * (response) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.coupon.${response.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: CouponTypes.GET_COUPON_USER_SUCCESS,
        payload: Util.toCamelCaseKey(response.data)
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: CouponTypes.GET_COUPON_USER_ERROR
      });
    }
  });
}

export default function * couponSaga () {
  yield all([
    getCouponPage(),
    getCouponList(),
    getCouponDetail(),
    addCoupon(),
    getCouponUser()
  ]);
}
